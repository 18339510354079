import React from "react";
import styled from "styled-components";

import Time from "./Time";
function MeAnswer({ text }) {
  return (
    <MeAnswerWrap>
      <NavyBubble>{text}</NavyBubble>
      <Time left={"-60px"} right={null} />
    </MeAnswerWrap>
  );
}

const MeAnswerWrap = styled.div`
  margin: 1rem 0 1.2rem auto;
  position: relative;
  max-width: 260px;
`;
const NavyBubble = styled.div`
  display: inline-block;
  padding: 11px 1rem;
  background: #002b49;
  color: #fff;
  border-radius: 15px;
  border-top-right-radius: 0%;
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.1));
`;

export default MeAnswer;
