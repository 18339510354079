import React, { useEffect, useState } from "react";
import PageTop from "../common/PageTop";
import HospitalSidebar from "./components/HospitalSidebar";
import HospitalFilter from "../hospital_map/components/HospitalFilter";
import { getFilterData } from "../hospital_map/Filter";

function HospitalList2() {
  const [filter, setFilter] = useState(Array());
  const hospital_flag = 'A';

  const mapChange = () => {
    setFilter([getFilterData("department"), getFilterData("range")]);
  };

  useEffect(() => {
    setFilter([getFilterData("department"), getFilterData("range")]);
  }, []);
  
  return (
    <div>
      <PageTop title="거래병원" />
      {/*<HospitalSort />*/}
      <HospitalSidebar hospital_flag={hospital_flag} filter={filter} />
      <HospitalFilter mapChange={mapChange} />
    </div>
  );
}

export default HospitalList2;
