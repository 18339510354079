import React, { useState } from "react";
import SampleGraph from "./SampleGraph";
import styled from "styled-components";
import { Modal } from "flowbite-react";

function CustomBarGraph({ caption, areaInfo, flag }) {
  const [openModal, setOpenModal] = useState(false);

  const onModalOpinionHandler = () => {
    setOpenModal(true);
  };
  const offModalOpinionHandler = () => {
    setOpenModal(false);
  };
  return (
    <div>
      <div className="flex items-center gap-1">
        <strong className="text-base font-medium">{caption}</strong>
        <button onClick={() => onModalOpinionHandler()}>
          <img src="assets/img/hospital_soon/icon-question.svg" alt="" />
        </button>
      </div>
      <SampleGraph areaInfo={areaInfo} flag={flag} />
      <Modal show={openModal} onClose={() => setOpenModal(false)} style={{display: "flex", alignItems: "center"}}>
        <ModalTop className="flex justify-between items-center px-4 py-2">
          <h3 className="text-black text-lg font-semibold ">{caption}</h3>
          <button onClick={offModalOpinionHandler}>
            <img
              src="assets/img/hospital_map/icon-close-gray.svg"
              style={{ width: "32px" }}
            />
          </button>
        </ModalTop>
        <Modal.Body className="px-6">
          {caption === "[직장 인구수 및 1인당 평균소득 지수]" ? (
            <TootipText className="list-disc">
              <li>직장인 1인당 평균소득은 국민연금 납부금액으로 추정한 정보를 본사 소재지를 기준으로 산정합니다.</li>
              <li>해당 시도의 추정 직장인 1인당 평균소득을 100으로 지수화하여 각지역의 소득을 상대 비교하여 나타냅니다.</li>
            </TootipText>
          ) : (
            <TootipText className="list-disc">
              <li>지역내총생산(Gross Regional Domestic Product : GRDP)과 주거비용(전세가)를 반영하여 추정 가계평균소득을 산정합니다.</li>
              <li>해당 시도의 추정 가계평균소득을 100으로 지수화하여 각지역의 소득을 상대 비교하여 나타냅니다.</li>
            </TootipText>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

const ModalTop = styled.div`
  border-bottom: 1px solid #e4e4e4;
`;

const TootipText = styled.ul`
  li {
    color: #000;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px;
    padding-left: 10px;
  }
`;

export default CustomBarGraph;