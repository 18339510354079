import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getUser } from "../../login/User";
import { getToken } from "../../login/Token";
import gcss_api from "../../api/api";
import axios from "axios";
import { ymd_ago } from "../../common/PageTop";
import OpinionEditor from "./OpinionEditor";
import OpinionReply from "./OpinionReply";

const OpinionItem = ({ item, getOpinion }) => {
  const user = getUser();
  const token = getToken();
  const [showEditor, setShowEditor] = useState(false);
  const [showReply, setShowReply] = useState(false);

  let option = {
    method: 'GET',
    headers: {'Authorization': token, 'Content-Type': 'application/json'},
    url: gcss_api("api_memo_opinion"),
  };

  const onModify = () => setShowEditor(true);
  const closeEditor = () => setShowEditor(false);

  const onDelete = () => {
    if(window.confirm("삭제하겠습니까?")) {
      option.method = "DELETE";
      option.data = JSON.stringify({"PSTO_SEQ": item.PSTO_SEQ});
      axios(option).then(() => {
        getOpinion();
      }).catch((err) => {
        if(err?.response?.status === 401) window.location.href = "/logout";
        console.log(err);
        alert("의견 삭제에 실패하였습니다.");
      });
    }
  };

  const onReply = () => setShowReply(true);
  const closeReply = () => setShowReply(false);

  return (
    <OpinionItemWrap $depth={item.DEPTH}>
      <OpinionItemInfo>
        <img src="assets/img/hospital_map/icon-clock-gray.svg" alt="" />
        <p className="text-neutral-500">
          {ymd_ago(item.SHIP_DT.replaceAll("-", "").substr(0,8))} ({item.SHIP_DT.replaceAll("-", ".").replace("T", " ")})
        </p>
        {item.USER_NM === user && <button onClick={() => onModify()}>수정</button>}
        {item.USER_NM === user && <SpaceBar />}
        {item.USER_NM === user && <button onClick={() => onDelete()}>삭제</button>}
        {item.USER_NM === user && item.DEPTH === '0' && <SpaceBar />}
        {item.DEPTH === '0' && <button onClick={() => onReply()}>답글쓰기</button>}
      </OpinionItemInfo>
      <OpinionItemInfo>
        <div className="pt-2 pl-2 flex gap-2">
          <p>GC{item.AFFL_NM}</p>
          <SpaceBar/>
          <p>{item.USER_NM}</p>
        </div>
      </OpinionItemInfo>
      {showEditor ? <OpinionEditor item={item} getOpinion={getOpinion} closeEditor={closeEditor}/> : <OpinitonText>{item.PSTO_CN}</OpinitonText>}
      {showReply && <OpinionReply item={item} getOpinion={getOpinion} closeReply={closeReply} />}
    </OpinionItemWrap>
  );
};

const OpinionItemWrap = styled.div`
  padding: 0.725rem 0 0.725rem ${({$depth}) => $depth ? `${$depth}rem` : "0"};
  border-bottom: 1px solid #e4e4e4;
`;

const OpinionItemInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
`;

const SpaceBar = styled.div`
  width: 1px;
  height: 11px;
  background: #959595;
`;

const OpinitonText = styled.pre`
  font-size: 13px;
  margin-top: 0.725rem;
  padding-left: 14px;
  text-wrap: wrap;
`;
export default OpinionItem;
