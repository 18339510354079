import React from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  LabelList,
  YAxis,
  Cell,
} from "recharts";

export default function SampleGraph({ areaInfo, flag }) {
  const earn_amt = flag === "PPLTN" ? "ASUM_HINC_AMT" : "P_ASUM_LABOR_EARN_AMT";
  const data = [
    {
      name: (areaInfo && areaInfo.DONG_NM ? areaInfo.DONG_NM : ""),
      person: (areaInfo && areaInfo[flag + "_CNT"] ? areaInfo[flag + "_CNT"] : 0),
      pay: (areaInfo && areaInfo[earn_amt] ? areaInfo[earn_amt] : 0),
    },
    {
      name: (areaInfo && areaInfo.DONG_SGG_NM ? areaInfo.DONG_SGG_NM : ""),
      person: (areaInfo && areaInfo["SGG_" + flag + "_CNT"] ? areaInfo["SGG_" + flag + "_CNT"] : 0),
      pay: (areaInfo && areaInfo["SGG_" + earn_amt] ? areaInfo["SGG_" + earn_amt] : 0),
    },
    {
      name: (areaInfo && areaInfo.DONG_CTPV_NM ? areaInfo.DONG_CTPV_NM : ""),
      person: (areaInfo && areaInfo["CTPV_" + flag + "_CNT"] ? areaInfo["CTPV_" + flag + "_CNT"] : 0),
      pay: (areaInfo && areaInfo["CTPV_" + earn_amt] ? areaInfo["CTPV_" + earn_amt] : 0),
    },
  ];
  const personLabel = (props) => {
    const { x, y, index, width, value } = props;
    return (
      <g>
        <text x={x + width / 2} y={20} fill="#000" textAnchor="middle" fontSize={12} fontWeight={600}>
          {index ? "평균 " + value.toLocaleString() + "명" : value.toLocaleString() + "명"}
        </text>
      </g>
    );
  };
  const payLabel = (props) => {
    const { x, y, value } = props;
    const width = value > 10000000 ? 60 : 50;
    const left = value > 10000000 ? 30 : 25;
    return (
      <g>
        <rect x={x - left} y={y - 30} fill="#FFFFFF" width={width} height={20} rx={5} stroke="#000000">
        </rect>
        <text x={x} y={y - 15} fill="#000000" textAnchor="middle" fontSize={12} fontWeight={600}>
            {Math.floor(value).toLocaleString()}
          </text>
      </g>
    );
  };
  return (
    <div style={{ width: "100%", height: 200, fontSize: "12px " }}>
      <ResponsiveContainer height={200}>
        <ComposedChart
          width={400}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <YAxis yAxisId={1} hide={true}/>
          <YAxis yAxisId={2} hide={true} domain={[0, dataMax => dataMax * 2]}/>
          <Bar yAxisId={1} dataKey="person" barSize={40} fill="#D9D9D9" radius={[8,8,0,0]}>
            <Cell key={'cell-0'} fill="#00ADE6"/>
            <LabelList dataKey="person" content={personLabel} />
          </Bar>
          <Line yAxisId={2} dataKey="pay" stroke="#000000" strokeWidth={2} dot={{r: 5}}>
            <LabelList dataKey="pay" content={payLabel} />
          </Line>
          <XAxis dataKey="name" fontSize={12} stroke="#929292" fontWeight={600} tickLine={false} tick={{fill: "#000000"}} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
