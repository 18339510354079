import { useEffect, useState } from "react";
import styled from "styled-components";
import { Tabs } from "flowbite-react";
import { Link } from "react-router-dom";
import { getToken } from "../../login/Token";
import axios from "axios";
import gcss_api from "../../api/api";
import { ymd_ago } from "../../common/PageTop";
import { db } from "../../db";
import { getUser } from "../../login/User";

import { useLocation, useNavigate } from "react-router-dom";


function MypageBox({ mypageHideHandler }) {

  const [memos, setMemos] = useState();
  const [schedules, setSchedules] = useState();

  const [ctpv, setCtpv] = useState({});
  const [sgg, setSgg] = useState({});
  const [dong, setDong] = useState({});
  const [memoStatus, setMemoStatus] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const token = getToken();

  const option = {
    method: 'GET',
    headers: { 'Authorization': token, 'Content-Type': 'application/json' },
  };

  function convertFlagToImage(item) {
    let icon = ""
    if (item.VENDER_FLAG > 0) {
      icon = "assets/img/hospital_map/iconalram-icon3-check.svg";
    } else {
      if (item.HOPN_STEP_NM === "개원") {
        icon = "assets/img/hospital_map/iconalram-icon2-check.svg";
      } else {
        icon = "assets/img/hospital_map/iconalram-icon1-check.svg";
      }
    }
    return icon;
  }

  function convertFlagToImageSchedule(item) {
    let icon = ""
    switch(item.RPRT_INST_TYPE_CD) {
      case "N": icon = "assets/img/hospital_map/icon-map-blue.svg"; break;
      case "A": icon = "assets/img/hospital_map/icon-map-green.svg"; break;
      case "B": case "B-1": icon = "assets/img/hospital_map/icon-map-green-border.svg"; break;
    }
    return icon;
  }

  const onDetail = (item) => {
    let to = "";
    if (item.VENDER_FLAG > 0) {
      to = "/deal";
    } else {
      if (item.HOPN_STEP_NM === "개원") {
        to = "/non_deal";
      } else {
        to = "/hospital_soon";
      }
    }
    if (to === "") return false;
    navigate(to, {
      state: {
        COMB_KEY: item.COMB_KEY,
        RFRNC_TBL_NM: item.RFRNC_TBL_NM,
        DONG_CD: item.DONG_CD,
        from: location,
        y: item.LNL_YCO,
        x: item.LNL_XCO,
      }
    });
  }

  const onDetailSchedule = (item) => {
    let to = "";
    switch (item.RPRT_INST_TYPE_CD) {
      case "N": to = "/hospital_soon"; break;
      case "A": to = "/deal"; break;
      case "B": case "B-1": to = "/non_deal"; break;
    }
    if (to === "") return false;
    navigate(to, {
      state: {
        COMB_KEY: item.COMB_KEY,
        RFRNC_TBL_NM: item.RFRNC_TBL_NM,
        DONG_CD: item.DONG_CD,
        from: location,
        y: item.LNL_YCO,
        x: item.LNL_XCO,
      }
    });
  }

  function convertStringToKoDay(date) {
    //ex) convertStringToKoDay('2023-02-07')
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const dayOfWeek = week[new Date(date).getDay()];
    return dayOfWeek;
  };

  useEffect(() => {
    const getMemos = () => {
      const options = { ...option, url: gcss_api("api_memo_hospital_center") }
      axios(options).then((res) => {
        setMemos(res.data);
      }).catch((err) => {
        if (err?.response?.status === 401) window.location.href = "/logout";
        console.log(err);
      });
    };

    const getSchedules = () => {
      const options = { ...option, url: gcss_api("api_shedule_user_list") }
      axios(options).then((res) => {
        setSchedules(res.data);
      }).catch((err) => {
        if (err?.response?.status === 401) window.location.href = "/logout";
        console.log(err);
      });
    }
    async function getData() {
      const ctpvData = {};
      await db.city.orderBy("DONG_CTPV_CD").each((item) => {
        ctpvData[item.DONG_CTPV_CD] = item.DONG_CTPV_NM;
      });
      setCtpv(ctpvData);
      const sggData = {};
      await db.sgg.orderBy("DONG_SGG_CD").each((item) => {
        sggData[item.DONG_SGG_CD] = item.DONG_SGG_NM;
      });
      setSgg(sggData);
      const dongData = {};
      await db.dong.orderBy("DONG_CD").each((item) => {
        dongData[item.DONG_CD] = item.DONG_NM;
      });
      setDong(dongData);
    };

    getMemos();
    getSchedules();
    getData();
  }, []);

  return (
    <MypageBoxWrap>
      <MypageBoxTop>
        <button onClick={mypageHideHandler}>
          <img src="assets/img/hospital_list/icon-left-arrow.svg" alt="" />
        </button>
        <h3>마이페이지</h3>
        <button>
          {/*<img src="assets/img/login/icon-close.svg" alt="" />*/}
        </button>
      </MypageBoxTop>
      <MypageContent>
        <div className="px-4 flex justify-end">
          <BtnLogin>
            <img src="/assets/img/icon-logout.svg" alt="" />
            <Link to={"/logout"}>로그아웃</Link>
          </BtnLogin>
        </div>

        <MypageTabWrap className="mx-4 bg-white rounded">
          <Tabs>
            <Tabs.Item active title={memos === undefined ? "메모(0)" : "메모(" + memos.length + ")"}>
              <MemoListWrap className="px-4 flex flex-col gap-3">

                <div className="flex text-black text-xs font-semibold">
                  <button className="flex" onClick={() => setMemoStatus(false)}>
                    {memoStatus ? <StatusOff /> : <StatusOn />}
                    내 메모 ({memos === undefined ? 0 : memos.filter(x => x.USER_NM === getUser()).length})
                  </button>
                  <button className="flex" onClick={() => setMemoStatus(true)} style={{ marginLeft: "10px" }}>
                    {memoStatus ? <StatusOn /> : <StatusOff />}
                    전체 메모 ({memos === undefined ? 0 : memos.length})
                  </button>
                </div>
                {memos?.map((item) => (
                  memoStatus === false ? item.USER_NM === getUser() &&
                    <MemoListItems key={item.MEMO_SEQ}>
                      <button onClick={() => onDetail(item)}>
                        <div className="flex text-black text-xs">
                          <img src={convertFlagToImage(item)} alt="" style={{ width: "15px", height: "20px" }} />
                          <strong className="font-semibold">
                            {item.RPRT_INST_NM}
                          </strong>
                          ({ctpv[item.DONG_CTPV_CD]} {sgg[item.DONG_SGG_CD]} {dong[item.DONG_CD]})
                        </div>
                      </button>
                      <div className="flex items-center gap-1 mb-1 text-xs">
                        <img src="assets/img/hospital_map/icon-clock-gray.svg" alt="" />
                        <p className="text-neutral-500">{ymd_ago(item.SHIP_DT.replaceAll("-", "").substr(0, 8))} ({item.SHIP_DT.replaceAll("-", ".").replace("T", " ")})</p>
                      </div>
                      <div className="flex items-center gap-1 text-xs pl-3  mb-1 ">
                        <p>{item.AFFL_NM}</p>
                        <SpaceBar />
                        <p className="flex items-center gap-2">
                          {item.USER_NM} {item.RLS_LVL_CD === "0" && <img src="/assets/img/icon-lock.svg" alt="" />}
                        </p>
                      </div>
                      <p className="text-xs pl-3">
                        {item.MEMO_CN}
                      </p>
                    </MemoListItems>
                    :
                    <MemoListItems key={item.MEMO_SEQ}>
                      <button onClick={() => onDetail(item)}>
                        <div className="flex text-black text-xs">
                          <img src={convertFlagToImage(item)} alt="" style={{ width: "15px", height: "20px" }} />
                          <strong className="font-semibold">
                            {item.RPRT_INST_NM}
                          </strong>
                          ({ctpv[item.DONG_CTPV_CD]} {sgg[item.DONG_SGG_CD]} {dong[item.DONG_CD]})
                        </div>
                      </button>
                      <div className="flex items-center gap-1 mb-1 text-xs">
                        <img src="assets/img/hospital_map/icon-clock-gray.svg" alt="" />
                        <p className="text-neutral-500">{ymd_ago(item.SHIP_DT.replaceAll("-", "").substr(0, 8))} ({item.SHIP_DT.replaceAll("-", ".").replace("T", " ")})</p>
                      </div>
                      <div className="flex items-center gap-1 text-xs pl-3  mb-1 ">
                        <p>{item.AFFL_NM}</p>
                        <SpaceBar />
                        <p className="flex items-center gap-2">
                          {item.USER_NM} {item.RLS_LVL_CD === "0" && <img src="/assets/img/icon-lock.svg" alt="" />}
                        </p>
                      </div>
                      <p className="text-xs pl-3">
                        {item.MEMO_CN}
                      </p>
                    </MemoListItems>
                ))}

              </MemoListWrap>
            </Tabs.Item>
            <Tabs.Item title={schedules === undefined ? "일정(0)" : "일정(" + schedules.length + ")"}>
              <ScheduleItemWrap className="px-4">

                {schedules?.map((item) => (

                  <div className="flex" key={item.COMB_KEY+item.REG_SEQ}>
                    <div className="w-1/4 flex flex-col justify-center items-center text-center py-4 px-2">
                      <p className="block text-black text-xs">{item.SCHDL_BGNG_DT.substr(0, 4)}년</p>
                      <strong className="text-black font-semibold" style={{fontSize:"14px"}}>
                        {item.SCHDL_BGNG_DT.substr(5, 2)}월 {item.SCHDL_BGNG_DT.substr(8, 2)}일
                      </strong>
                      <p className="block text-black text-xs">{convertStringToKoDay(item.SCHDL_BGNG_DT.substr(0, 10))}</p>
                      <span className=" w-14 px-1.5 py-0.5 bg-gray-400 rounded-2xl text-white text-xs">
                        {ymd_ago(item.SCHDL_BGNG_DT.replaceAll("-", "").substr(0, 8))} { }
                      </span>
                    </div>
                    <ScheduleInfo className="w-3/4 px-2 my-2">
                    <button onClick={() => onDetailSchedule(item)}>
                          <div className="flex text-black text-xs">
                            <img src={convertFlagToImageSchedule(item)} alt="" style={{ width: "12px", height: "15px" }} />
                            <strong className="font-semibold">
                              {item.RPRT_INST_NM}
                            </strong>
                            <div style={{fontSize:"11px"}}>
                              ({ctpv[item.DONG_CTPV_CD]} {sgg[item.DONG_SGG_CD]} {dong[item.DONG_CD]})
                            </div>
                            
                          </div>
                        </button>
                      <strong className="block mb-1 text-black font-semibold text-normal">
                        {item.SCHDL_TTL_CN}
                      </strong>
                      <p className="mb-1 text-xs text-neutral-500 flex items-center gap-1">
                        <img src="assets/img/hospital_map/icon-clock-gray.svg" alt="" />
                        {item.SCHDL_BGNG_DT.substr(11, 5)} - {item.SCHDL_END_DT.substr(11, 5)}
                      </p>
                      <p className="text-xs">
                        {item.SCHDL_DTL_CN}
                      </p>
                    </ScheduleInfo>
                  </div>
                ))}

              </ScheduleItemWrap>
            </Tabs.Item>
          </Tabs>
        </MypageTabWrap>
      </MypageContent>
    </MypageBoxWrap>
  );
}
const MypageBoxWrap = styled.div`
  background: #eff2f3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 201;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

`;

const MypageBoxTop = styled.div`
  line-height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  background: #fff;
  border-bottom: 1px solid #eff2f3;
`;

const MypageTabWrap = styled.div`
  div[role="tabpanel"] {
    padding: 0;
    padding-bottom: 1rem;
  }
  div[role="tablist"] {
    width: 100%;
    button {
      width: 50%;
      color: #9c9c9c;
      border-bottom: 3px solid #d0d0d0;
      padding: 0.5rem 0;
      font-size: 16px;
      font-weight: bold;
      background: #fff;
      &:focus {
        --tw-ring-opacity: 0;
        --tw-ring-color: rgb(255, 255, 255 / var(--tw-ring-opacity));
      }
      &[aria-selected="true"] {
        color: #002b49;
        border-bottom: 3px solid #002b49;
      }
    }
  }
`;

const MypageContent = styled.div`
  background: #eff2f3;
  padding: 10px 0 1.5rem;
  height: 100vh;
  overflow-y: scroll;
`;

const BtnLogin = styled.button`
  border: 1px solid #00ade6;
  background: #fff;
  color: #00ade6;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 5px;
  margin: 0.5rem 0;
  font-size: 13px;
  border-radius: 9999px;
`;
const ScheduleItemWrap = styled.div`
  > div {
    border-bottom: 1px solid #e4e4e4;
  }
`;

const ScheduleInfo = styled.div`
  border-left: 1px solid #e4e4e4;
`;

const SpaceBar = styled.span`
  width: 1px;
  height: 11px;
  background: #e4e4e4;
`;
const MemoListWrap = styled.div``;

const MemoListItems = styled.div`
  padding: 0.725rem 0;
  border-bottom: 1px solid #e4e4e4;
`;

const StatusOn = styled.div`
  width: 15px;
  height: 15px;
  background: #000000;
  border-radius:50%;
  margin-right: 2px;
`;

const StatusOff = styled.div`
  width: 15px;
  height: 15px;
  background: #ffffff;
  border-radius:50%;
  margin-right: 2px;
  border-style:solid;
  border-width:1px;
  border-color:#000000;
`;


export default MypageBox;
