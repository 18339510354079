import React from "react";
import styled from "styled-components";

const MapIcon = ({ color, size, badge, badgeLeft, badgeSize }) => {
  return (
    <MapIconMarker $badgeLeft={badgeLeft} $size={size} $badgeSize={badgeSize}>
      <img
        src={`assets/img/hospital_map/icon-map-${color}.svg`}
        alt=""
        className="marker"
      />
      {badge ? (
        <img
          src="assets/img/hospital_map/icon-map-maker-badge.svg"
          alt=""
          className="badge"
        />
      ) : (
        ""
      )}
    </MapIconMarker>
  );
};

const MapIconMarker = styled.div`
  position: fixed;
  z-index: 0;
  .marker {
    width: ${({$size}) => $size ? `${$size}px` : "0"};
    position: fixed;
  }
  .badge {
    position: relative;
    top: 0;
    transform: translateY(-35%);
    max-width:initial;
    width: ${({$badgeSize}) => $badgeSize ? `${$badgeSize}px` : "0"};
    left: ${({$badgeLeft}) => $badgeLeft ? `${$badgeLeft}px` : "0"};
  }
`;

export default MapIcon;
