import React, { useEffect, useState } from "react";
import styled from "styled-components";

import HotitalSoonTop from "./components/HospitalSoonTop";
import HospitalRecruit from "./components/HospitalRecruit";
import HospitalInfoCard from "./components/HospitalInfoCard";

import { useLocation,useNavigate } from "react-router-dom";
import axios from "axios";
import gcss_api from "../api/api";
import { getToken } from "../login/Token";
function HospitalSoon() {
  const location = useLocation();
  const navigate = useNavigate();
  const detailKey = { ...location.state };
  const [detailData, setDetailData] = useState(Array());
  useEffect(() => {
    const token = getToken();
    const option = {
      method: 'POST',
      headers: {'Authorization': token, 'Content-Type': 'application/json'},
      url: gcss_api("api_hospital_detail_new"),
      data: JSON.stringify(detailKey),
    }
    axios(option).then((res) => {
      setDetailData(res.data);
    }).catch((err) => {
      if(err?.response?.status === 401) window.location.href = "/logout";
      alert("정보를 더 이상 확인할 수 없습니다.");
      console.log(err); //에러가 안나와서 어떻게 나올지 감이 안잡힘
      navigate(-1);
    });
  }, []);
  
  return (
    <div>
      <HotitalSoonTop infoData={detailData} />
      <ContentBorderTop>
        <HospitalRecruit infoData={detailData}/>
        <HospitalInfoCard infoData={detailData} />
      </ContentBorderTop>
    </div>
  );
}

export const ContentBorderTop = styled.div`
  border-top: 3px solid #f3f3f3;
`;

export default HospitalSoon;
