import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HospitalFilter from "./HospitalFilter";

function SearchBox({ map, mapData }) {
  const [keyword, setKeyword] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [historyVisible, setHistoryVisible] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [searched, setSearched] = useState([]);
  const searchRef = useRef();

  const onSearch = (e) => {
    setKeyword(e.target.value);
  };

  useEffect(() => {
    const searched_temp = JSON.parse(sessionStorage.getItem("searched"));
    if(searched_temp) setSearched(searched_temp);
    if(location?.state?.keyword) {
      setKeyword(location.state.keyword);
      setTimeout(() => {
        if(document.querySelector("#searchList") && location?.state?.scrollTop)
          document.querySelector("#searchList").scrollTo(0, location.state.scrollTop);
      }, 1000);
    }
  },[]);

  useEffect(() => {
    async function data() {
      const regex = new RegExp(`${keyword}`,"gi");
      const data = mapData.filter((item) => regex.test(item.RPRT_INST_NM) || regex.test(item.TEL_NO_CN));
      setHistoryVisible(false);
      setSearchData(data);
    }
    if(keyword !== "") data();
    else {
      setSearchData([]);
      setHistoryVisible(true);
    }
  },[keyword, mapData]);

  const onDetail = (item) => {
    searched.push(item);
    const unique_searched = searched.filter((el, idx) => searched.findIndex(item => item.COMB_KEY === el.COMB_KEY) === idx);
    sessionStorage.setItem("searched", JSON.stringify(unique_searched));
    let to = "";
    switch(item.HOSPITAL_FLAG) {
      case "N": to = "/hospital_soon"; break;
      case "A": to = "/deal"; break;
      case "B": case "B-1": to = "/non_deal"; break;
    }
    if(to === "") return false;
    navigate(to, {
      state: {
        COMB_KEY: item.COMB_KEY,
        RFRNC_TBL_NM: item.RFRNC_TBL_NM,
        DONG_CD: item.DONG_CD,
        from: location,
        y: item.LNL_YCO,
        x: item.LNL_XCO,
        keyword: keyword,
        scrollTop: document.querySelector("#searchList")?.scrollTop,
      }
    });
  };

  const onDelete = (item) => {
    setSearched(prev => prev.filter(el => el !== item));
    sessionStorage.setItem("searched", JSON.stringify(searched));
  };

  return (
    <SearchBoxWrap>
      <SearchInputWrap>
        <button>
          <Link to={"/"} state={{center: map ? map.getCenter() : null}}>
            <img src="assets/img/hospital_list/icon-left-arrow.svg" alt="" />
          </Link>
        </button>
        <div className="relative mx-4 w-full">
          <SearchInput placeholder="병원검색" value={keyword} onChange={onSearch} />
        </div>
      </SearchInputWrap>
      <HospitalFilter mainpage={true} />
      <RecentSearch>
        {historyVisible && (<RecentSearchTitle>최근검색</RecentSearchTitle>)}
        {historyVisible && (<RecentSearchList>
          {searched && searched.map((item) => {
            let img = "";
            switch(item.HOSPITAL_FLAG) {
              case "N": img = "assets/img/hospital_map/icon-map-blue.svg"; break;
              case "A": img = "assets/img/hospital_map/icon-map-green.svg"; break;
              case "B": case "B-1": img = "assets/img/hospital_map/icon-map-green-border.svg"; break;
            }
            return (
          <div className="flex items-center gap-2 mb-3 px-4" key={item.RPRT_INST_NM}>
            <img src={img} alt="" />
            <p onClick={() => onDetail(item)}>{item.RPRT_INST_NM}</p>
            <button className="ml-auto" onClick={() => onDelete(item)}>
              <img src="assets/img/hospital_map/icon-close-gray.svg" alt="" />
            </button>
          </div>
          );})}
          {
            /*
            <img src="assets/img/hospital_map/icon-search-result.svg" alt="" />
            <img src="assets/img/hospital_map/icon-map-green.svg" alt="" /> // 초록
            <img src="assets/img/hospital_map/icon-map-green-border.svg" alt="" /> // 화이트
            <img src="assets/img/hospital_map/icon-map-blue.svg" alt="" />  // 파랑
            <span>장</span>그린 // span에 색적용
            */
          }
        </RecentSearchList>)}
        {!historyVisible && (<RecentSearchTitle>검색결과</RecentSearchTitle>)}
        {!historyVisible && (<RecentSearchList id="searchList">
          {/* TODO 진료과 검색 */}
          {searchData && searchData.map((item) => (
          <div className="treat px-4 py-2" key={item.COMB_KEY}>
            <div className="flex justify-between items-center gap-2 mb-2">
              <p className="treat-name" onClick={() => onDetail(item)}>
                {item.RPRT_INST_NM}
              </p>
              <span className="text-black text-xs font-normal leading-tight">
                {/* 총의사수 0명 - 정보없음 */}
              </span>
            </div>
            <p className="text-black text-xs font-normal leading-tight">
              {item.ADDR_CN}
            </p>
          </div>
          ))}
          {searchData?.length === 0 && (
            <div className="px-4 py-2">
              <p>검색결과가 없습니다.</p>
              <p>검색어 또는 필터를 조정하세요.</p>
            </div>
          )}
        </RecentSearchList>)}
      </RecentSearch>
    </SearchBoxWrap>
  );
}

const SearchBoxWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 201;
  width: 100vw;
  height: 100vh;
  background: #fff;
`;

const SearchInputWrap = styled.div`
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  border-bottom: 1px solid #eff2f3;
`;
const SearchInput = styled.input`
  width: 100%;
  height: 30px;
  border-radius: 6px;
  background: #eff2f3;
  padding: 0 5px;
`;

const RecentSearch = styled.div`
  height: calc(100vh - 105px);
  margin-top: 55px;
`;

const RecentSearchTitle = styled.h2`
  color: #CCC;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 1rem;
  border-bottom: 1px solid #eff2f3;
`;

const RecentSearchList = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 146px);
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  > div > img {
    width: 23px;
  }
  p {
    color: #777777;
    font-weight: 500;
    span {
      color: #00ade6;
    }
  }
  .treat {
    border-bottom: 1px solid #eff2f3;
    .treat-name {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      span {
        color: #00ade6;
      }
    }
  }
`;

export default SearchBox;
