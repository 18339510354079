import { useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server"
import { useNavermaps, Marker, useMap, Overlay } from "react-naver-maps";
import { db } from "../../db";
import { AvailableApt, AvailableBuilding } from "./AreaMap";
import { makeMarkerClustering } from "../../hospital_map/components/marker-cluster";
import MapArea from "../../hospital_map/components/MapArea";

let cluster = [];
let markers = [];

export default function AvailableMaker({filter, clickMarkerHandler, selector, bounds, loading}) {
    const navermaps = useNavermaps();
    const map = useMap();
    const [aptData, setAptData] = useState(Array());
    const [shopData, setShopData] = useState(Array());
    const MarkerClustering = makeMarkerClustering(navermaps);

    function buildingIcon(item) {
        const content = renderToStaticMarkup(<AvailableBuilding item={item} />);
        return {
            content,
            size: new navermaps.Size(50, 57),
        };
    }

    function aptIcon(item) {
        const content = renderToStaticMarkup(<AvailableApt item={item} />);
        return {
            content,
            size: new navermaps.Size(57, 67),
        }
    }

    function clusterIcon() {
        const icons = [];
        const clusterSize = [40, 55, 70, 85, 100];
        clusterSize.map((item) => {
            const content = renderToStaticMarkup(<MapArea color="darkBlue" size={item} />);
            const icon = {
                content,
                size: new navermaps.Size(item, item),
                anchor: new navermaps.Point(item/2, item/2),
            }
            icons.push(icon);
        })
        return icons;
    }

    useEffect(() => {
        async function data() {
            const apt = await db.available.where({name: "NEW_APT_INFO_RES"}).toArray();
            const shop = await db.available.where({name: "NEW_SHOP_INFO_RES"}).toArray();
            if(apt[0]) setAptData(apt[0].data);
            if(shop[0]) setShopData(shop[0].data);
        }
        data();
    },[loading]);

    useEffect(() => {
        initCluster();
    }, [aptData, shopData, bounds, filter]);

    const initCluster = () => {
        const aptMarkers = [];
        const shopMarkers = [];

        if(aptData && bounds) {
            aptData.map((item) => {
                const latLng = new navermaps.LatLng(item.NEW_APARTMENT_INFO.LNL_YCO, item.NEW_APARTMENT_INFO.LNL_XCO);
                if(bounds.hasLatLng(latLng)) {
                    aptMarkers.push(item);
                }
            });
        }
    
        if(shopData && bounds) {
            shopData.map((item) => {
                const latLng = new navermaps.LatLng(item.NEW_SHOP_INFO.LNL_YCO, item.NEW_SHOP_INFO.LNL_XCO);
                if(bounds.hasLatLng(latLng)) {
                    shopMarkers.push(item);
                }
            });
        }

        markers.map((item) => item.setMap(null));
        markers = [];
        cluster.map((item) => item.setMap(null));
        cluster = [];
        

        if(filter !== "NEW_SHOP_INFO_RES") {
            aptMarkers.map((item) => {
                const zIndex = item === selector ? 10 : 5;
                const marker = new navermaps.Marker({
                    position: new navermaps.LatLng(item.NEW_APARTMENT_INFO.LNL_YCO, item.NEW_APARTMENT_INFO.LNL_XCO),
                    title: item.NEW_APARTMENT_INFO.APT_NM,
                    zIndex: zIndex,
                    icon: aptIcon(item),
                });
                marker.item = item;
                navermaps.Event.addListener(marker, "click", () => clickMarkerHandler([marker], marker));
                markers.push(marker);
            });
        }
    
        if(filter !== "NEW_APT_INFO_RES") {
            shopMarkers.map((item) => {
                const zIndex = item === selector ? 10 : 5;
                const marker = new navermaps.Marker({
                    position: new navermaps.LatLng(item.NEW_SHOP_INFO.LNL_YCO, item.NEW_SHOP_INFO.LNL_XCO),
                    title: item.NEW_SHOP_INFO.BDST_NM,
                    zIndex: zIndex,
                    icon: buildingIcon(item),
                });
                marker.item = item;
                navermaps.Event.addListener(marker, "click", () => clickMarkerHandler([marker], marker));
                markers.push(marker);
            });
        }
    
        cluster.push(new MarkerClustering({
            minClusterSize: 1,
            maxZoom: 16,
            map: map,
            markers: markers,
            disableClickZoom: true,
            gridSize: 150,
            icons: clusterIcon(),
            indexGenerator: [10, 100, 200, 500, 1000],
            stylingFunction: function (clusterMarker, count, member) {
                clusterMarker.getElement().querySelector('div:first-child').innerText = count;
                if(member?.length === selector?.length) {
                    if(member[0].position.equals(selector[0].position)) {
                        clusterMarker.eventTarget.classList.add("actCluster");
                    }
                }
                navermaps.Event.addListener(clusterMarker, "click", () => {
                    clickMarkerHandler(member, clusterMarker);
                });
            },
        }));
    };

    return null;
}