import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { ymd_ago, ymd_format } from "../../common/PageTop";

function AvailableDetailTop() {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location?.pathname;

  const item = location?.state?.item;
  const to = location?.state?.from?.pathname;
  const center = location?.state?.center;

  const onBtnMap = () => {
    navigate("/available", {state: {center: center}});
  };

  return (
    <AvailableDetailTopWrap>
      <DetailTop>
        <Link to={to} state={{center: center}}>
          <img src="assets/img/hospital_list/icon-left-arrow.svg" alt="" />
        </Link>
        <BtnInfoTop onClick={onBtnMap}>지도</BtnInfoTop>
      </DetailTop>

      {pathname === "/apt_detail" ? (
        <BuildingText className="mb-4">
          <h2>
            {item.NEW_APARTMENT_INFO.APT_NM} <span>({item.NEW_APARTMENT_INFO.HH_CNT.toLocaleString()}세대)</span>
          </h2>
          <div>
            <span className="red">{ymd_ago(item.NEW_APARTMENT_INFO.MOVIN_YM)}</span>
            <p>{ymd_format(item.NEW_APARTMENT_INFO.MOVIN_YM, true).replace("0일", "")} 입주예정</p>
          </div>
          <div>
            <img src="assets/img/hospital_map/icon-map.svg" alt="지도아이콘" />
            <p>{item.NEW_APARTMENT_INFO.ADDR_CN}</p>
          </div>
        </BuildingText>
      ) : null}

      {pathname === "/shop_detail" ? (
        <BuildingText className="mb-4">
          <h2>
            {item.NEW_SHOP_INFO.BDST_NM}<span>(총 {item.NEW_SHOP_INFO.BDST_TFAR.toLocaleString()}m2)</span>
          </h2>
          <div>
            <span className="blue">{ymd_ago(item.NEW_SHOP_INFO.USE_APRV_YMD)}</span>
            <p>{ymd_format(item.NEW_SHOP_INFO.USE_APRV_YMD, true)} 사용승인</p>
          </div>
          <div>
            <img src="assets/img/hospital_map/icon-map.svg" alt="지도아이콘" />
            <p>{item.NEW_SHOP_INFO.ADDR_CN}</p>
          </div>
        </BuildingText>
      ) : null}
    </AvailableDetailTopWrap>
  );
}

export const AvailableDetailTopWrap = styled.div`
  border-bottom: 3px solid #f3f3f3;
  padding: 0 1rem;
`;

export const DetailTop = styled.div`
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const BuildingText = styled.div`
  h2 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    span {
      font-size: 13px;
    }
  }
  div {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
    span {
      font-size: 13px;
      padding: 2px 6px;
      border-radius: 20px;
      &.red {
        color: #d20000;
        border: 1px solid #d78c8c;
        background: #fed5d5;
      }
      &.blue {
        color: #002b49;
        border: 1px solid #002b49;
        background: #eef4f8;
      }
    }
    p {
      color: #000;
      font-size: 13px;
    }
  }
`;

const BtnInfoTop = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #dadada;
  background: #fff;
  color: #072c49;
  font-size: 14px;
  padding: 3px;
  gap: 0 2px;
  img {
    width: 20px;
  }
`;

export default AvailableDetailTop;
