import React from "react";
import styled from "styled-components";

const mokupData = [
  {
    name: "검사목록 전체(검사명,코드명)",
  },
  {
    name: "수가변동검사",
  },
  {
    name: "신규검사",
  },
];
const AiAnswer = () => {
  return (
    <AiAnswerWrap>
      {mokupData.map((item) => (
        <AnswerKeyword key={item.name}>{item.name}</AnswerKeyword>
      ))}
    </AiAnswerWrap>
  );
};

const AiAnswerWrap = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

const AnswerKeyword = styled.button`
  border-radius: 20px;
  border: 1px solid #8caad7;
  background: #e6e9f4;
  color: #002b49;
  font-size: 13px;
  padding: 4px 15px;
`;

export default AiAnswer;
