import React from "react";
import styled from "styled-components";

//둥근 사각 날짜 Label
const DayBadgeSquare = ({ title }) => {
  return title ? <DayBadgeSquareWrap>{title}</DayBadgeSquareWrap> : "";
};

const DayBadgeSquareWrap = styled.span`
  padding: 2px 5px;
  border-radius: 5px;
  color: #007a33;
  border: 1px solid #007a33;
  font-size: 11px;
  font-weight: 500;
`;

export default DayBadgeSquare;
