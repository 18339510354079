import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

const Ga4 = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        ReactGA.initialize("G-N3TWX3VYM0");
        setInitialized(true);
    }, []);

    useEffect(() => {
        if(initialized) {
            ReactGA.set({page: location.pathname});
            ReactGA.send("pageview");
        }
    }, [initialized, location]);
};

export default Ga4;