import React from "react";
import styled from "styled-components";

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const steps = [
  {
    status: 0,
    text: "반경",
  },
  {
    status: 200,
    text: "200m",
  },
  {
    status: 500,
    text: "500m",
  },
  {
    status: 1000,
    text: "1km",
  },
];

export default function DistanceProgress({status, setStatus}) {
  const transfer = {status: status};

  const getStepPosition = (transferStatus) => {
    return steps.findIndex(({ status }) => status === transferStatus);
  };

  return (
    <DistanceProgressWrap>
      <div>
        <ProgressBar
          height={3}
          percent={99 * (getStepPosition(transfer.status) / (steps.length - 1)) + 1}
          filledBackground="#00ADE6"
        >
          {steps.map((step, index, arr) => {
            return (
              <Step key={index}
                position={100 * (index / arr.length)}
                children={({ accomplished }) => (
                  <div onClick={() => {if(step.status !== 0) setStatus(step.status);}}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      width: 12,
                      height: 12,
                      backgroundColor: accomplished ? "#00ADE6" : "white",
                      border: accomplished
                        ? "1px solid #00ADE6"
                        : "1px solid #9E9E9E",
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        width: "30px",
                        position: "absolute",
                        top: "-17px",
                        fontSize: "12px",
                      }}
                    >
                      {step.text}
                    </span>
                  </div>
                )}
              />
            );
          })}
        </ProgressBar>
      </div>
    </DistanceProgressWrap>
  );
}

const DistanceProgressWrap = styled.div`
  padding: 30px 1.5rem 0;
  height: 50px;
  background: #fff;
  max-width: 768px;
  margin: 55px auto 0;
`;
