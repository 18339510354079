import React from "react";
import { HospitalInfoContent } from "../../hospital_map/components/HospitalInfo";

function HospitalSoonTop({infoData}) {
  return (
    <div className="mt-3 px-4 z-10 relative">
      <HospitalInfoContent hospital={infoData}/>
    </div>
  );
}

export default HospitalSoonTop;
