import { useState } from "react";
import styled from "styled-components";

function AiChatBottom() {
  const [voiceChat, setVoiceChat] = useState(false);

  const voiceChatHandler = () => {
    setVoiceChat(true);
  };
  return (
    <AiChatBottomWrap>
      <BottomContentWrap>
        <InputWrap>
          <input type="text" placeholder="검사정보에 관해 질문을 해주세요" />
          <button>
            <img src="assets/img/ai_chat/icon-send.svg" alt="" />
          </button>
        </InputWrap>
        <BtnWrap>
          {voiceChat ? (
            <button>
              <img src="assets/img/ai_chat/icon-keyboard.svg" alt="" />
            </button>
          ) : (
            <button onClick={voiceChatHandler}>
              <img src="assets/img/ai_chat/icon-chat-mic.svg" alt="" />
            </button>
          )}
        </BtnWrap>
      </BottomContentWrap>
      {voiceChat ? (
        <BtnVoiceChat>
          <img src="assets/img/ai_chat/icon-chat-mic.svg" />
        </BtnVoiceChat>
      ) : null}
    </AiChatBottomWrap>
  );
}

const AiChatBottomWrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 3px solid rgba(43, 43, 43, 0.06);
  background: #fff;
  padding: 12px 1rem;
`;

const BottomContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

const InputWrap = styled.div`
  position: relative;
  width: calc(100% - 50px);
  input {
    width: 100%;
    border-radius: 50px;
    background: #f0f3f6;
    text-indent: 10px;
    font-size: 14px;
    &::placeholder {
      color: #adb3bc;
    }
  }
  button {
    margin-left: auto;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -12.5px;
  }
`;

const BtnWrap = styled.div``;

const BtnVoiceChat = styled.div`
  text-align: center;
  margin-top: 1rem;
  img {
    display: inline-block;
    width: 64px;
  }
`;

export default AiChatBottom;
