import Dexie from 'dexie';

export const db = new Dexie('gcss');
db.version(1).stores({
    hospital: '&COMB_KEY, RPRT_INST_NM, LNL_XCO, LNL_YCO, DONG_CTPV_CD, DONG_SGG_CD, DONG_CD, ADDR_CN, TEL_NO_CN, RFRNC_TBL_NM, HOPN_STEP_NM, RECN_YN, LCPMT_YN, HOPN_YN, RECN_YMD, LCPMT_YMD, HOPN_YMD, DEPARTMENT_LIST, HOSPITAL_FLAG, FAMILY_COUNT',
    updateTime: '&name, time',
    city: '&DONG_CTPV_CD, DONG_CTPV_NM',
    sgg: '&DONG_SGG_CD, DONG_SGG_NM',
    dong: '&DONG_CD, DONG_NM',
    available: '&name, data',
});