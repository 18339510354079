import React, { children } from "react";
import styled from "styled-components";

function AlarmCard({ title, icon, children }) {
  return (
    <AlarmCardWrap className={title === "" ? "content" : ""}>
      <AlarmCardTop className={icon}>
        {icon && <img src={`assets/img/hospital_map/alram-title-${icon}.svg`} alt="" />}
        <h3>{title}</h3>
      </AlarmCardTop>
      <AlarmCardContent>{children}</AlarmCardContent>
    </AlarmCardWrap>
  );
}

const AlarmCardWrap = styled.div`
  border-radius: 10px;
  background: #fff;
  box-shadow: 3px 3px 10px 0px rgba(216, 229, 233, 0.5);
  margin: 1rem;
  &.content {
    flex: 1;
    overflow-y: auto;
  }
`;

const AlarmCardTop = styled.div`
  padding: 0 10px;
  border-radius: 10px 10px 0px 0px;
  background: #fff;
  height: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  &.notice {
    height: 38px;
    border-bottom: 1px solid #e4e4e4;
  }

  h3 {
    color: #003769;
    font-size: 15px;
    font-weight: 600;
  }
`;

const AlarmCardContent = styled.div`
  padding: 0 0 10px;
`;
export default AlarmCard;
