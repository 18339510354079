import React from "react";

const HospitalLoation = ({item, dong, sgg}) => {
  return (
    <div className="flex items-center gap-1 p-2">
      <img src="assets/img/hospital_list/icon-location.svg" alt="위치아이콘" />
      <span className="text-black text-xs font-normal leading-tight">
        {sgg[item.DONG_SGG_CD]}
      </span>
      <img
        src="assets/img/hospital_list/icon-location-arrow.svg"
        alt="왼쪽화살표"
      />
      <span className="text-black text-xs font-normal leading-tight">
        {dong[item.DONG_CD]}
      </span>
    </div>
  );
};

export default HospitalLoation;
