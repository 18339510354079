import React from "react";
import styled from "styled-components";
import { ModalInner } from "../../login/components/AgreeModal";

const CsModal = ({ csHideHandler }) => {
  return (
    <>
      <SalesModalWrap className="modal-cs">
        <div className="fixed inset-0 bg-black/25 backdrop-blur-sm">
          <div className=" h-max mx-4 my-8 z-20 relative">
            <ModalInner className="w-full h-max bg-white rounded-lg">
              <SalesModalTop className="flex justify-between items-center px-4 py-2">
                <h3 className="text-black text-lg font-semibold ">문의이력</h3>
                <button onClick={csHideHandler}>
                  <img
                    src="assets/img/hospital_map/icon-close-gray.svg"
                    style={{ width: "32px" }}
                  />
                </button>
              </SalesModalTop>

              <SalesModalBottom>
                <div className="flex items-center gap-1 mb-4">
                  <DayFilter className="active">1개월</DayFilter>
                  <DayFilter>3개월</DayFilter>
                  <DayFilter>6개월</DayFilter>
                  <DayFilter>1년</DayFilter>
                </div>
                <OpinionItem>
                  <OpinionItemInfo>
                    <img
                      src="assets/img/hospital_map/icon-clock-gray.svg"
                      alt=""
                    />
                    <p className="text-neutral-500">5일전 (2024.02.01 14:24)</p>
                  </OpinionItemInfo>
                  <OpinitonText>
                    N971   모발 중금속 및 미네랄 40종 검사 (element analysis)
                    [Hair] 소요일
                  </OpinitonText>
                </OpinionItem>
                <OpinionItem>
                  <OpinionItemInfo>
                    <img
                      src="assets/img/hospital_map/icon-clock-gray.svg"
                      alt=""
                    />
                    <p className="text-neutral-500">5일전 (2024.02.01 14:24)</p>
                  </OpinionItemInfo>
                  <OpinitonText>
                    N971   모발 중금속 및 미네랄 40종 검사 (element analysis)
                    [Hair] 소요일
                  </OpinitonText>
                </OpinionItem>
              </SalesModalBottom>
            </ModalInner>
          </div>
        </div>
      </SalesModalWrap>
    </>
  );
};

const SalesModalWrap = styled.div`
  &.modal-cs {
    position: fixed;
    z-index: 11;
  }
`;

const SalesModalTop = styled.div`
  border-bottom: 1px solid #e4e4e4;
`;

const SalesModalBottom = styled.div`
  padding: 10px 1rem 30px;
`;

const OpinionItem = styled.div`
  padding: 0.725rem 0;
  border-bottom: 1px solid #e4e4e4;
`;

const OpinionItemInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
`;

const OpinitonText = styled.p`
  font-size: 13px;
  margin-top: 0.725rem;
  padding-left: 14px;
`;

const DayFilter = styled.button`
  width: 60px;
  height: 28px;
  color: #000;
  font-size: 13px;
  border: 1px solid #c3c3c3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  &.active {
    color: #1d3480;
    border: 1px solid #7185c5;
    background: #e3e9fc;
  }
`;

export default CsModal;
