import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "./components/Header";
import FloatingBtns from "./components/FloatingBtns";
import HospitalFilter from "./components/HospitalFilter";
import HospitalInfo from "./components/HospitalInfo";
import Makers from "./components/Makers";
import axios from "axios";
import gcss_api from "../api/api";
import { getToken } from "../login/Token";
import { getFilterData, setFilterData, setFilterDataAll } from "./Filter";
import { db } from "../db";
import { Container as MapDiv, NaverMap, useNavermaps } from 'react-naver-maps';
import { useLocation } from "react-router-dom";
import Loading from "../common/Loading";
import OpinionModal from "./components/OpinionModal";
import { getUser } from "../login/User";

import { useCookies } from 'react-cookie';
import moment from 'moment';

let appToken = "";

function HospitalMap() {
  const [infoVisible, setInfoVisible] = useState(false);
  const [infoData, setInfoData] = useState([]);
  const [map, setMap] = useState();
  const [mapData, setMapData] = useState(Array());
  const [selector, setSelector] = useState();
  const [bounds, setBounds] = useState();
  const navermaps = useNavermaps();
  const location = useLocation();
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [mapHeight, setMapHeight] = useState(103);
  const [cookies, setCookie] = useCookies(['user']);

  const token = getToken();
  let option = {
    method: 'GET',
    headers: { 'Authorization': token, 'Content-Type': 'application/json' },
    url: "",
  }

  useEffect(() => {

    fc_hospital_data_getTime();
    fc_get_city_code();
    fc_get_sgg_code();
    fc_get_dong_code();
  }, []);

  async function fc_hospital_data_getTime() {

    const today = new Date();
    const hospital_data_getTime = await db.updateTime.where({ name: "hospital_time" }).toArray();
    const username = cookies.username;

    if (hospital_data_getTime === undefined || hospital_data_getTime.length === 0 || hospital_data_getTime[0].time < today || username !== getUser() ) {
      console.log('$$$$$$$$$$$ check total start1');
      option.url = gcss_api("api_hospital_total");
      setLoadingVisible(true);
      db.hospital.clear();
      axios(option).then((res) => {
        db.hospital.bulkPut(res.data).then(() => {
          setLoadingVisible(false);
          const tomorrow = new Date(today);
          tomorrow.setDate(tomorrow.getDate() + 1);
          db.updateTime.put({ name: "hospital_time", time: new Date(tomorrow.getFullYear() + "-" + (tomorrow.getMonth() + 1) + "-" + tomorrow.getDate()) });
          const expires =  moment().add('1','d').toDate()
          setCookie('username',getUser(),{expires});
          filterHospitalData();
        });
      }).catch((err) => {
        if (err?.response?.status === 401) window.location.href = "/logout";
        console.log(err); //에러가 안나와서 어떻게 나올지 감이 안잡힘
      });
    }else{
      filterHospitalData();
    }
  }

  async function fc_get_city_code() {
    const city = await db.city.count();
    if (city === undefined || city === 0) {
      option.url = gcss_api("api_code_area-level1");
      axios(option).then((res) => {
        db.city.bulkPut(res.data);
      }).catch((err) => {
        if (err?.response?.status === 401) window.location.href = "/logout";
        console.log(err);
      });
    }
  }

  async function fc_get_sgg_code() {
    const sgg = await db.sgg.count();
    if (sgg === undefined || sgg === 0) {
      option.url = gcss_api("api_code_area-level2");
      axios(option).then((res) => {
        db.sgg.bulkPut(res.data);
      }).catch((err) => {
        if (err?.response?.status === 401) window.location.href = "/logout";
        console.log(err);
      });
    }
  }

  async function fc_get_dong_code() {
    const dong = await db.dong.count();
    if (dong === undefined || dong === 0) {
      option.url = gcss_api("api_code_area-level3");
      axios(option).then((res) => {
        db.dong.bulkPut(res.data);
      }).catch((err) => {
        if (err?.response?.status === 401) window.location.href = "/logout";
        console.log(err);
      });
    }
  }
  
  const filterHospitalData = async () => {
    const flag_filter = getFilterData("hospital-flag");
    const department_filter = getFilterData("department");
    if(flag_filter && department_filter) {
      const data = await db.hospital
        .filter((item) => flag_filter.some(data => item.HOSPITAL_FLAG.substring(0,1) === data))
        .filter((item) => item.DEPARTMENT_LIST.length === 0 || department_filter.some(data => item.DEPARTMENT_LIST.some(code => code === data)))
        .toArray();
      setMapData(data);
    }
  }

  const clickMarkerHandler = (data, cluster) => {
    setInfoData(data);
    if(data) setInfoVisible(true);
    if(cluster?.item) setSelector(cluster?.item?.COMB_KEY);
    else setSelector(cluster);
    map.autoResize();
    map.panTo(cluster?.position, {duration: 0, easing: "linear"});
  };

  const filterChange = () => {
    filterHospitalData();
  };

  const cancelSelect = () => {
    setSelector(null);
    setInfoVisible(false);
    setMapHeight(103);
    map.autoResize();
  };

  useEffect(() => {
    if(map) {
      async function selectCombKey() {
        const data = await db.hospital.filter(item => item.COMB_KEY === location.state.COMB_KEY).toArray();
        if(data[0]?.LNL_YCO) {
          map.panTo(new navermaps.LatLng(data[0].LNL_YCO, data[0].LNL_XCO));
          setInfoData([data[0]]);
          if(data[0]) setInfoVisible(true);
          setSelector(location.state.COMB_KEY);
        }
      }
      if(location?.state?.COMB_KEY) selectCombKey();
      else if(location?.state?.center) map.panTo(location.state.center);
      else if(location?.state && location.state.x && location.state.y) map.panTo(new navermaps.LatLng(location.state.y, location.state.x));
      else if(location?.state?.from?.state?.center) map.panTo(location.state.from.state.center);
      else {
        option.url = gcss_api("api_center-point");
        axios(option).then((res) => {
          map.panTo(new navermaps.LatLng(res.data.Y, res.data.X));
        });
      }
      setBounds(map.getBounds());
    }
  },[map]);

  const offModalOpinionHandler = () => {
    setOpenModal(false);
  };

  const sendPushToken = (pushToken) => {
    option.url = gcss_api("api_alarm_device-token");
    axios(option).then((res) => {
      if(pushToken !== res) {
        const options = {
          ...option, method: "POST", data: JSON.stringify({"DEVICE_TOKEN": pushToken})
        };
        axios(options).then((res) => {
          console.log(res);
          appToken = pushToken;
        }).catch((err) => {
          if(err?.response?.status === 401) window.location.href = "/logout";
          console.log(err);
        });
      }
    }).catch((err) => {
      if(err?.response?.status === 401) window.location.href = "/logout";
      console.log(err);
    })
  };

  if(window.gcss && appToken === "") {
    const pushToken = window.gcss.getPushToken();
    sendPushToken(pushToken);
  } else if(window.webkit?.messageHandlers?.getPushToken?.postMessage && appToken === "") {
    window.webkit.messageHandlers.getPushToken.postMessage("gcss");
    window.setPushToken = (pushToken) => {
      sendPushToken(pushToken);
    }
  }

  useEffect(() => {
    if(infoData.length) {
      if(infoData[0] !== undefined) {
        if(infoData.length > 2) setMapHeight(380 + 103);
        else if(infoData.length > 1) setMapHeight(280 + 103);
        else setMapHeight(155 + 103);
      }
    }
  }, [infoData]);

  useEffect(() => {
    if(map) map.autoResize();
  }, [mapHeight]);
  
  useEffect(() => {
    if(location?.state?.memo) {
      if(location?.state?.COMB_KEY) {
        async function selectCombKey() {
          const data = await db.hospital.filter(item => item.COMB_KEY === location.state.COMB_KEY).toArray();
          if(data[0]?.LNL_YCO) {
            map.panTo(new navermaps.LatLng(data[0].LNL_YCO, data[0].LNL_XCO));
            setInfoData([data[0]]);
            if(data[0]) setInfoVisible(true);
            setSelector(location.state.COMB_KEY);
          }
        }
        selectCombKey();
      }
    }
  }, [location]);

  return (
    <HospitalMapWrap>
      <MapDiv style={{ width: "100vw", height: "calc(100vh - " + mapHeight + "px)", top: "103px" }}>
        <NaverMap ref={setMap} onClick={()=>cancelSelect()} zoom={16}>
          <Makers mapData={mapData} bounds={bounds} clickMarkerHandler={clickMarkerHandler} selector={selector} setInfoData={setInfoData} setInfoVisible={setInfoVisible}/>
        </NaverMap>
        <BtnOpinion onClick={() => setOpenModal(true)}>
          <img src="assets/img/hospital_map/icon-opinion.svg" alt="" />
        </BtnOpinion>
      </MapDiv>
      <Header map={map} mapData={mapData} />
      <FloatingBtns map={map}/>
      <HospitalFilter mainpage={true} mapChange={filterChange} mapData={mapData} filterHospitalData={filterHospitalData}/>
      {infoVisible && infoData[0] !== undefined && (<HospitalInfo infoData={infoData} />)}
      <OpinionModal openModal={openModal} offModalOpinionHandler={offModalOpinionHandler} />
      <Loading isVisible={loadingVisible}/>
    </HospitalMapWrap>
  );
}

const HospitalMapWrap = styled.div`
  /* max-width: 768px;
  margin: 0 auto;
  height: 100vh;
  width: 100vw; */
`;

const BtnOpinion = styled.button`
  position: fixed;
  top: 120px;
  right: 5px;
  z-index: 0;
`;

export default HospitalMap;
