import React, { useEffect } from "react";
import styled from "styled-components";
import { useState } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { BrickLabel } from "../hospital_map/components/HospitalFilter";
import { IconArrowON } from "../hospital_map/components/IconArrowON";

function PeriodSetting({setIsVisible, isVisible, mapChange}) {
  const [value, setValue] = useState([0, 6]);

  const BtnRun = (id) => {
    if(id === 1) {
      //필터적용하기
      sessionStorage.setItem("filter-range", JSON.stringify(value));
      mapChange();
    }
    setIsVisible(false);
  }

  useEffect(() => {
    const range = JSON.parse(sessionStorage.getItem("filter-range"));
    setTimeout(() => setValue(range), 2000);
  }, [isVisible]);

  return (
    <>
      <PeriodSettingWrap className="px-4 py-4">
        <RangeSlider min={0} max={6} step={0.5} value={value} onInput={setValue} />
        <ul className="flex justify-between items-center mt-2 text-xs">
          <li className="text-transparent">0년</li>
          <li>1년</li>
          <li>2년</li>
          <li>3년</li>
          <li>4년</li>
          <li>5년</li>
          <li>6년+</li>
        </ul>
        <br/>
        <TabContents>
          <div className="flex items-center flex-wrap gap-1">
            <BrickLabel onClick={() => setValue([0,6])}>전체</BrickLabel>
            <BrickLabel onClick={() => setValue([0,1])}>~1년</BrickLabel>
            <BrickLabel onClick={() => setValue([0,2])}>~2년</BrickLabel>
            <BrickLabel onClick={() => setValue([0,3])}>~3년</BrickLabel>
            <BrickLabel onClick={() => setValue([0,4])}>~4년</BrickLabel>
            <BrickLabel onClick={() => setValue([0.5,1])}>0.5~1년</BrickLabel>
            <BrickLabel onClick={() => setValue([1.5,2])}>1.5~2년</BrickLabel>
            <BrickLabel onClick={() => setValue([2.5,3])}>2.5~3년</BrickLabel>
          </div>
        </TabContents>
      </PeriodSettingWrap>
      <div className="flex justify-between items-center px-2 pb-2 shadow-md">
        <div className="flex gap-x-2">
          <BtnFillterBottom onClick={() => BtnRun(1)}>
            <img src="assets/img/hospital_map/icon-filter.svg" alt="필터적용하기" />필터적용하기
          </BtnFillterBottom>
        </div>
        <div className="flex gap-x-2">
          <IconArrowON btnRun={() => BtnRun(4)} />
        </div>
      </div>
    </>
  );
}

const PeriodSettingWrap = styled.div`
  .range-slider {
    height: 3px;
    input {
      visibility: hidden;
    }
    .range-slider__thumb {
      width: 14px;
      height: 14px;
    }
  }
`;

export const TabContents = styled.div`
  margin: 0 0.5rem;
  padding: 10px 0 20px;
`;

const BtnFillterBottom = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 2px;
  color: #7e7e7e;
  font-size: 13px;
  font-weight: 400;
`;

export default PeriodSetting;
