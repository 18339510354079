import React, { useState } from "react";
import styled from "styled-components";
import { getToken } from "../../login/Token";
import gcss_api from "../../api/api";
import axios from "axios";

const MemoReply = ({item, getSales, closeReply}) => {
  const [memo, setMemo] = useState("");
  const token = getToken();
  const option = {
    headers: {'Authorization': token, 'Content-Type': 'application/json'},
    url: gcss_api("api_memo_hospital"),
  };

  const onWrite = () => {
    if(memo === "") {
      alert("내용을 입력하고 등록해주세요.");
    } else {
      option.method = "POST";
      option.data = JSON.stringify({"MEMO": memo, "COMB_KEY": item.COMB_KEY, "SHOW_FLAG": item.RLS_LVL_CD, "PARENT_MEMO_SEQ": item.MEMO_SEQ});
      axios(option).then(() => {
        getSales();
        closeReply();
      }).catch((err) => {
        if(err?.response?.status === 401) window.location.href = "/logout";
        console.log(err);
        alert("영업메모 등록에 실패하였습니다.");
      });
    }
  };

  return (
    <MemoModalBottom>
      <textarea
        rows="5"
        className="w-full rounded grow shrink basis-0 text-sm font-normal"
        placeholder="답글쓰기"
        value={memo}
        onChange={(e) => setMemo(e.target.value)}
      />
      <div className="my-2 py-1 flex justify-end gap-2">
        <button className="py-2 w-16 bg-gray-200 rounded text-neutral-400 text-normal font-semibold" onClick={() => closeReply()}>취소</button>
        <button className="py-2 w-16 bg-sky-500 rounded text-white text-normal font-semibold" onClick={() => onWrite()}>등록</button>
      </div>
    </MemoModalBottom>
  );
};

const MemoModalBottom = styled.div`
  padding: 0 0 0 3rem;
  textarea {
    border: 1px solid #6da0d2;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  }
`;

export default MemoReply;