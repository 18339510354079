import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getUser } from "../../login/User";
import SearchBox from "./SearchBox";
import AlarmBox from "./AlarmBox";
import { Link, useLocation } from "react-router-dom";
import MypageBox from "./MypageBox";

function Header({map, mapData}) {
  const user = getUser();
  const location = useLocation();

  const [showSearch, setShowSearch] = useState(false);
  const [showAlarm, setShowAlarm] = useState(false);

  const [showMypage, setShowMypage] = useState(false);

  const searchShowHandler = () => setShowSearch(true);
  const searchHideHandler = () => setShowSearch(false);

  const alarmShowHandler = () => setShowAlarm(true);
  const alarmHideHandler = () => setShowAlarm(false);

  const mypageShowHandler = () => setShowMypage(true);
  const mypageHideHandler = () => setShowMypage(false);

  useEffect(() => {
    if(location?.state?.keyword !== undefined) setShowSearch(true);
    if(location?.state?.alarm) setShowAlarm(true);
  },[]);

  useEffect(() => {
    if(location?.state?.alarm) setShowAlarm(true);
  }, [location]);

  return (
    <>
      <HeaderWrap>
        <Logo>
          <img src="assets/img/hospital_map/it-logo.svg" width={78} height={28} />
        </Logo>
        <HeaderInfo>
          <button onClick={searchShowHandler}>
            <img src="assets/img/hospital_map/icon-search.svg" alt="" />
          </button>
          <button onClick={alarmShowHandler}>
            <img src="assets/img/hospital_map/icon-bell.svg" alt="" />
          </button>
          {false && <button>
            <Link to={"/ai_chat"} state={{from: location, center: map ? map.getCenter() : null}}>
              <img src="assets/img/hospital_map/icon-chat.svg" alt="" />
            </Link>
          </button>}
          <button onClick={mypageShowHandler}>
          <p className="user-name">{user}</p>
          </button>
          {/* <button className="text-white border-0 border-l pl-2 border-solid text-sm">
            <Link to={"/logout"}>Logout</Link>
          </button> */}
        </HeaderInfo>
      </HeaderWrap>
      {showSearch ? <SearchBox searchHideHandler={searchHideHandler} mapData={mapData}/> : null}
      {showAlarm ? <AlarmBox alarmHideHandler={alarmHideHandler} /> : null}
      {showMypage && <MypageBox mypageHideHandler={mypageHideHandler}/>}
    </>
  );
}

const HeaderWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #072c49;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
`;

const Logo = styled.div``;
const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 0 7px;
  .user-name {
    color: #fff;
    font-size: 15px;
  }
`;

export default Header;
