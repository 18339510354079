import React from "react";
import AvailableDetailTop from "./components/AvailableDetailTop";
import AvailableContent from "./components/AvailableContent";

function AvailableDetail2() {
  return (
    <div>
      <AvailableDetailTop />
      <AvailableContent />
    </div>
  );
}

export default AvailableDetail2;
