import React from "react";
import styled from "styled-components";
import Slider from "react-slick";

const mokupData = [
  {
    keyword: [
      {
        name: "X979 모발 중금속 및 미네랄 40종 검사\n (element analysis) [Hair]",
      },
      { name: "C700 TBPE" },
      { name: "Z928 Cannabinoide(정성)" },
      { name: "C700 TBPEss" },
    ],
  },
  {
    keyword: [
      {
        name: "2-X979 모발 중금속 및 미네랄 40종 검사\n (element analysis) [Hair]",
      },
      { name: "2-C700 TBPE" },
      { name: "2-Z928 Cannabinoide(정성)" },
      { name: "2-C700 TBPEaw" },
    ],
  },
  {
    keyword: [
      {
        name: "3-X979 모발 중금속 및 미네랄 40종 검사\n (element analysis) [Hair]",
      },
      { name: "3-C700 TBPE" },
      { name: "3-Z928 Cannabinoide(정성)" },
      { name: "3-C700 TBPEase" },
    ],
  },
];

const AiAnswerSilck = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: false,
    nextArrow: false,
    className: "keyword-silder",
  };

  return (
    <AiAnswerSilckWrap>
      <div className="slider-container">
        <Slider {...settings}>
          {mokupData.map((item, index) => (
            <div>
              <AnswerKeywordList key={item.keyword} className="here">
                {item.keyword.map((keyword, i) => (
                  <AnswerKeyword key={keyword.name}>
                    {keyword.name}
                  </AnswerKeyword>
                ))}
              </AnswerKeywordList>
            </div>
          ))}
        </Slider>
      </div>
    </AiAnswerSilckWrap>
  );
};

const AiAnswerSilckWrap = styled.div`
  margin: 10px 0 30px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  .slider-container {
    max-width: calc(100vw - 20px);
    .keyword-silder {
      .slick-list {
        height: 100%;
      }
      .slick-dots {
        li {
          margin: 0;
          button {
            padding: 0;
            &:before {
              font-size: 12px;
              opacity: 1;
              color: #fff;
              -webkit-text-stroke-width: 1px;
              -webkit-text-stroke-color: #002b49;
            }
          }
          &.slick-active {
            button {
              &:before {
                color: #002b49;
              }
            }
          }
        }
      }
    }
    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }
`;

const AnswerKeyword = styled.button`
  border-radius: 20px;
  border: 1px solid #8caad7;
  background: #e6e9f4;
  color: #002b49;
  font-size: 13px;
  padding: 4px 15px;
`;

const AnswerKeywordList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;
export default AiAnswerSilck;
