import React from "react";
import styled from "styled-components";

function MemoList() {
  return (
    <MemoListWrap className="px-4 flex flex-col gap-3">
      <MemoListItems>
        <span className="inline-block align-middle py-0.5 px-1 mb-1 bg-zinc-100 text-neutral-500 text-xs">
          장그린내과
        </span>
        <div className="flex items-center gap-1 mb-1 text-xs">
          <img src="assets/img/hospital_map/icon-clock-gray.svg" alt="" />
          <p className="text-neutral-500">5일전 (2024.02.01 14:24)</p>
        </div>
        <div className="flex items-center gap-1 text-xs pl-3  mb-1 ">
          <p>GCCELL</p>
          <SpaceBar />
          <p className="flex items-center gap-2">
            홍길동 <img src="/assets/img/icon-lock.svg" alt="" />
          </p>
        </div>
        <p className="text-xs pl-3">
          개원예정병원이 매일 업데이트되서 좋습니다. <br />
          언제까지 서비스 되나요?
        </p>
      </MemoListItems>

      <MemoListItems>
        <span className="inline-block align-middle py-0.5 px-1 mb-1 bg-zinc-100 text-neutral-500 text-xs">
          아이메드녹십자의료재단
        </span>
        <div className="flex items-center gap-1 mb-1 text-xs">
          <img src="assets/img/hospital_map/icon-clock-gray.svg" alt="" />
          <p className="text-neutral-500">5일전 (2024.02.01 14:24)</p>
        </div>
        <div className="flex items-center gap-1 text-xs pl-3  mb-1 ">
          <p>GCCELL</p>
          <SpaceBar />
          <p className="flex items-center gap-2">홍길동</p>
        </div>
        <p className="text-xs pl-3">
          개원예정병원이 매일 업데이트되서 좋습니다. <br />
          언제까지 서비스 되나요?
        </p>
      </MemoListItems>
    </MemoListWrap>
  );
}

const MemoListWrap = styled.div``;

const MemoListItems = styled.div`
  padding: 0.725rem 0;
  border-bottom: 1px solid #e4e4e4;
`;

const SpaceBar = styled.span`
  width: 1px;
  height: 11px;
  background: #959595;
`;

export default MemoList;
