import React from "react";
import styled from "styled-components";

const InfoCard = ({ title, children }) => {
  return (
    <InfoCardWrap>
      <InfoCardTitleWrap>
        <h4>{title}</h4>
      </InfoCardTitleWrap>
      <InfoCardContent>{children}</InfoCardContent>
    </InfoCardWrap>
  );
};

const InfoCardWrap = styled.div`
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  margin-bottom: 1rem;
`;

const InfoCardTitleWrap = styled.div`
  background: #f6f6f6;
  border-bottom: 2px solid #002b4b;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 10px 10px 0px 0px;
  h4 {
    color: #002b4b;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
`;

const InfoCardContent = styled.div`
  padding: 1rem;
`;

export default InfoCard;
