import React, { useEffect, useState } from "react";
import PageTop from "../common/PageTop";
import FloatingBtns from "../hospital_map/components/FloatingBtns";
import AvailableFilter from "./components/AvailableFilter";
import AreaInfo from "./components/AreaInfo";
import { Container as MapDiv, NaverMap } from "react-naver-maps";
import { useLocation, useNavigate } from "react-router-dom";
import { getToken } from "../login/Token";
import gcss_api from "../api/api";
import axios from "axios";
import { db } from "../db";
import AvailableMarker from "./components/AvailableMarker";
import Loading from "../common/Loading";

function Available() {
  const [map, setMap] = useState();
  const [bounds, setBounds] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState("ALL");
  const [inVisible, setInVisible] = useState(false);
  const [selector, setSelector] = useState();
  const [loadingVisible, setLoadingVisible] = useState(false);

  const token = getToken();
  const option = {
    method: 'GET',
    headers: {'Authorization': token},
    url: gcss_api("api_potential_area"),
  }
  
  useEffect(() => {
    const today = new Date();
    async function fc_available_data_getTime() {
      const available_data_getTime = await db.updateTime.where({name: "available_time"}).toArray();
      if(available_data_getTime === undefined || available_data_getTime.length === 0 || available_data_getTime[0].time < today) {
        setLoadingVisible(true);
        axios(option).then((res) => {
          db.available.clear();
          db.available.put({name: "NEW_APT_INFO_RES", data: res.data.NEW_APT_INFO_RES});
          db.available.put({name: "NEW_SHOP_INFO_RES", data: res.data.NEW_SHOP_INFO_RES}).then(() => {
            setLoadingVisible(false);
          });
          const tomorrow = new Date(today);
          tomorrow.setDate(tomorrow.getDate() + 1);
          db.updateTime.put({name: "available_time", time: new Date(tomorrow.getFullYear() + "-" + (tomorrow.getMonth()+1) + "-" + tomorrow.getDate())});
        }).catch((err) => {
          if(err?.response?.status === 401) window.location.href = "/logout";
          console.log(err); //에러가 안나와서 어떻게 나올지 감이 안잡힘
          if(location?.state?.from?.pathname) {
            alert("개원가능지역 데이터를 받아오지 못했습니다. 이전화면으로 되돌아갑니다.");
            navigate(location.state.from.pathname);
          }
        });
      }
    }
    fc_available_data_getTime();
  },[]);

  useEffect(() => {
    if(map) {
      if(location.state && location.state.center) map.panTo(location.state.center);
      if(!selector) setBounds(map.getBounds());
    }
  },[map]);

  const clickMarkerHandler = (item, cluster) => {
    if(item) setInVisible(true);
    setSelector(item);
    map.panTo(cluster?.position, {duration: 0, easing: "linear"});
  }

  const cancelSelect = () => {
    setSelector(null);
    setInVisible(false);
  };

  return (
    <div>
      <MapDiv style={{ width: "100vw", height: "100vh" }}>
        <NaverMap ref={setMap} onBoundsChanged={(bounds) => setBounds(bounds)} zoom={16} onClick={() => cancelSelect()}>
          <AvailableMarker filter={filter} clickMarkerHandler={clickMarkerHandler} selector={selector} bounds={bounds} loading={loadingVisible}/>
        </NaverMap>
      </MapDiv>
      <PageTop title="개원가능지역" />
      <FloatingBtns map={map}/>
      <AvailableFilter setFilter={setFilter} />
      <AreaInfo inVisible={inVisible} item={selector} />
      <Loading isVisible={loadingVisible}/>
    </div>
  );
}

export default Available;
