import React from "react";
import styled from "styled-components";

import HospitalLoation from "./HospitalLoation";
import DetailItem from "./DetailItem";

function HospitalDetailItem({hospitalData, select, dong, sgg}) {
  let preItem;
  return (
    <HospitalDetailItemWrap>
      {hospitalData && hospitalData.sort((a, b) => {
        if(a.DONG_CD < b.DONG_CD) return -1;
        if(b.DONG_CD < a.DONG_CD) return 1;
        return 0;
      }).map((item) => {
        if(select === "All") {
          if(!preItem || preItem.DONG_CD !== item.DONG_CD) {
            preItem = item;
            return (<div key={item.DONG_CD}><HospitalLoation item={item} dong={dong} sgg={sgg} /><DetailItem item={item} /></div>);
          } else return (<DetailItem item={item} key={item.COMB_KEY} />);
        } else if(item.DONG_CD === select || item.DONG_SGG_CD === select) {
          if(!preItem || preItem.DONG_CD !== item.DONG_CD) {
            preItem = item;
            return (<div key={item.DONG_CD}><HospitalLoation item={item} dong={dong} sgg={sgg} /><DetailItem item={item} /></div>);
          } else return (<DetailItem item={item} key={item.COMB_KEY} />);
        } 
      })}
    </HospitalDetailItemWrap>
  );
}

const HospitalDetailItemWrap = styled.div`
  background: #e4e4e4;
`;

export default HospitalDetailItem;
