import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BtnBack, ymd_ago, ymd_format } from "../../common/PageTop";
import FamilyModal from "./FamilyModal";
import MemoModal from "./MemoModal";
import CsModal from "./CsModal";
import { db } from "../../db";
import axios from "axios";
import gcss_api from "../../api/api";
import { getToken } from "../../login/Token";

function HospitalInfo({ infoData }) {
  return (
    <HospitalInfoWrap className="">
      <Bar className="bg-zinc-500 rounded-full" />
      <ContentBox className="">
        {infoData?.sort((a, b) => {
          if (a.item.RPRT_INST_NM < b.item.RPRT_INST_NM) return -1;
          if (b.item.RPRT_INST_NM < a.item.RPRT_INST_NM) return 1;
          return 0;
        }).map((item, idx) => {
          if (item?.item) return <HospitalInfoContent key={item.item.COMB_KEY} hospital={{ "HOSPITAL_INFO": item.item }} bar={"none"} />
          else if (item) return <HospitalInfoContent key={item.COMB_KEY} hospital={{ "HOSPITAL_INFO": item }} bar={"none"} />
        })}
      </ContentBox>
    </HospitalInfoWrap>
  );
}

export default HospitalInfo;

export const HospitalInfoContent = ({ hospital, bar }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location?.pathname;
  const onBtnBack = () => {
    if (location?.state?.from) {
      navigate(location.state.from?.pathname,
        {
          state: {
            x: location?.state?.x,
            y: location?.state?.y,
            COMB_KEY: location?.state?.COMB_KEY,
            keyword: location?.state?.keyword,
            scrollTop: location?.state?.scrollTop,
            alarm: location?.state?.alarm,
          }
        }
      );
    } else {
      switch (pathname) {
        case "/hospital_soon": navigate("/hospital_list1", { state: { COMB_KEY: location?.state?.COMB_KEY } }); break;
        case "/deal": navigate("/hospital_list2", { state: { DONG_CD: location?.state?.DONG_CD, COMB_KEY: location?.state?.COMB_KEY } }); break;
        case "/non_deal": navigate("/hospital_list3", { state: { DONG_CD: location?.state?.DONG_CD, COMB_KEY: location?.state?.COMB_KEY } }); break;
      }
    }
  };
  const onBtnMap = () => {
    navigate("/hospital_map", { state: { COMB_KEY: hospital?.HOSPITAL_INFO?.COMB_KEY } });
  };

  const [openCsModal, setOpenCsModal] = useState(false);
  const [openMemoModal, setOpenMemoModal] = useState(false);
  const [openFamilyModal, setOpenFamilyModal] = useState(false);

  const csShowHandler = () => setOpenCsModal(true);
  const csHideHandler = () => setOpenCsModal(false);

  const memoShowHandler = () => setOpenMemoModal(true);
  const memoHideHandler = () => setOpenMemoModal(false);

  const familyShowHandler = () => setOpenFamilyModal(true);
  const familyHideHandler = () => setOpenFamilyModal(false);

  const onDetail = () => {
    if (pathname === "/hospital_map") {
      let to = "";
      switch (hospital.HOSPITAL_INFO.HOSPITAL_FLAG) {
        case "N": to = "/hospital_soon"; break;
        case "A": to = "/deal"; break;
        case "B": case "B-1": to = "/non_deal"; break;
      }
      if (to === "") return false;
      navigate(to, {
        state: {
          COMB_KEY: hospital.HOSPITAL_INFO.COMB_KEY,
          RFRNC_TBL_NM: hospital.HOSPITAL_INFO.RFRNC_TBL_NM,
          DONG_CD: hospital.HOSPITAL_INFO.DONG_CD,
          from: location,
          y: hospital.HOSPITAL_INFO.LNL_YCO,
          x: hospital.HOSPITAL_INFO.LNL_XCO,
        }
      });
    } else return false;
  }

  const [memoPopStatus, setMemoPopStatus] = useState(false);
  const [summary, setSummary] = useState();

  const token = getToken();
  const option = {
    method: 'GET',
    headers: { 'Authorization': token, 'Content-Type': 'application/json' },
  };
  
  useEffect(() => {
    if (location?.state?.memo) {
      if (location?.state?.COMB_KEY === hospital?.HOSPITAL_INFO?.COMB_KEY) {
        memoShowHandler();
      }
    }
  }, [location]);

  useEffect(() => {
    if (hospital?.HOSPITAL_INFO?.COMB_KEY) {
      const options = { ...option, url: gcss_api("api_hospitall_summary") + "?comb_key=" + hospital?.HOSPITAL_INFO?.COMB_KEY }
      axios(options).then((res) => {
        setSummary(res.data);
      });
    }else if (location?.state?.COMB_KEY){
      const options = { ...option, url: gcss_api("api_hospitall_summary") + "?comb_key=" + location?.state?.COMB_KEY }
      axios(options).then((res) => {
        setSummary(res.data);
      });
    }
  }, []);

  return (
    <>
      <HospitalInfoInner>
        {bar !== "none" && <Bar className="bg-zinc-500 rounded-full" />}
        <div className="flex justify-end gap-x-1 mb-4 relative">
          {pathname !== "/hospital_map" && (
            <BtnBack onClick={onBtnBack}>
              <img src="assets/img/hospital_list/icon-left-arrow.svg" alt="" />
            </BtnBack>
          )}
          {pathname !== "/hospital_map" && <BtnInfoTop onClick={onBtnMap}>지도</BtnInfoTop>}
          <BtnInfoTop onClick={familyShowHandler}>
            <img src="assets/img/hospital_map/icon-gc.svg" alt="" />  {summary !== undefined ? summary.DLNG_AFFL_CNT : 0}
          </BtnInfoTop>
          <BtnInfoTop onClick={() => {
            memoShowHandler();
            setMemoPopStatus(false);
          }}>
            <img src="assets/img/hospital_map/icon-pencil.svg" alt="연필아이콘" /> {summary !== undefined ? summary.MEMO_CNT : 0}
          </BtnInfoTop>
          {true && <BtnInfoTop onClick={() => {
            memoShowHandler();
            setMemoPopStatus(true);
          }}>
            <img src="assets/img/hospital_map/icon-calendar.svg" alt="달력아이콘" /> {summary !== undefined ? summary.SCHEDULE_CNT : 0}
          </BtnInfoTop>}
          {false && <BtnInfoTop onClick={csShowHandler}>
            <img src="assets/img/hospital_map/icon-cscenter.svg" alt="고객센터아이콘" />
          </BtnInfoTop>}
        </div>

        <div className="flex justify-between items-center">
          <HospitalName className=" text-black text-lg font-semibold font-['Pretendard'] leading-tight" onClick={() => onDetail()}>
            {hospital?.HOSPITAL_INFO?.RPRT_INST_NM ? hospital.HOSPITAL_INFO.RPRT_INST_NM : "정보없음"}
          </HospitalName>

          {/* 상세페이지 */}
          {/* pathname === "/hospital_soon" && hospital?.HOSPITAL_INFO?.FNDN_YMD_CN && hospital?.HOSPITAL_INFO?.FNDN_YMD_CN !== "정보없음" && (
            <HospitalSoon className="flex justify-center items-center rounded-full">
              {hospital.HOSPITAL_INFO.FNDN_YMD_CN + " 예정"}
            </HospitalSoon>
          ) 개원예정일 삭제 */}
          {/* 지도에서 접근 */}
          {pathname === "/hospital_map" && hospital?.HOSPITAL_INFO?.HOPN_STEP_NM === "채용" && hospital?.HOSPITAL_INFO?.RECN_YMD && (
            <HospitalSoon className="flex justify-center items-center rounded-full">
              {ymd_ago(hospital.HOSPITAL_INFO.RECN_YMD) + " 등록"}
            </HospitalSoon>
          )}
          {pathname === "/hospital_map" && hospital?.HOSPITAL_INFO?.HOPN_STEP_NM === "허가" && hospital?.HOSPITAL_INFO?.LCPMT_YMD && (
            <HospitalSoon className="flex justify-center items-center rounded-full">
              {ymd_ago(hospital.HOSPITAL_INFO.LCPMT_YMD) + " 허가"}
            </HospitalSoon>
          )}
          {pathname === "/hospital_map" && hospital?.HOSPITAL_INFO?.HOPN_STEP_NM === "개원" && hospital?.HOSPITAL_INFO?.HOPN_YMD && (
            <HospitalOpen>
              <span>{ymd_ago(hospital.HOSPITAL_INFO.HOPN_YMD) + " 개원"}</span>
            </HospitalOpen>
          )}
        </div>

        <ul className="my-2">
          {pathname === "/deal" && hospital?.HOSPITAL_INFO?.ESTBL_YMD && (
            <li className="flex items-center gap-1 mb-2">
              <HospitalOpen>
                <p>{ymd_format(hospital.HOSPITAL_INFO.ESTBL_YMD)} 개원</p>
                <span>{ymd_ago(hospital.HOSPITAL_INFO.ESTBL_YMD)}</span>
              </HospitalOpen>
            </li>
          )}
          {pathname === "/non_deal" && hospital?.HOSPITAL_INFO?.ESTBL_YMD && (
            <li className="flex items-center gap-1 mb-2">
              <HospitalOpen>
                <p>{ymd_format(hospital.HOSPITAL_INFO.ESTBL_YMD)} 개원</p>
                <span>{ymd_ago(hospital.HOSPITAL_INFO.ESTBL_YMD)}</span>
              </HospitalOpen>
            </li>
          )}
          <li className="flex items-center gap-1 mb-2">
            <img src="assets/img/hospital_map/icon-map.svg" alt="지도아이콘" />
            <span className="text-black text-xs font-normal font-['Pretendard'] leading-tight">
              {hospital && hospital.HOSPITAL_INFO && hospital.HOSPITAL_INFO.ADDR_CN ? hospital.HOSPITAL_INFO.ADDR_CN : "정보없음"}
            </span>
          </li>
          <li className="flex items-center gap-1 mb-2">
            <img src="assets/img/hospital_map/icon-phone.svg" alt="폰아이콘" />
            <span className="text-black text-xs font-normal font-['Pretendard'] leading-tight">
              {hospital && hospital.HOSPITAL_INFO && hospital.HOSPITAL_INFO.TEL_NO_CN ? hospital.HOSPITAL_INFO.TEL_NO_CN : "정보없음"}
            </span>
          </li>
        </ul>
      </HospitalInfoInner>

      {/* 팝업 */}
      {openFamilyModal && <FamilyModal familyHideHandler={familyHideHandler} hospital={hospital} />}
      {openMemoModal && <MemoModal memoHideHandler={memoHideHandler} hospital={hospital} memoPopStatus={memoPopStatus} />}
      {openCsModal && <CsModal csHideHandler={csHideHandler} />}
    </>
  );
};

const HospitalInfoWrap = styled.div`
  position: fixed;
  z-index: 200;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px -4px 7px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 1rem 0;
`;

const ContentBox = styled.div`
  max-height: 350px;
  
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

export const HospitalInfoInner = styled.div``;

const Bar = styled.div`
  width: 134px;
  height: 5px;
  margin: 0 auto 5px;
`;

const BtnInfoTop = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #dadada;
  background: #fff;
  color: #072c49;
  font-size: 14px;
  padding: 3px;
  gap: 0 2px;
  img {
    width: 20px;
  }
`;

export const HospitalName = styled.div``;
export const HospitalSoon = styled.div`
  width: 94px;
  min-width: fit-content;
  text-wrap: nowrap;
  height: 28px;
  border: 1px solid #00ade6;
  background: #f0fbff;
  color: #00ade6;
  font-size: 13px;
`;

export const HospitalOpen = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  p {
    font-size: 13px;
    color: #072c49;
  }
  span {
    padding: 2px 5px;
    height: 20px;
    background: #072c49;
    color: #fff;
    font-size: 12px;
    border-radius: 9px;
  }
`;

const HospitalInfoItem = styled.div`
  border-bottom: 1px solid #e4e4e4;
  padding: 1rem 0;
`;
