import React from "react";

import PageTop from "../common/PageTop";
import AiChatContent from "./components/AiChatContent";
import AiChatBottom from "./components/AiChatBottom";

function AiChat() {
  return (
    <div>
      <PageTop title={"Ai검사편람"} style={{ zIndex: "1" }} />
      <AiChatContent />
      <AiChatBottom />
    </div>
  );
}

export default AiChat;
