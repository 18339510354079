import "./index.css";

import * as React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider, Route, Link, } from "react-router-dom";
import { NavermapsProvider } from 'react-naver-maps';

import Login from "./login/Login";
import Logout from "./login/Logout";
import HospitalMap from "./hospital_map/HospitalMap";
import HospitalList1 from "./hospital_list/HospitalList1";
import HospitalList2 from "./hospital_list/HospitalList2";
import HospitalList3 from "./hospital_list/HospitalList3";
import HospitalSoon from "./hospital_soon/HospitalSoon";
import NonDeal from "./non_deal/NonDeal";
import Deal from "./deal/Deal";

import Available from "./available/Available";
import AvailableDetail1 from "./available_detail/AvailableDetail1";
import AvailableDetail2 from "./available_detail/AvailableDetail2";

import Realtor from "./realtor/Realtor";
import AiChat from "./ai_chat/AiChat";
import Mypage from "./mypage/Mypage";

import { AuthCheck } from "./login/Token";

import Ga4 from "./common/Ga4";

const routerData = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "logout",
    element: <Logout />,
  },
  {
    path: "hospital_map",
    element: <NavermapsProvider ncpClientId="uqm5q11fbg"><HospitalMap /></NavermapsProvider>,
  },
  {
    path: "hospital_list1",
    element: <HospitalList1 />,
  },
  {
    path: "hospital_list2",
    element: <HospitalList2 />,
  },
  {
    path: "hospital_list3",
    element: <HospitalList3 />,
  },
  {
    path: "hospital_soon",
    element: <HospitalSoon />,
  },
  {
    path: "non_deal",
    element: <NonDeal />,
  },
  {
    path: "deal",
    element: <Deal />,
  },
  {
    path: "available",
    element: <NavermapsProvider ncpClientId="uqm5q11fbg"><Available /></NavermapsProvider>,
  },
  {
    path: "apt_detail",
    element: <AvailableDetail1 />,
  },
  {
    path: "shop_detail",
    element: <AvailableDetail2 />,
  },
  {
    path: "realtor",
    element: <Realtor />,
  },
  {
    path: "ai_chat",
    element: <AiChat />,
  },
  {
  	path: "mypage",
  	element: <Mypage />,
  },
];

const router = createBrowserRouter(
  routerData.map((router) => {
    return {
      path: router.path,
      element: <><Ga4/><AuthCheck>{router.element}</AuthCheck></>,
    };
  })
);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
