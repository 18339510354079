import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const tableSoon = [
  {
    th: "의료기관",
    td: "AGENCY_NM",
  },
  {
    th: "의료인수",
    td: "TOTL_DR_CNT",
  },
  {
    th: "입원실",
    td: "HOSR_YN",
  },
  {
    th: "병상수",
    td: "TOTL_SCKBD_CNT",
  },
  {
    th: "총면적",
    td: "RPRT_INST_GFA",
  },
];

const tableData = [
  {
    th: "의료기관",
    td: "AGENCY_NM",
  },
  {
    th: "총의사수",
    td: "TOTL_DR_CNT",
  },
  {
    th: "총전문의수",
    td: "MED_SPLT_CNT",
  },
  {
    th: "총병상수",
    td: "TOTL_SCKBD_CNT",
  },
  {
    th: "입원실",
    td: "HOSR_YN",
  },
];

const CustomTable = ({ data }) => {
  const location = useLocation();
  if(data && (data.AGENCY_NM || data.TOTL_DR_CNT || data.HOSR_YN || data.TOTL_SCKBD_CNT || data.RPRT_INST_GFA)) {
    return (
      <CustomTableWrap className="overflow-x-auto">
        <table className="gray-th-table">
          <thead>
            <tr>
              {location?.pathname === "/hospital_soon" && tableSoon.map((item) => (
                <th key={item.th}>{item.th}</th>
              ))}
              {location?.pathname !== "/hospital_soon" && tableData.map((item) => (
                <th key={item.th}>{item.th}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {tableData.map((item) => (
                <td key={item.td}>{data ? data[item.td] : ""}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </CustomTableWrap>
    );
  } else {
    return null;
  }
};

export const CustomTableWrap = styled.div`
  table.gray-th-table {
    width: 100%;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    border-top: 1px solid #bbb;
    thead tr th {
      height: 27px;
      background: #f5f5f5;
      border-bottom: 1px solid #bbb;
      vertical-align: middle;
      border-left: 1px solid #bbb;
      &:first-child {
        border-left: none;
      }
    }
    tbody tr td {
      height: 27px;
      text-align: center;
      border-bottom: 1px solid #bbb;
      vertical-align: middle;
      border-left: 1px solid #bbb;
      &:first-child {
        border-left: none;
      }
    }
  }
`;
export default CustomTable;
