import React, { useRef } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { ymd_ago } from "../../common/PageTop";
import { getFilterData } from "../../hospital_map/Filter";
import { HospitalOpen } from "../../hospital_map/components/HospitalInfo";
function DetailItem({item}) {
  const location = useLocation();
  const navigate = useNavigate();
  const leftScroll = useRef();
  const department_code = getFilterData("label").department;
  const onDetail = () => {
    let url = "";
    if(item.HOSPITAL_FLAG === 'A') url = "/deal";
    else if(item.HOSPITAL_FLAG.substring(0,1) === 'B') url = "/non_deal";
    navigate(url, {
      state: {
        COMB_KEY: item.COMB_KEY,
        RFRNC_TBL_NM: item.RFRNC_TBL_NM,
        DONG_CD: item.DONG_CD,
      }
    });
  }
  
  if(location.state?.COMB_KEY === item.COMB_KEY) leftScroll.current?.scrollIntoView();

  return (
    <DetailItemInner onClick={() => onDetail()} ref={leftScroll}>
      <div className="flex justify-between items-center mb-2">
        <div className="text-black text-base font-semibold leading-tight">
          {item?.RPRT_INST_NM}
        </div>
        {item?.HOPN_YMD && ymd_ago(item.HOPN_YMD) && (
          <HospitalOpen>
            <span className="whitespace-nowrap">{ymd_ago(item.HOPN_YMD)} 개원</span>
          </HospitalOpen>
        )}
      </div>
      <div className="flex items-center max-w-full min-w-0">
        <span className="text-black text-xs leading-tight overflow-hidden whitespace-nowrap text-ellipsis">
          {item?.DEPARTMENT_LIST?.map((item, index) => {
            if(index !== 0) return " | " + department_code?.filter((filter) => filter.code === item)[0]?.name;
            else return department_code?.filter((filter) => filter.code === item)[0]?.name;
          })}
        </span>
      </div>
    </DetailItemInner>
  );
}

const DetailItemInner = styled.div`
  background: #fff;
  padding: 0.57rem 1rem;
  border-bottom: 1px solid #e4e4e4;
`;

export default DetailItem;
