import React from "react";
import styled from "styled-components";

import AiBot from "./AiBot";
import MeAnswer from "./MeAnswer";
import AiAnswer from "./AiAnswer";
import AiAnswerSlick from "./AiAnswerSilck";

function AiChatContent() {
  return (
    <AiChatContentWrap>
      <AiBotSubject className="flex items-center gap-2 mb-4">
        <img src="/assets/img/ai_chat/icon-robot-chat.svg" alt="" />
        <BotSubject className="rounded-3xl p-2">
          검사에 대해 안내해드립니다.
        </BotSubject>
      </AiBotSubject>
      <AiBot>
        <AiAnswer />
      </AiBot>
      <MeAnswer text={"수가변동검사 ~"} />
      <AiBot>
        <AiAnswerSlick />
      </AiBot>
      <MeAnswer
        text={`N971 모발 중금속 및 미네랄 40종 검사 (element analysis) [Hair]`}
      />
      <AiBot>
        <AiAnswer />
      </AiBot>
    </AiChatContentWrap>
  );
}

const AiChatContentWrap = styled.div`
  background: #f8f8f8;
  width: 100vw;
  height: calc(100vh - 55px);
  margin-top: 55px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

const AiBotSubject = styled.div``;

const BotSubject = styled.p`
  color: #00ade6;
  background: #fff;
  font-size: 13px;
`;

export default AiChatContent;
