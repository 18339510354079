import React, { useEffect, useRef } from "react";
import { HospitalName, HospitalSoon } from "../../hospital_map/components/HospitalInfo";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { ymd_ago } from "../../common/PageTop";

export default function HospitalCard({item}) {
  const location = useLocation();
  const navigate = useNavigate();
  const leftScroll = useRef();
  const onDetail = () => {
    navigate("/hospital_soon", {
      state: {
        COMB_KEY: item.COMB_KEY,
        RFRNC_TBL_NM: item.RFRNC_TBL_NM,
        DONG_CD: item.DONG_CD,
      }
    });
  }
  useEffect(() => {
    if(location.state?.COMB_KEY === item?.COMB_KEY) {
      leftScroll.current?.scrollIntoView();
    }
  });

  return (
    <CardCustom onClick={() => onDetail()} ref={leftScroll}>
      <div className="flex justify-between items-center">
        <HospitalName className=" text-black text-lg font-semibold leading-tight">
          {item.RPRT_INST_NM}
        </HospitalName>
        {item?.HOPN_STEP_NM === "채용" && item?.RECN_YMD && ymd_ago(item.RECN_YMD) && (
          <HospitalSoon className="flex justify-center items-center rounded-full">
            {ymd_ago(item.RECN_YMD) + " 등록"}
          </HospitalSoon>
        )}
        {item?.HOPN_STEP_NM === "허가" && item?.LCPMT_YMD && ymd_ago(item.LCPMT_YMD) && (
          <HospitalSoon className="flex justify-center items-center rounded-full">
            {ymd_ago(item.LCPMT_YMD) + " 허가"}
          </HospitalSoon>
        )}
      </div>

      <ul className="mt-2">
        <li className="flex items-center gap-1 mb-2">
          <img src="assets/img/hospital_map/icon-map.svg" alt="지도아이콘" />
          <span className="text-black text-xs font-normal leading-tight">
            {item.ADDR_CN}
          </span>
        </li>
        <li className="flex items-center gap-1">
          <img src="assets/img/hospital_map/icon-phone.svg" alt="폰아이콘" />
          <span className="text-black text-xs font-normal leading-tight">
            {item.TEL_NO_CN}
          </span>
        </li>
      </ul>
    </CardCustom>
  );
}

const CardCustom = styled.div`
  border-radius: 10px;
  background: #fff;
  box-shadow: 3px 3px 10px 0px #d8e5e9;
  padding: 0.8rem;
  margin-bottom: 0.5rem;
`;
