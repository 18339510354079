import { renderToStaticMarkup } from "react-dom/server"
import { useNavermaps, useMap } from "react-naver-maps";
import MapIcon from "./MapIcon";
import { makeMarkerClustering } from "./marker-cluster";
import MapArea from "./MapArea";
import { useEffect, useState } from "react";

let blueMarkers = [];
let greenMarkers = [];
let whiteMarkers = [];

let clusters = [];
let selectItem;

export default function Makers({mapData, bounds, clickMarkerHandler, selector, setInfoData, setInfoVisible}) {

    const navermaps = useNavermaps();
    const map = useMap();
    const MarkerClustering = makeMarkerClustering(navermaps);

    function htmlIcon(item, selector) {
        let color = "", width = 25, height = 31, badgeSize = 17, badgeLeft = 15, badge = false;
        switch(item.HOSPITAL_FLAG) {
            case "N": color = "blue"; break;
            case "A": color = "green"; break;
            case "B": color = "green-border"; break;
            case "B-1": color = "green-border"; badge = true; break;
        }
        if(item.COMB_KEY === selector) {
            width = 40;
            height = 50;
            badgeSize = 27;
            badgeLeft = 25;
        }
        const content = renderToStaticMarkup(<MapIcon color={color} size={width} badge={badge} badgeSize={badgeSize} badgeLeft={badgeLeft} />);
        return {
            content,
            size: new navermaps.Size(width, height),
        };
    }

    blueMarkers.map((item) => item.setMap(null));
    blueMarkers = [];
    greenMarkers.map((item) => item.setMap(null));
    greenMarkers = [];
    whiteMarkers.map((item) => item.setMap(null));
    whiteMarkers = [];

    useEffect(() => {
        setInfoData([selectItem]);
        setInfoVisible(true);
    }, [selectItem]);

    mapData.map(item => {
        const latLng = new navermaps.LatLng(item.LNL_YCO, item.LNL_XCO);
            const zIndex = item.COMB_KEY === selector ? 10 : 5;
            if(zIndex === 10) selectItem = item;
            const marker = new navermaps.Marker({
                position: new navermaps.LatLng(item.LNL_YCO, item.LNL_XCO),
                title: item.RPRT_INST_NM,
                zIndex: zIndex,
                icon: htmlIcon(item, selector),
            });
            marker.item = item;
            navermaps.Event.addListener(marker, "click", () => {
                clickMarkerHandler([marker], marker);
            });
            switch(item.HOSPITAL_FLAG) {
                case "N": blueMarkers.push(marker); break;
                case "A": greenMarkers.push(marker); break;
                case "B": case "B-1": whiteMarkers.push(marker); break;
            }
    });


    const clusterType = ["green", "green-border", "blue"];
    const clusterGen = [10, 100, 200, 500, 1000];

    function clusterIcon(color) {
        const icons = [];
        const clusterSize = [40, 55, 70, 85, 100];
        clusterSize.map((item) => {
            const content = renderToStaticMarkup(<MapArea color={color} size={item} />);
            const icon = {
                content,
                size: new navermaps.Size(item, item),
                anchor: new navermaps.Point(item/2, item/2),
            }
            icons.push(icon);
        })
        return icons;
    }

    clusters.map((item) => item.setMap(null));
    clusters = [];

    clusterType.map((item) => {
        let marker;
        switch(item) {
            case "blue": marker = blueMarkers; break;
            case "green": marker = greenMarkers; break;
            case "green-border": marker = whiteMarkers; break;
        }
        const cluster = new MarkerClustering({
            minClusterSize: 2,
            maxZoom: 16,
            map: map,
            markers: marker,
            disableClickZoom: true,
            gridSize: 150,
            icons: clusterIcon(item),
            indexGenerator: clusterGen,
            stylingFunction: function (clusterMarker, count, member) {
                clusterMarker.getElement().querySelector('div:first-child').innerText = count;
                if(clusterMarker.position.equals(selector?.position)) {
                    clusterMarker.eventTarget.classList.add("actCluster");
                }
                navermaps.Event.addListener(clusterMarker, "click", () => {
                    clickMarkerHandler(member, clusterMarker);
                });
            },
        });
        clusters.push(cluster);
    });

    return null;
}
