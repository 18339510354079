import React, { useEffect, useState } from "react";
import styled from "styled-components";

import RealtorCard from "./RealtorCard";
import BuildingList from "./BuildingList";
import { useLocation } from "react-router-dom";
import gcss_api from "../../api/api";
import { getToken } from "../../login/Token";
import axios from "axios";

function RealtorContent({status}) {
  const location = useLocation();
  const detailKey = { X:location.state.center.x, Y:location.state.center.y, RADIUS: status, UNIT: "m" };
  const [detailData, setDetailData] = useState({});
  useEffect(() => {
    const token = getToken();
    const option = {
      method: 'POST',
      headers: {'Authorization': token, 'Content-Type': 'application/json'},
      url: gcss_api("api_potential_real-estate"),
      data: JSON.stringify(detailKey),
    };
    axios(option).then((res) => {
      setDetailData(res.data);
    }).catch((err) => {
      if(err?.response?.status === 401) window.location.href = "/logout";
      console.log(err);
    });
  }, [status]);
  return (
    <RealtorContentWrap>
      <BuildingList item={detailData}/>
      {detailData && detailData.REAL_ESTATE_AGENCY_INFO && detailData.REAL_ESTATE_AGENCY_INFO.map((item) => (<RealtorCard key={item.CREO_REG_NO} item={item}/>))}
    </RealtorContentWrap>
  );
}

const RealtorContentWrap = styled.div`
  max-width: 768px;
  margin: 0 auto;
  background: #eff2f3;
  padding: 0.5rem 1rem 1rem;
`;

export default RealtorContent;
