import React from "react";
import styled from "styled-components";

import Time from "./Time";

function AiBot({ children }) {
  return (
    <AiBotWrap>
      <WhiteBubble>
        <p className="bot-text">
          안녕하세요? <br />
          AI검색편람 챗봇입니다.
          <br />
          궁금한 사항 문의해 주세요.
        </p>
        <Time left={null} right={"-60px"} />
      </WhiteBubble>
      <div>{children}</div>
    </AiBotWrap>
  );
}

const AiBotWrap = styled.div`
  margin-right: auto;
`;

const WhiteBubble = styled.div`
  display: inline-block;
  padding: 11px 1rem;
  background: #fff;
  border-radius: 15px;
  border-top-left-radius: 0;
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.1));
  position: relative;
  .bot-text {
    color: #000;
    font-size: 13px;
  }
`;

export default AiBot;
