import React, { useEffect, useState } from "react";
import DealTop from "./components/Dealtop";
import { ContentBorderTop } from "../hospital_soon/HospitalSoon";
import HospitalInfoCard from "../hospital_soon/components/HospitalInfoCard";
import { useLocation } from "react-router-dom";
import { getToken } from "../login/Token";
import gcss_api from "../api/api";
import axios from "axios";

function Deal() {
  const location = useLocation();
  const detailKey = { ...location.state };
  const [detailData, setDetailData] = useState(Array());
  useEffect(() => {
    const token = getToken();
    const option = {
      method: 'POST',
      headers: {'Authorization': token, 'Content-Type': 'application/json'},
      url: gcss_api("api_hospital_detail_account"),
      data: JSON.stringify(detailKey),
    }
    axios(option).then((res) => {
      setDetailData(res.data);
    }).catch((err) => {
      if(err?.response?.status === 401) window.location.href = "/logout";
      console.log(err);
    });
  }, []);
  return (
    <div>
      <DealTop infoData={detailData} />
      <ContentBorderTop className="py-2">
        <HospitalInfoCard infoData={detailData} />
      </ContentBorderTop>
    </div>
  );
}

export default Deal;
