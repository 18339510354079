import React from "react";
import styled from "styled-components";

function Time({ left, right }) {
  return <TimeText style={{ left: left, right: right }}>오후 2:04</TimeText>;
}

const TimeText = styled.p`
  color: #6c6c6c;
  font-size: 12px;
  position: absolute;
  bottom: 0;
`;
export default Time;
