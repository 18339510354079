import React from "react";
import styled from "styled-components";

function RealtorCard({item}) {
  return (
    <RealtorCardWrap>
      <div className=" text-black text-lg font-semibold">
        {item.CREO_NM}
      </div>

      <ul className="mt-2">
        <li className="flex items-center gap-1 mb-2">
          <img src="assets/img/hospital_map/icon-map.svg" alt="지도아이콘" />
          <span className="text-black text-xs font-normal">
            {item.ADDR_CN}
          </span>
        </li>
        <li className="flex items-center gap-1">
          <img src="assets/img/hospital_map/icon-phone.svg" alt="폰아이콘" />
          <span className="text-black text-xs font-normal">{item.TEL_NO_CN}</span>
        </li>
      </ul>
    </RealtorCardWrap>
  );
}

const RealtorCardWrap = styled.div`
  border-radius: 10px;
  background: #fff;
  box-shadow: 3px 3px 10px 0px #d8e5e9;
  padding: 0.8rem;
  margin-bottom: 0.5rem;
`;
export default RealtorCard;
