import { useState } from "react";
import styled from "styled-components";

function Loginform({ onSignIn, userNameHandler, passWordHandler }) {
  const onEnterChk = (e) => {
    if(e.key === "Enter") onSignIn();
  };
  return (
    <div>
      <Formgroup>
        <label htmlFor="">
          <img src="assets/img/login/icon-email.svg" alt="이메일 아이콘" />
          <input type="text" placeholder="ID" onChange={userNameHandler} required />
        </label>
      </Formgroup>
      <Formgroup>
        <label htmlFor="">
          <img src="assets/img/login/icon-pw.svg" alt="비밀번호 아이콘" />
          <input type="password" placeholder="PASSWORD" onChange={passWordHandler} onKeyDown={onEnterChk} required />
        </label>
      </Formgroup>
      <BtnLogin type="button" onClick={onSignIn}>
        <img src="assets/img/login/icon-lock.svg" alt="" />
        로그인
      </BtnLogin>
    </div>
  );
}

export default Loginform;

const Formgroup = styled.div`
  margin-bottom: 15px;
  label {
    position: relative;
    img {
      width: 20px;
      position: absolute;
      top: 0;
      left: 20px;
    }
  }
  input {
    width: 100%;
    height: 50px;
    background: #fff;
    color: #676767;
    border-radius: 5px;
    padding-left: 46px;
    font-size: 14px;
  }
`;

const BtnLogin = styled.button`
  margin-top: 28px;
  width: 100%;
  height: 60px;
  background: #00ade7;
  color: #fff;
  border-radius: 5px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  img {
    width: 20px;
    margin-right: 10px;
  }
`;
