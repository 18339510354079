import React from "react";
import { Accordion } from "flowbite-react";
import styled from "styled-components";
import { getFilter } from "../../hospital_map/Filter";

function NeedDoctor({item}) {
  const department = getFilter()["department"];
  return (
    <NeedDoctorAccordion>
      <Accordion id="need-doctor">
        <Accordion.Panel>
          <Accordion.Title>입주 규모에 따른 예상 필요 의사수</Accordion.Title>
          <Accordion.Content>
            <p className="text-black text-xs">
              ({item.SUB_EXPLAIN})
            </p>
            <table className="gray-th-table mt-4">
              <thead>
                <tr>
                  <th>진료과</th>
                  <th>의사수</th>
                </tr>
              </thead>
              <tbody>
                {item?.REQUIRE_DR_COUNT_LIST.map((dr) => (
                  <tr key={dr?.TRT_SBJCT_CD}>
                    <td>{department.filter((filter) => filter?.code === dr?.TRT_SBJCT_CD)[0]?.name}</td>
                    <td>{dr?.REQUIRE_DOCTOR_NUMBER}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </NeedDoctorAccordion>
  );
}

const NeedDoctorAccordion = styled.div`
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  padding: 1rem;
  margin-bottom: 1rem;
  #need-doctor {
    > button {
      padding: 0;
      padding-bottom: 0.5rem;
      background: none;
      &:focus {
        border: none;
        --tw-ring-opacity: 0;
        --tw-ring-color: rgb(255, 255, 255 / var(--tw-ring-opacity));
      }
    }
    > div {
      padding: 0;
    }
  }
`;

export default NeedDoctor;
