import { db } from "../db";

// 테스트에 필요해서 만든 로그아웃
function Logout() {
  db.delete();
  localStorage.clear();
  sessionStorage.clear();
  window.location.href="/";
}

export default Logout;