import React, { useEffect, useState } from "react";
import PageTop from "../common/PageTop";
import HospitalCardContainer from "./components/HospitalCardContainer";
import { getFilterData } from "../hospital_map/Filter";
import styled from "styled-components";

function HospitalList1() {
  const [filter, setFilter] = useState(Array());
  const mapChange = () => {
    setFilter(getFilterData("department"));
  };
  useEffect(() => {
    setFilter(getFilterData("department"));
  }, []);
  return (
    <HospitalList>
      <PageTop title="개원 예정 병원" />
      <HospitalCardContainer filter={filter}/>
    </HospitalList>
  );
}

export default HospitalList1;

const HospitalList = styled.div`
  height: 100vh;
  overflow: hidden;
`;
