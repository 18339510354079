import React from "react";
import { Tabs } from "flowbite-react";

import styled from "styled-components";
import ScheduleItem from "./ScheduleItem";
import MemoList from "./MemoList";

function MypageTab() {
  return (
    <MypageTabWrap className="mx-4 bg-white rounded">
      <Tabs>
        <Tabs.Item active title={`일정(예정1)`}>
          <ScheduleItem />
        </Tabs.Item>
        <Tabs.Item title={`메모(333)`}>
          <MemoList />
        </Tabs.Item>
      </Tabs>
    </MypageTabWrap>
  );
}

const MypageTabWrap = styled.div`
  div[role="tabpanel"] {
    padding: 0;
    padding-bottom: 1rem;
  }
  div[role="tablist"] {
    width: 100%;
    button {
      width: 50%;
      color: #9c9c9c;
      border-bottom: 3px solid #d0d0d0;
      padding: 0.5rem 0;
      font-size: 16px;
      font-weight: bold;
      background: #fff;
      &:focus {
        --tw-ring-opacity: 0;
        --tw-ring-color: rgb(255, 255, 255 / var(--tw-ring-opacity));
      }
      &[aria-selected="true"] {
        color: #002b49;
        border-bottom: 3px solid #002b49;
      }
    }
  }
`;

export default MypageTab;
