import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const FloatingBtns = ({map}) => {
  const location = useLocation();
  const pathname = location?.pathname;
  return (
    <FloatingBtnsWrap>
      <ClientBtns className="flex flex-col">
        {/* TODO: .red 를 가질때 빨간 점 표시 className="red" */}
        <BtnOpenSoon>
          <Link to={"/hospital_list1"} state={{from: location, center: map ? map.getCenter() : null}}>
            개원<br />예정
          </Link>
        </BtnOpenSoon>
        <BtnDealings>
          <Link to={"/hospital_list2"} state={{from: location, center: map ? map.getCenter() : null}}>거래</Link>
        </BtnDealings>
        <BtnNonDealings>
          <Link to={"/hospital_list3"} state={{from: location, center: map ? map.getCenter() : null}}>비거래</Link>
        </BtnNonDealings>
      </ClientBtns>
      <BtnLocation className={pathname === "/available" ? "active" : ""}>
        {pathname === "/available" ? (
          <Link to={"/hospital_map"} state={{from: location, center: map ? map.getCenter() : null}}>개원<br />가능지역</Link>
        ) : (
          <Link to={"/available"} state={{from: location, center: map ? map.getCenter() : null}}>개원<br />가능지역</Link>
        )}
      </BtnLocation>
      {/* <BtnLocation>진료권<br />분석</BtnLocation> // 1차에서는 사용안함 주석처리 */}
      <BtnLocation>
        {map ? (
          <Link to={"/Realtor"} state={{from: location, center: map ? map.getCenter() : null}}>공인<br />중개사</Link>
        ) : (
          <>공인<br />중개사</>
        )}
      </BtnLocation>
    </FloatingBtnsWrap>
  );
};

const FloatingBtnsWrap = styled.div`
  position: fixed;
  top: 140px;
  left: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
`;

const ClientBtns = styled.div``;

const BtnLocation = styled.button`
  width: 46px;
  background: #fff;
  color: #002b4b;
  border: 1px solid #002b4b;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  padding: 3px 4px;
  letter-spacing: -1px;
  font-weight: 500;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.28));
  &.active {
    background: #002b4b;
    color: #fff;
  }
`;

const BtnOpenSoon = styled.button`
  height: 40px;
  color: #fff;
  background: #00ade6;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  &.red:after {
    content: "●";
    color: red;
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 8px;
  }
`;

const BtnDealings = styled.button`
  height: 40px;
  color: #fff;
  background: #007a33;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  &.red:after {
    content: "●";
    color: red;
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 8px;
  }
`;

const BtnNonDealings = styled.button`
  height: 40px;
  background: #fff;
  border: 1px solid #007a33;
  color: #007a33;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.28));
  &.red:after {
    content: "●";
    color: red;
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 8px;
  }
`;

export default FloatingBtns;
