import React from "react";
import styled from "styled-components";

//라운드 날짜 Label
const DayBadge = ({ title, isActive }) => {
  return (
    <DayBadgeWrap className={isActive ? "active" : ""}>{title}</DayBadgeWrap>
  );
};

const DayBadgeWrap = styled.span`
  height: 20px;
  padding: 0px 7px;
  line-height: 20px;
  color: #fff;
  background: #a4a4a4;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
  &.active {
    background: #007a33;
  }
`;

export default DayBadge;
