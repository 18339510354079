import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  LabelList,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

const renderCustomizedLabel = (props) => {
  const { x, y, width, value } = props;

  return (
    <g>
      <text
        x={x + width / 2}
        y={y - 2}
        fill="#000"
        textAnchor="middle"
        fontSize={12}
        fontWeight={600}
      >
        {value ? value + "배" : ""}
      </text>
    </g>
  );
};

export default function SampleGraph2({areaInfo}) {
  const data = [
    {
      name: "0~14세",
      age: (areaInfo ? areaInfo.AGGR_FIST_PPLTN_RLTV_RT : 0),
      multiple: (areaInfo ? areaInfo.AGGR_FIST_PPLTN_RLTV_RT : ""),
    },
    {
      name: "15~24세",
      age: (areaInfo ? areaInfo.AGGR_SEST_PPLTN_RLTV_RT : 0),
      multiple: (areaInfo ? areaInfo.AGGR_SEST_PPLTN_RLTV_RT : ""),
    },
    {
      name: "25~44세",
      age: (areaInfo ? areaInfo.AGGR_THST_PPLTN_RLTV_RT : 0),
      multiple: (areaInfo ? areaInfo.AGGR_THST_PPLTN_RLTV_RT : ""),
    },
    {
      name: "45~64세",
      age: (areaInfo ? areaInfo.AGGR_FOST_PPLTN_RLTV_RT : 0),
      multiple: (areaInfo ? areaInfo.AGGR_FOST_PPLTN_RLTV_RT : ""),
    },
    {
      name: "65세",
      age: (areaInfo ? areaInfo.AGGR_FFST_PPLTN_RLTV_RT : 0),
      multiple: (areaInfo ? areaInfo.AGGR_FFST_PPLTN_RLTV_RT : ""),
    },
  ];
  return (
    <div style={{ width: "100%", height: 150 }}>
      <ResponsiveContainer height={150}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 15,
            right: 10,
            left: 10,
            bottom: 5,
          }}
        >
          <Bar dataKey="age" fill="#D0E4EA" barSize={30} radius={[8,8,0,0]}>
            <LabelList dataKey="multiple" content={renderCustomizedLabel} />
          </Bar>
          <ReferenceLine y={1} strokeDasharray={2} strokeWidth={1.5} stroke="#FF0000"/>
          <XAxis dataKey="name" fontSize={12} stroke="#929292" fontWeight={600} tickLine={false} tick={{fill: "#000000"}} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
