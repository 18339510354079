import React, { useState } from "react";
import PageTop from "../common/PageTop";
import RealtorContent from "./components/RealtorContent";
import DistanceProgress from "./components/DistanceProgress";

function Realtor() {
  const [status, setStatus] = useState(200);
  return (
    <div>
      <PageTop title={"공인중개사"} />
      <DistanceProgress status={status} setStatus={setStatus}/>
      <RealtorContent status={status} />
    </div>
  );
}

export default Realtor;
