import { RotatingLines } from 'react-loader-spinner';
import styled from 'styled-components';

const Loading = ({isVisible}) => {
    return (
        <LoadingWrap $isVisible={isVisible} className='gap-y-2'>
            <RotatingLines visible={isVisible} strokeColor='#072c49'/>
            {isVisible && <span className='font-bold text-sky-950'>정보를 생성중입니다.</span>}
        </LoadingWrap>
    );
};

const LoadingWrap = styled.div`
    width: 100%;
    height: 100%;
    position: ${({$isVisible}) => $isVisible ? "fixed" : "none"};
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    background-color: #ffffffa0;
    z-index: 100;
`;

export default Loading;