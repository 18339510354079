import { useState } from "react";
import styled from "styled-components";
import Loginform from "./components/Loginform";
import AgreeModal from "./components/AgreeModal";
import axios from "axios";
import qs from 'qs';
import gcss_api from "../api/api";
import { setToken } from "./Token";
import { setUser } from "./User";

function Login() {
  const [showModal, setShowModal] = useState(false);
  const [username, setUserName] = useState("");
  const [password, setPassWord] = useState("");
  const [modalData, setModalData] = useState(Array);
  const [authData, setAuthData] = useState(Array);
  
  const offWindowHandler = () => {
    window.location.href="/";
  };
  const onModalHandler = () => {
    setShowModal(true);
  };
  const offModalHandler = () => {
    setShowModal(false);
  };
  const userNameHandler = (e) => {
    setUserName(e.target.value);
  };
  const passWordHandler = (e) => {
    setPassWord(e.target.value);
  };
  const modalDataHandler = (data) => {
    setModalData(data);
    onModalHandler();
  };
  const authHandler = (data) => {
    setAuthData(data);
  };
  const setAgree = () => {
    let options = {
      method: 'GET',
      headers: {'Authorization': authData.token},
      url: gcss_api("api_agree_confirm"),
    }
    axios(options);
  };

  const onSignIn = () => {
    const data = {'username': username, 'password': password};
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      data: qs.stringify(data),
      url: gcss_api("api_login"),
    }
    axios(options).then((res) => {
      const token = res.data.token_type + " " + res.data.access_token;
      const user = res.data.user_name;
      authHandler({token:token, user:user});
      let modalOption = {
        method: 'GET',
        headers: {'Authorization': token},
        url: gcss_api("api_agree_check"),
      }
      axios(modalOption).then((res) => {
        if(res.data.status) {
          setToken(token);
          setUser(user);
          window.location.href="/";
        } else {
          modalOption.url = gcss_api("api_agree_content");
          axios(modalOption).then((res) => {
            modalDataHandler(res.data);
          });
        }
      });
    }).catch((err) => {
      if(err.response) {
        if(Array.isArray(err.response.data.detail)) {
          alert(err.response.data.detail[0].loc[1] + " " + err.response.data.detail[0].msg);
        } else alert(err.response.data.detail);
      } else {
        console.log(err);
        alert("Error");
      }
    });
  };
  
  return (
    <PageBack>
    <LoginWrap>
      <div className="flex justify-end">
        <img src="assets/gc-logo.svg" alt="logo" />
      </div>

      <ContentImg>
        <img
          src="assets/img/login/login-visual.svg"
          alt="지능형 영업정보 서비스 잇"
        />
      </ContentImg>
      <Loginform onSignIn={onSignIn} userNameHandler={userNameHandler} passWordHandler={passWordHandler}/>
      <AgreeModal showModal={showModal} onModalHandler={onModalHandler} offModalHandler={offModalHandler} offWindowHandler={offWindowHandler} modalData={modalData} authData={authData} setAgree={setAgree} />
      <Des>
        지능형영업정보 서비스는 <br />
        <span>회사 메일주소</span>와 <span>사전에 부여된 비밀번호</span>로
        <br />
        로그인하여 사용하실 수 있습니다.
      </Des>
    </LoginWrap>
    </PageBack>
  );
}

export default Login;

const PageBack = styled.div`
  width: 100%;
  background: #002b49;
`;

const LoginWrap = styled.div`
  max-width: 768px;
  margin: 0 auto;
  background: #002b49;
  padding: 30px;
  height: 100vh;
  width: 100vw;
`;

const ContentImg = styled.div`
  margin: 4rem 0;
`;

const Des = styled.p`
  text-align: center;
  color: #fff;
  font-size: 15px;
  margin: 22px 0;
  line-height: 1.2;
  span {
    color: #00ade7;
    text-decoration: underline;
  }
`;
