import { HospitalFilterWrap } from "../../hospital_map/components/HospitalFilter";
import { useEffect, useState } from "react";

const AvailableFilter = ({setFilter}) => {
  const [tab1_select, setTab1] = useState(true);
  const [tab2_select, setTab2] = useState(true);

  const toggle = (item) => {
    switch(item) {
      case "tab1": 
        setTab1(prev => {
          if(prev && !tab2_select) alert("둘중 하나는 선택되어 있어야 합니다.");
          else {
            sessionStorage.setItem("available_filter", JSON.stringify({"tab1": !prev, "tab2": tab2_select}));
            return !prev;
          }
          return prev;
        });
        break;
      case "tab2":
        setTab2(prev => {
          if(prev && !tab1_select) alert("둘중 하나는 선택되어 있어야 합니다.");
          else {
            sessionStorage.setItem("available_filter", JSON.stringify({"tab1": tab1_select, "tab2": !prev}));
            return !prev;
          }
          return prev;
        });
        break;
    }
  };

  useEffect(() => {
    const available_filter = JSON.parse(sessionStorage.getItem("available_filter"));
    if(available_filter?.tab1 && available_filter?.tab2) setFilter("ALL");
    else if(available_filter?.tab1) {
      setFilter("NEW_APT_INFO_RES");
      if(tab2_select) setTab2(false);
    } else if(available_filter?.tab2) {
      setFilter("NEW_SHOP_INFO_RES");
      if(tab1_select) setTab1(false);
    }
  }, [tab1_select, tab2_select]);

  return (
    <HospitalFilterWrap>
      <div aria-label="Tabs" role="tablist" className="flex text-center flex-wrap" id="custom-tab">
        <button type="button" aria-controls="tab1" aria-selected={tab1_select} className="text-sm" id="tab1" onClick={() => toggle("tab1")}>입주예정</button>
        <button type="button" aria-controls="tab2" aria-selected={tab2_select} className="text-sm" id="tab2" onClick={() => toggle("tab2")}>신규상가</button>
      </div>
    </HospitalFilterWrap>
  );
};

export default AvailableFilter;