import React from "react";
import styled from "styled-components";
import { ymd_ago } from "../../common/PageTop";

function BuildingList({item}) {
  console.log(item);
  return (
    <div>
      <BuildingListUl>
        {item && item.APT_INFO && item.APT_INFO.map((item) => {
          return (
            <BuildingListLi key={"apt" + item.APT_MNG_NO}>
              <img src={`assets/img/available/map-marker-apartment.svg`} alt=""/>
              <p>{item.APT_NM}</p>
              <span className={"red"}>{ymd_ago(item.MOVIN_YM)} 입주</span>
            </BuildingListLi>
          );
        })}
        {item && item.SHOP_INFO && item.SHOP_INFO.map((item) => {
          return (
            <BuildingListLi key={"shop" + item.BDST_MNG_NO}>
              <img src={`assets/img/available/map-marker-building.svg`} alt=""/>
              <p>{item.BDST_NM}</p>
              <span className={"blue"}>{ymd_ago(item.USE_APRV_YMD)} 사용승인</span>
            </BuildingListLi>
          );
        })}
      </BuildingListUl>
    </div>
  );
}

const BuildingListUl = styled.ul`
  padding: 0.5rem 0;
`;

const BuildingListLi = styled.li`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
  img {
    width: 24px;
  }
  p {
    color: #000;
    font-size: 15px;
  }
  span {
    font-size: 13px;
    padding: 2px 6px;
    border-radius: 20px;
    height: 22px;
    min-width:max-content;
    &.red {
      color: #d20000;
      border: 1px solid #d78c8c;
      background: #fed5d5;
    }
    &.blue {
      color: #002b49;
      border: 1px solid #002b49;
      background: #eef4f8;
    }
  }
`;
export default BuildingList;
