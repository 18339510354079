import { useEffect, useState } from "react";
import styled from "styled-components";
import { Accordion } from "flowbite-react";

import AlarmCard from "./AlarmCard";
import { getToken } from "../../login/Token";
import axios from "axios";
import gcss_api from "../../api/api";
import { ymd_ago, ymd_format } from "../../common/PageTop";
import { useLocation, useNavigate } from "react-router-dom";

function AlarmBox({ alarmHideHandler }) {
  const [alarmData, setAlarmData] = useState();
  const [notice, setNotice] = useState();
  const token = getToken();
  const navigate = useNavigate();
  const location = useLocation();
  const option = {
    headers: {'Authorization': token, 'Content-Type': 'application/json'},
  }
  let prevTime = null;

  const onClickItem = (item) => {
    let to = "";
    switch(item.DATA_TYPE) {
      case "1": to = "/deal"; break;  // 거래
      case "2": to = "/non_deal"; break;  // 비거래
      case "3": to = "/hospital_soon"; break; // 예정
      case "4": to = "memo"; break; // 메모
      case "5": to = "/apt_detail"; break; // 아파트
      case "6": to = "/shop_detail"; break; // 상가
      case "9": to = "notice"; break; // 공지사항
    }
    if(to === "") return false;
    const options = {
      ...option,
      method: "PUT",
      url: gcss_api("api_alarm_history") + "?alarm_seq=" + item.ALARM_SEQ,
    }
    if(to === "notice") return false;
    axios(options).then((res) => {
      if(to === "memo") {
        alarmHideHandler();
        navigate("/hospital_map", {
          state: {
            COMB_KEY: item.KEY,
            RFRNC_TBL_NM: item.RFRNC_TBL_NM,
            DONG_CD: item.DONG_CD,
            from: location,
            memo: true,
          }
        });
      } else if(to === "/apt_detail" || to === "/shop_detail") {
        // 실제 데이터를 보고 추후에 개발해야할것으로 보임 TODO
        navigate("/available", {
          state: {
            to: to,
            key: item.KEY,
            alarm: true,
          }
        });
      } else {
        navigate(to, {
          state: {
            COMB_KEY: item.KEY,
            RFRNC_TBL_NM: item.RFRNC_TBL_NM,
            DONG_CD: item.DONG_CD,
            from: location,
            alarm: true,
          }
        });
      }
    });
  };
  
  useEffect(() => {
    const options = {
      ...option,
      method: "GET",
      url: gcss_api("api_alarm_history"),
    }
    axios(options).then((res) => {
      setAlarmData(res.data.filter(el => el.DATA_TYPE !== 9));
      setNotice(res.data.filter(el => el.DATA_TYPE === 9));
    });
  }, []);

  return (
    <AlarmBoxWrap>
      <AlarmBoxTop>
        <button onClick={alarmHideHandler}>
          <img src="assets/img/hospital_list/icon-left-arrow.svg" alt="" />
        </button>
        <h3>알림</h3>
        <button>
          {/*<img src="assets/img/login/icon-close.svg" alt="" />*/}
        </button>
      </AlarmBoxTop>
      <AlarmCard title={"공지사항"} icon={"notice"}>
        <Accordion id="alram-accordion">
          {notice?.map((item) => {
            return (
          <Accordion.Panel key={item.ALARM_SEQ}>
            <Accordion.Title>
              {!item.CHECK && <img src="assets/img/hospital_map/alram-notice.svg" alt="" />}
              {item.CHECK && <img src="assets/img/hospital_map/alram-notice-check.svg" alt="" />}
              {item.AN_NM}
              <UpDateBadge>{ymd_ago(item.SHIP_DT.replaceAll("-", "").substr(0,8))}</UpDateBadge>
            </Accordion.Title>
            <Accordion.Content>
              <p>{item.AN_CN}</p>
            </Accordion.Content>
          </Accordion.Panel>
            );
          })}
        </Accordion>
        {/*<BtnMore>더보기</BtnMore>*/}
      </AlarmCard>

      <AlarmCard title="" icon="" >
        <div>
          {alarmData?.map((item) => {
            let topLine = "";
            if(item.SHIP_DT.replaceAll("-", "").substr(0,8) !== prevTime) topLine = "topLine";
            prevTime = item.SHIP_DT.replaceAll("-", "").substr(0,8);

            let icon = "", icon2 = "";
            switch(item.DATA_TYPE) {
              case "1":
                icon = "assets/img/hospital_map/iconalram-icon3.svg";
                icon2 = "assets/img/hospital_map/iconalram-icon3-check.svg";
                break;
              case "2":
                icon = "assets/img/hospital_map/iconalram-icon2.svg";
                icon2 = "assets/img/hospital_map/iconalram-icon2-check.svg";
                break;
              case "3":
                icon = "assets/img/hospital_map/iconalram-icon1.svg";
                icon2 = "assets/img/hospital_map/iconalram-icon1-check.svg";
                break;
              case "4":
                icon = "assets/img/hospital_map/iconalram-icon7.svg";
                icon2 = "assets/img/hospital_map/iconalram-icon7-check.svg";
                break;
              case "5":
                icon = "assets/img/hospital_map/iconalram-icon5.svg";
                icon2 = "assets/img/hospital_map/iconalram-icon5-check.svg";
                break;
              case "6":
                icon = "assets/img/hospital_map/iconalram-icon4.svg";
                icon2 = "assets/img/hospital_map/iconalram-icon4-check.svg";
                break;
            }
            return (
              <div key={item.ALARM_SEQ}>
                {topLine && <span className="p-2">{ymd_format(prevTime, true).replace(/[0-9]*년/gi, "")}({ymd_ago(prevTime)})</span>}
                <AlramItem onClick={() => onClickItem(item)} className={topLine}>
                  <img src={item.CHECK ? icon2 : icon} alt="" />
                  <p>{item.RPRT_INST_NM}</p>
                </AlramItem>
              </div>
            );
          })}
        </div>
      </AlarmCard>
    </AlarmBoxWrap>
  );
}
const AlarmBoxWrap = styled.div`
  background: #eff2f3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 201;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  display: flex;
  flex-direction: column;
  #alram-accordion {
    padding: 5px 10px;
    > button {
      padding: 0.5rem 2px;
      background: none;
      h2 {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        img {
        }
      }
      &:focus {
        border: none;
        --tw-ring-opacity: 0;
        --tw-ring-color: rgb(255, 255, 255 / var(--tw-ring-opacity));
      }
    }
    > div {
      padding: 0 1rem 0.725rem;
      border-bottom: 1px solid #e4e4e4;
      border-radius: 0;
      p {
        color: #000;
        font-size: 13px;
        font-weight: 300;
        line-height: 20px;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

const AlarmBoxTop = styled.div`
  line-height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  background: #fff;
  border-bottom: 1px solid #eff2f3;
`;

const UpDateBadge = styled.span`
  height: 24px;
  padding: 0px 7px;
  line-height: 24px;
  color: #007a33;
  border: 1px solid #007a33;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  text-wrap: nowrap;
`;

const BtnMore = styled.button`
  width: calc(100% - 20px);
  height: 30px;
  border-radius: 5px;
  border: 1px solid #eff2f3;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #959595;
  font-size: 13px;
  margin: 0 auto;
`;

const AlramItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0.5rem 10px;
  span.page-name {
    color: #a0a0a0;
    font-size: 12px;
    font-weight: 500;
  }
  p {
    color: #000;
    font-size: 14px;
    font-weight: 500;
  }
  &.topLine {
    border-top: 1px solid #e4e4e4;
  }
`;
export default AlarmBox;
