import React from "react";
import styled from "styled-components";
import PageTop from "../common/PageTop";

import MypageTab from "./components/MypageTab";

function Mypage() {
  return (
    <div>
      <PageTop title={"마이페이지"} style={{ zIndex: "1" }} />
      <MypageContent>
        <div className="px-4 flex justify-end">
          <BtnLogin>
            <img src="/assets/img/icon-logout.svg" alt="" />
            로그아웃
          </BtnLogin>
        </div>

        <MypageTab />
      </MypageContent>
    </div>
  );
}

const MypageContent = styled.div`
  background: #eff2f3;
  padding: 55px 0 1.5rem;
  height: 100vh;
`;

const BtnLogin = styled.button`
  border: 1px solid #00ade6;
  background: #fff;
  color: #00ade6;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 5px;
  margin: 0.5rem 0;
  font-size: 13px;
  border-radius: 9999px;
`;
export default Mypage;
