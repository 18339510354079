import React from "react";
import styled from "styled-components";
import { getToken } from "../../login/Token";
import gcss_api from "../../api/api";
import axios from "axios";
import { getFilter, setFilterData, setFilterDataAll } from "../Filter";
import { IconArrowON } from "./IconArrowON";

const HospitalFilterFooter = ({name, resetFilter, mapChange, setIsVisible, onBtnAllClick, itemLength}) => {
  let option = {
    method: '',
    headers: {'Authorization': getToken(), 'Content-Type': 'application/json'},
    url: "",
    data: "",
  }
  
  const BtnRun = (id) => {
    if(id === 1) {
      //필터저장하기
      option.method = 'POST';
      option.url = gcss_api("api_filter_" + name);
      const filterData = getFilter()[name];
      let data = [];
      filterData.map((item) => {
        if(item.isActive) data.push(item.code);
      });
      if(name === "department") {
        option.data = JSON.stringify({"DEPARTMENT_LIST": data});
      } else if(name === "hospital-flag") {
        option.data = JSON.stringify({"HOSPITAL_FLAG_LIST": data});
      }
      if(data.length === 0) {
        alert("한 개 이상의 항목을 선택해야 필터자장됩니다.");
        return false;
      }
      axios(option).then((res) => {
        if(res.status) alert("필터를 저장하였습니다.");
        option.method = 'GET';
        option.url = gcss_api("api_filter_" + name);
        axios(option).then((res) => {
          setFilterData(name, res.data);
          resetFilter();
          setIsVisible(false);
          mapChange();
        }).catch((err) => {
          if(err?.response?.status === 401) window.location.href = "/logout";
          console.log(err); //에러가 안나와서 어떻게 나올지 감이 안잡힘
        });
      }).catch((err) => {
        if(err?.response?.status === 401) window.location.href = "/logout";
        console.log(err); //에러가 안나와서 어떻게 나올지 감이 안잡힘
      });
    } else if(id === 2) {
      //필터초기화
      option.method = 'GET';
      option.url = gcss_api("api_filter_" + name);
      axios(option).then((res) => {
        if(res.data.length !== 0) {
          setFilterData(name, res.data);
          resetFilter();
        } else setFilterDataAll(name);
      }).catch((err) => {
        if(err?.response?.status === 401) window.location.href = "/logout";
        console.log(err); //에러가 안나와서 어떻게 나올지 감이 안잡힘
      });
    } else if(id === 3) {
      // 필터 전체선택/해제
      const filterData = getFilter()[name];
      let data = [];
      Object.entries(filterData).map(([id,item]) => {
        if(item.isActive) data.push(id);
      });
      if(data.length === itemLength) onBtnAllClick(name, false);
      else onBtnAllClick(name, true);
    } else {
      // 필터 닫기
      setIsVisible(false);
    }
  }
  return (
    <div className="flex justify-between items-center px-2 pb-2 shadow-md">
      <div className="flex gap-x-2">
        <BtnFillterBottom onClick={() => BtnRun(1)}>
          <img src="assets/img/hospital_map/icon-filter.svg" alt="필터저장하기" />필터저장하기
        </BtnFillterBottom>
      </div>
      <div className="flex gap-x-2">
        <BtnBorder onClick={() => BtnRun(3)}>
          전체선택/해제
        </BtnBorder>
        <BtnBorder onClick={() => BtnRun(2)}>
          최근 저장 불러오기
        </BtnBorder>
        <IconArrowON btnRun={() => BtnRun(4)} />
      </div>
    </div>
  );
};

const BtnFillterBottom = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 2px;
  color: #7e7e7e;
  font-size: 13px;
  font-weight: 400;
`;

const BtnBorder = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 2px;
  color: #7e7e7e;
  font-size: 13px;
  font-weight: 400;
  border: 1px solid;
  padding: 0 5px;
`;
export default HospitalFilterFooter;
