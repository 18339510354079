import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Tabs } from "flowbite-react";
import HospitalFilterFooter from "./HospitalFilterFooter";
import { getFilter, getFilterData, setFilter, setFilterAll, setFilterData, setFilterDataNew, setFilterDataAll } from "../Filter";
import PeriodSetting from "../../period_arange/PeriodSetting";
import { useLocation } from "react-router-dom";
import { getToken } from "../../login/Token";
import gcss_api from "../../api/api";
import axios from "axios";

const HospitalFilter = ({mainpage, mapChange, mapData, filterHospitalData}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [filter, changeFilter] = useState();
  const [filterChange, setFilterChange] = useState(true);
  const [flagTitle, setFlagTitle] = useState("전체병원");
  const [departmentCount, setDepartmentCount] = useState("");
  const tapRef = useRef(null);
  const location = useLocation();

  const tabUnActive = (tab) => {
    setActiveTabIndex(tab);
    if(!filter) setFilterChange(!filterChange);
    setIsVisible(true);
  };

  const onBtnClick = (filterName, filterItem) => {
    setFilter(filterName, filterItem);
    setFilterChange(!filterChange);
  }

  const onBtnAllClick = (filterName, data) => {
    setFilterAll(filterName, data);
    setFilterChange(!filterChange);
  }

  const resetFilter = () => {
    setFilterChange(!filterChange);
  }

  useEffect(() => {
    changeFilter(getFilter());
  },[filterChange]);

  useEffect(() => {
    if(!filter) setFilterChange(!filterChange);
    else {
      let d = getFilterData("department");
      if(d) {
        if(d?.length === filter["department"]?.length) setDepartmentCount("All");
        else setDepartmentCount(d.length);
      }
      let f = getFilterData("hospital-flag");
      if(f) {
        if(f?.length === filter["hospital-flag"]?.length) {
          setFlagTitle("전체병원");
        } 
      }
      
      const tabItems = document.querySelectorAll("#custom-tab button");
      tabItems.forEach((item) => {
        if(item.textContent === "전체병원" || item.textContent === "진료과 All") {
          if(item.textContent === "전체병원") item.classList.remove("active");
          else if(item.textContent === "진료과 All") item.classList.remove("active");
          else item.classList.add("active");
        } else item.classList.add("active");
      });
    }
  }, [isVisible, mapData, filter]);

  const token = getToken();
  let option = {
    method: 'GET',
    headers: {'Authorization': token, 'Content-Type': 'application/json'},
    url: "",
  }
  useEffect(() => { 
    if(flagTitle === "") setFlag();
    if(departmentCount === "") setDepartment();
  }, [flagTitle, departmentCount]);

  useEffect(() => {
    setFlag();
    setDepartment();
  }, []);

  const setFlag = () => {
    if(!getFilterData("hospital-flag")) {
      option.url = gcss_api("api_filter_hospital-flag");
      axios(option).then((res) => {
        if(res.data.length === 0) setFilterDataAll("hospital-flag");
        else setFilterDataNew("hospital-flag", res.data);
        filterHospitalData();
      }).catch((err) => {
        if(err?.response?.status === 401) window.location.href = "/logout";
        console.log(err); //에러가 안나와서 어떻게 나올지 감이 안잡힘
      }); 
    }
  };

  const setDepartment = () => {
    if(!getFilterData("department")) {
      option.url = gcss_api("api_filter_department");
      axios(option).then((res) => {
        if(res.data.length === 0) setFilterDataAll("department");
        else setFilterDataNew("department", res.data);
        filterHospitalData();
      }).catch((err) => {
        if(err?.response?.status === 401) window.location.href = "/logout";
        console.log(err); //에러가 안나와서 어떻게 나올지 감이 안잡힘
      });
    }
  };

  useEffect(() => {
    if(!isVisible) tapRef.current?.setActiveTab(9);
    if(isVisible) {
      const d = getFilterData("department");
      const f = getFilterData("hospital-flag");
      if(d) setFilterData("department", d);
      if(f) setFilterData("hospital-flag", f);
      resetFilter();
    }
  }, [isVisible]);

  return (
    <HospitalFilterWrap>
      {/*<BtnArrow onClick={(tab) => setIsVisible(!isVisible)}>
        {isVisible ? <IconArrowON /> : <IconArrow />}
      </BtnArrow>*/}
      <Tabs id="custom-tab" onActiveTabChange={tabUnActive} ref={tapRef}>
        {/*<Tabs.Item title="브릭">
          {isVisible && filter && (
            <>
              <TabContents>
                <div className="flex items-center flex-wrap gap-1">
                  {Object.entries(filter["brick"]).map((id, item) => {
                    return (
                      <BrickLabel
                        key={id}
                        className={item.isActive ? "active" : ""}
                        onClick={() => {onBtnClick("brick", id)}}
                      >
                        {item.name}
                      </BrickLabel>
                    );
                  })}
                </div>
              </TabContents>
              <HospitalFilterFooter name="brick" mapChange={mapChange} resetFilter={resetFilter} />
            </>
          )}
        </Tabs.Item>*/}
        {mainpage && (
        <Tabs.Item title={flagTitle} >
          {isVisible && mainpage && filter && (
            <>
              <TabContents>
                <div className="flex items-center flex-wrap gap-1">
                  {filter["hospital-flag"]?.map((item) => {
                    return (
                      <BrickLabel
                        key={item.code}
                        className={item.isActive ? "active" : ""}
                        onClick={() => {onBtnClick("hospital-flag", item.code)}}
                      >
                        {item.name}
                      </BrickLabel>
                    );
                  })}
                </div>
              </TabContents>
              <HospitalFilterFooter name="hospital-flag" mapChange={mapChange} resetFilter={resetFilter} setIsVisible={setIsVisible} onBtnAllClick={onBtnAllClick} itemLength={Object.entries(filter["hospital-flag"]).length}/>
            </>
          )}
        </Tabs.Item>
        )}
        <Tabs.Item title={"진료과 " + departmentCount}>
          {isVisible && filter && (
            <>
              <TabContents>
                <div className="flex items-center flex-wrap gap-1">
                  {filter["department"]?.map((item) => {
                    return (
                      <BrickLabel key={item.code} className={item.isActive ? "active" : ""} onClick={() => {onBtnClick("department", item.code)}}>
                        {item.name}
                      </BrickLabel>
                    );
                  })}
                </div>
              </TabContents>
              <HospitalFilterFooter name="department" mapChange={mapChange} resetFilter={resetFilter} setIsVisible={setIsVisible} onBtnAllClick={onBtnAllClick} itemLength={Object.entries(filter["department"]).length}/>
            </>
          )}
        </Tabs.Item>
        {location?.pathname !== "/hospital_map" && location?.pathname !== "/hospital_list1" && (
        <Tabs.Item title="개원경과기간">
          <PeriodSetting setIsVisible={setIsVisible} mapChange={mapChange} isVisible={isVisible} />
        </Tabs.Item>
        )}
      </Tabs>
    </HospitalFilterWrap>
  );
};

export default HospitalFilter;

export const HospitalFilterWrap = styled.div`
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  background: #fff; 
  > div {
    gap: 0;
  }
  #custom-tab {
    padding: 0.7rem 0.5rem;
    gap: 0 2px;
    button[aria-controls] {
      height: 26px;
      padding: 0 15px;
      border: 1px solid #c3c3c3;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      color: #000;
      font-weight: 400;
      &[aria-selected="true"] {
        color: #1d3480;
        border: 1px solid #7185c5;
        background: #e3e9fc;
        &:focus {
          --tw-ring-opacity: 0;
          --tw-ring-color: rgb(255, 255, 255 / var(--tw-ring-opacity));
        }
      }
      &.active {
        color: #1d3480;
        border: 1px solid #7185c5;  
      }
    }
  }
  div[aria-labelledby] {
    padding: 0 !important;
  }
`;

export const BtnArrow = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const TabContents = styled.div`
  border-top: 1px solid #e8e8e8;
  margin: 0 0.5rem;
  padding: 10px 0 20px;
`;

export const BrickLabel = styled.button`
  border: 1px solid #dbdbdb;
  background: #f1f1f1;
  color: #868686;
  text-align: center;
  font-size: 12px;
  padding: 3px 15px;
  border-radius: 22px;
  &.active {
    border: 1px solid #002b49;
    color: #fff;
    background: #002b49;
  }
`;
