import {Navigate, useLocation} from "react-router-dom";

export const setToken = (token) => {
    localStorage.setItem('token', token);
};

export const getToken = () => {
    return localStorage.getItem('token');
};

export function AuthCheck({children}) {
    let auth = getToken();
    let location = useLocation();
    if(!auth || auth === "undefined") {
        if(location.pathname === '/') return children;
        else return <Navigate to="/" state={{ from: location }} />;
    } else {
        if(location.pathname === '/') return <Navigate to="/hospital_map" state={{ from: location }} />;
        else return children;
    }
}