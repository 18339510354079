import React from "react";
import styled from "styled-components";
import { ymd_ago, ymd_format } from "../../common/PageTop";

export function AvailableBuilding({item}) {
  return (
    <MapBuilding $size={50} $left={10}>
      <img src="assets/img/available/map-marker-building.svg" alt="" />
      <MapBuildingInfo $size={50}>
        <span>{ymd_ago(item.NEW_SHOP_INFO.USE_APRV_YMD)} 사용승인</span>
        <p>
          {item.NEW_SHOP_INFO.BDST_NM}
          <br />
          (총{item.NEW_SHOP_INFO.BDST_TFAR.toLocaleString()}㎡)
        </p>
      </MapBuildingInfo>
    </MapBuilding>
  );
}

export function AvailableApt({item}) {
  return (
    <MapBuilding $size={57}>
      <img src="assets/img/available/map-marker-apartment.svg" alt="" />
      <MapApartInfo $size={57}>
        <span>{ymd_ago(item.NEW_APARTMENT_INFO.MOVIN_YM)}</span>
        <p>
          {item.NEW_APARTMENT_INFO.APT_NM}
          <br />
          ({item.NEW_APARTMENT_INFO.HH_CNT.toLocaleString()}세대) <span>{ymd_format(item.NEW_APARTMENT_INFO.MOVIN_YM, true).replace("0일", "")}입주예정</span>
        </p>
      </MapApartInfo>
    </MapBuilding>
  );
}

const MapBuilding = styled.div`
  position: fixed;
  display: inline-block;
  img {
    position: relative;
    min-width: ${({$size}) => $size ? `${$size}px` : "0"};
    left: ${({$left}) => $left ? `${$left}px` : "0"};
  }
`;

const MapApartInfo = styled.div`
  position: relative;
  width: max-content;
  text-align: center;
  left: calc(-50% + ${({$size}) => $size ? `${$size / 2}px` : "0"});
  > span {
    color: #d20000;
    font-size: 11px;
    height: 20px;
    padding: 2px 6px;
    border-radius: 20px;
    border: 1px solid #d78c8c;
    background: #fed5d5;
  }
  > p {
    color: #000;
    text-align: center;
    margin-top: 5px;
    text-shadow: -2px 0px #feffda, 0px 2px #feffda, 2px 0px #feffda,
      0px -1px #feffda;
    font-size: 11px;
    font-weight: 600;
    span {
      display: block;
      font-weight: 400;
    }
  }
`;

const MapBuildingInfo = styled.div`
  position: relative;
  width: max-content;
  text-align: center;
  left: calc(-50% + ${({$size}) => $size ? `${$size / 2}px` : "0"});
  span {
    height: 20px;
    padding: 2px 6px;
    border-radius: 20px;
    border: 1px solid #002b4b;
    background: #eef4f8;
    color: #002b4b;
    font-size: 11px;
    width: max-content;
  }
  p {
    font-size: 11px;
    font-weight: 600;
    margin-top: 5px;
    text-shadow: -2px 0px #dafffd, 0px 2px #dafffd, 2px 0px #dafffd,
      0px -1px #dafffd;
  }
`;