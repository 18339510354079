import React from "react";
import { Accordion } from "flowbite-react";
import styled from "styled-components";

import DayBadge from "./DayBadgeRound";
import Process from "./Process";
import { ymd_ago, ymd_format } from "../../common/PageTop";

function HospitalRecruit({infoData}) {
  const openWindow = () => {
    if(infoData?.HOSPITAL_INFO?.RECN_URL_CN !== "정보없음") window.open(infoData.HOSPITAL_INFO.RECN_URL_CN,"_blank");
  }
  return (
    <HospitalRecruitWrap>
      {infoData && infoData.HOSPITAL_STEP_INFO && infoData.HOSPITAL_STEP_INFO.RECN_YN === "1" && (
      <div id="process-tab">
        <button>
          <h2>
            <Process text={"채용"} />
            <ConfirmDay className={infoData?.HOSPITAL_INFO?.RECN_URL_CN === "정보없음" ? "" : "active"} onClick={() => openWindow()}>최초공고일 {ymd_format(infoData.HOSPITAL_STEP_INFO.RECN_YMD)}</ConfirmDay>
            <DayBadge title={ymd_ago(infoData.HOSPITAL_STEP_INFO.RECN_YMD)} isActive={infoData.HOSPITAL_STEP_INFO.LCPMT_YN === "1" ? false : true} />
          </h2>
        </button>
      </div>
      )}
      {infoData && infoData.HOSPITAL_STEP_INFO && infoData.HOSPITAL_STEP_INFO.LCPMT_YN === "1" && (
      <div id="process-tab">
        <button>
          <h2>
            <Process text={"허가"} />
            <ConfirmDay>{ymd_format(infoData.HOSPITAL_STEP_INFO.LCPMT_YMD)}</ConfirmDay>
            <DayBadge title={ymd_ago(infoData.HOSPITAL_STEP_INFO.LCPMT_YMD)} isActive={infoData.HOSPITAL_STEP_INFO.HOPN_YN === "1" ? false : true} />
          </h2>
        </button>
      </div>
      )}
      {infoData && infoData.HOSPITAL_STEP_INFO && infoData.HOSPITAL_STEP_INFO.HOPN_YN === "1" && (
      <div id="process-tab">
        <button>
          <h2>
            <Process text={"개원"} />
            <ConfirmDay>{ymd_format(infoData.HOSPITAL_STEP_INFO.HOPN_YMD)}</ConfirmDay>
            <DayBadge title={ymd_ago(infoData.HOSPITAL_STEP_INFO.HOPN_YMD)} isActive={true} />
          </h2>
        </button>
      </div>
      )}
    </HospitalRecruitWrap>
  );
}

const HospitalRecruitWrap = styled.div`
  #process-tab {
    border-radius: 0;
    margin: 15px 0 10px;
    > button {
      padding: 0 1rem;
      background: none;
      h2 {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      &:focus {
        border: none;
        --tw-ring-opacity: 0;
        --tw-ring-color: rgb(255, 255, 255 / var(--tw-ring-opacity));
      }
    }
    > div {
      padding: 15px 1rem;
    }
  }
`;

const ConfirmDay = styled.p`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  &.active {
    color: #005bc6;
    text-decoration: underline;
  }
`;

const ProcessContainer = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  li {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    &:before {
      content: "▼";
      color: #005bc6;
      font-size: 7px;
      position: absolute;
      top: -11px;
      left: 13px;
    }
  }
`;
export default HospitalRecruit;
