import React from "react";
import styled from "styled-components";

const KeyWordBadge = ({ title }) => {
  return <KeyWordBadgeWrap>{title}</KeyWordBadgeWrap>;
};

const KeyWordBadgeWrap = styled.span`
  width: 56px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  background: #848484;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
`;
export default KeyWordBadge;
