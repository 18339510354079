import React, { useEffect, useState } from "react";
import HospitalCard from "./HospitalCard";
import styled from "styled-components";
import { db } from "../../db";

export default function HospitalCardContainer({filter}) {
  const [hospital1, setHospital1] = useState(Array());
  useEffect(() => {
    async function getData() {
      setHospital1(await db.hospital.orderBy("RECN_YMD").reverse().filter(item => item.HOSPITAL_FLAG === 'N').toArray());
    }
    getData();
  },[filter]);
  return (
    <HospitalCardContainerWrap>
      {hospital1?.map((item) => {
        if(item.HOPN_STEP_NM === "채용") return (<HospitalCard item={item} key={item.COMB_KEY} />);
      })}
    </HospitalCardContainerWrap>
  );
}

const HospitalCardContainerWrap = styled.div`
  position: relative;
  top: 55px;
  max-width: 768px;
  margin: 0 auto;
  background: #eff2f3;
  padding: 1rem;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  height: calc(100vh - 55px);
`;
