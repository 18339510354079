import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Accordion } from "flowbite-react";
import { ModalInner } from "../../login/components/AgreeModal";
import { getToken } from "../../login/Token";
import gcss_api from "../../api/api";
import axios from "axios";
import { db } from "../../db";
import { ymd_ago } from "../../common/PageTop";

const mokupData2 = [
  {
    company: "CG녹십자",
    description: "치료제 및 백신 개발, 생산, 공급",
    product: [
      { name: "감마글로불린" },
      { name: "녹십자티디백신" },
      { name: "일본뇌염백신" },
    ],
  },
  {
    company: "CG녹십자",
    description: "병의원 대상의 영양주사제, 개인맞춤형 건강기능식품 개발, 생산",
    product: [
      { name: "수액제" },
      { name: "의료기기" },
      { name: "아미노산제" },
      { name: "태반주사제" },
    ],
  },
  {
    company: "CG녹십자",
    description: "치료제 및 백신 개발, 생산, 공급",
    product: [
      { name: "감마글로불린" },
      { name: "녹십자티디백신" },
      { name: "일본뇌염백신" },
    ],
  },
];

function FamilyModal({ familyHideHandler, hospital }) {
  const token = getToken();
  const [ctpv, setCtpv] = useState({});
  const [sgg, setSgg] = useState({});
  const [dong, setDong] = useState({});
  const [family, setFamily] = useState([]);

  const option = {
    method: 'GET',
    headers: {'Authorization': token, 'Content-Type': 'application/json'},
  };

  useEffect(() => {
    const options = { ...option, url: gcss_api("api_hospital_family-info") + "?comb_key=" + hospital?.HOSPITAL_INFO?.COMB_KEY, }
    axios(options).then((res) => {
      setFamily(res.data);
    }).catch((err) => {
      if(err?.response?.status === 401) window.location.href = "/logout";
      console.log(err); //에러가 안나와서 어떻게 나올지 감이 안잡힘
    });

    async function getData() {
      const ctpvData = {};
      await db.city.orderBy("DONG_CTPV_CD").each((item) => {
        ctpvData[item.DONG_CTPV_CD] = item.DONG_CTPV_NM;
      });
      setCtpv(ctpvData);
      const sggData = {};
      await db.sgg.orderBy("DONG_SGG_CD").each((item) => {
        sggData[item.DONG_SGG_CD] = item.DONG_SGG_NM;
      });
      setSgg(sggData);
      const dongData = {};
      await db.dong.orderBy("DONG_CD").each((item) => {
        dongData[item.DONG_CD] = item.DONG_NM;
      });
      setDong(dongData);
    };
    getData();
  },[]);

  return (
    <div>
      <FamilyModalWrap className="modal-family">
        <div className="fixed inset-0 bg-black/25 backdrop-blur-sm">
          <div className=" h-max mx-4 my-8 z-20 relative">
            <ModalInner className="w-full h-max bg-white rounded-lg">
              <FamilyModalTop className="flex justify-between items-center px-4 py-2">
                <h3 className="text-black text-lg font-semibold ">
                  GC 가족사 정보
                </h3>
                <button onClick={familyHideHandler}>
                  <img src="assets/img/hospital_map/icon-close-gray.svg" style={{ width: "32px" }} />
                </button>
              </FamilyModalTop>

              <FamilyModalBottom>
                <AreaLocation>
                  <span className="text-[13px] font-medium text-nowrap">
                    {ctpv[hospital?.HOSPITAL_INFO?.DONG_CTPV_CD]} {sgg[hospital?.HOSPITAL_INFO?.DONG_SGG_CD]}
                  </span>
                  <img src="assets/img/hospital_map/icon-location-arrow.svg" alt="" />
                  <span className="text-[13px] font-medium text-nowrap">{dong[hospital?.HOSPITAL_INFO?.DONG_CD]}</span>
                  <img src="assets/img/hospital_map/icon-location-arrow.svg" alt="" />
                  <strong className="text-[13px]  text-semibold">
                    {hospital?.HOSPITAL_INFO?.RPRT_INST_NM}
                  </strong>
                </AreaLocation>
                <FamilyListUl>
                  {family?.map((item) => (
                    <FamilyListLi>
                      <span className={item.DLNG_YN === '1' ? "" : "disabled"}>
                        {item.DLNG_YN === '1' ? (ymd_ago(item.FRST_CTRT_YMD) ? ymd_ago(item.FRST_CTRT_YMD) : "거래") : "비거래"}
                      </span>
                      <p>
                        {item.AFFL_NM} {item.USER_NM}
                      </p>
                      {item.TEL_NO_CN !== "정보없음" && <strong>|</strong>}
                      {item.TEL_NO_CN !== "정보없음" && <p>{item.TEL_NO_CN}</p>}
                    </FamilyListLi>
                  ))}
                </FamilyListUl>
                {false && <FamilyProductWrap className="mt-4">
                  <ProductTop>GC 가족사 및 제품 소개</ProductTop>
                  {mokupData2.map((item) => (
                    <Accordion id="family-accordion">
                      <Accordion.Panel>
                        <Accordion.Title>
                          <img src="assets/img/gc-color-logo.svg" alt="" />
                        </Accordion.Title>
                        <Accordion.Content>
                          <div className="flex justify-between items-start">
                            <p>{item.description}</p>
                            <button>
                              상세
                              <img src="assets/img/hospital_map/icon-detail-arrow.svg" alt="" />
                            </button>
                          </div>
                          <div className="flex gap-1 mt-1">
                            {item.product.map((product, idx) => (
                              <ProductLabel key={idx}>
                                {product.name}
                              </ProductLabel>
                            ))}
                          </div>
                        </Accordion.Content>
                      </Accordion.Panel>
                    </Accordion>
                  ))}
                </FamilyProductWrap>}
              </FamilyModalBottom>
            </ModalInner>
          </div>
        </div>
      </FamilyModalWrap>
    </div>
  );
}

const FamilyModalWrap = styled.div`
  &.modal-family {
    position: fixed;
    z-index: 11;
  }
`;

const FamilyModalTop = styled.div`
  border-bottom: 1px solid #e4e4e4;
`;

const FamilyModalBottom = styled.div`
  padding: 10px 1rem 30px;
`;

const AreaLocation = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  span {
    font-weight: 500;
  }
  strong {
    font-weight: 700;
  }
`;

const FamilyListUl = styled.ul`
  margin: 1rem 0 0.5rem;
`;

const FamilyListLi = styled.li`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
  span {
    width: 58px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #007a33;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid #007a33;
    &.disabled {
      color: #7d7d7d;
      border: 1px solid #7d7d7d;
      background: #eee;
    }
  }
  p {
    color: #000;
    font-size: 14px;
    font-weight: 500;
  }
  strong {
    color: #959595;
  }
`;

const FamilyProductWrap = styled.div`
  border: 1px solid #e8e8e8;
  #family-accordion {
    > button {
      padding: 0.5rem;
      background: none;
      border-bottom: 1px solid #e8e8e8;
      &:focus {
        border: none;
        --tw-ring-opacity: 0;
        --tw-ring-color: rgb(255, 255, 255 / var(--tw-ring-opacity));
      }
    }
    > div {
      padding: 0.8rem 0.5rem;
      border-bottom: 1px solid #e8e8e8;
      border-radius: 0;
      p {
        width: calc(100% - 40px);
        color: #000;
        font-size: 14px;
        font-weight: 500;
      }
      button {
        width: 34px;
        display: flex;
        align-items: center;
        gap: 3px;
        color: #072c49;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
`;

const ProductTop = styled.div`
  background: #f6f6f6;
  border-bottom: 1px solid #e8e8e8;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  color: #000;
  font-size: 16px;
  font-weight: 600;
`;

const ProductLabel = styled.span`
  padding: 1px 10px;
  color: #007a33;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid #007a33;
  border-radius: 5px;
`;
export default FamilyModal;
