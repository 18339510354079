import { useState } from "react";
import styled from "styled-components";
import { setToken } from "../Token";
import { setUser } from "../User";
import { Accordion, Checkbox, Label } from "flowbite-react";

function AgreeModal({ showModal, onModalHandler, offModalHandler, offWindowHandler, modalData, authData, setAgree }) {
  const [showSubModal, setShowModal] = useState(false);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const onSubModalHandler = () => {
    offModalHandler();
    setShowModal(true);
  };
  const offSubModalHandler = () => {
    offWindowHandler();
  };
  const onAgreeHandler = () => {
    if(!check1) {
      alert("서약 체크를 해주세요.");
    } else if(!check2) {
      alert("제3자 제공 및 위탁에 동의해주세요.");
    } else {
      setToken(authData.token);
      setUser(authData.user);
      setAgree();
      window.location.href = "/hospital_map";
    }
  };
  const onReHandler = () => {
    onModalHandler();
    setShowModal(false);
  };
  const changeCheck1 = () => {
    setCheck1(prev => !prev);
  }
  const changeCheck2 = () => {
    setCheck2(prev => !prev);
  }
  return (
    <div>
      {showModal && (
        <div className="fixed inset-0 bg-black/25 backdrop-blur-sm">
          <ModalDialog className="w-max h-max mx-auto my-8 z-20 relative">
            <ModalClose onClick={offModalHandler}>
              <img src="assets/img/login/icon-close.svg" />
            </ModalClose>
            <ModalInner className="w-full h-max bg-white px-2 pt-8 pb-2 rounded-lg">
              <ModalHeader>{modalData.title}</ModalHeader>
              <ModalContent>
                <p className="pt-6 break-words font-medium text-sm">
                  {modalData.header}
                </p>
                <Accordion collapseAll>
                  <Accordion.Panel>
                    <Accordion.Title>{modalData.agrement_title_list[0]}</Accordion.Title>
                    <Accordion.Content>
                      <ul className="my-4 agree-list text-sm">
                        {modalData.agrement_detail1_list.map((ele, idx) => {
                          return (<li className="mb-2">{idx+1}. {ele}</li>);
                        })}
                      </ul>
                    </Accordion.Content>
                    <div className="flex items-center gap-2">
                      <Checkbox id="agree1" onChange={() => changeCheck1()}/>
                      <Label htmlFor="agree1"> {modalData.checkbox_title_list[0]}</Label>
                    </div>
                  </Accordion.Panel>
                  <Accordion.Panel>
                    <Accordion.Title>{modalData.agrement_title_list[1]}</Accordion.Title>
                    <Accordion.Content>
                      <ul className="my-4 text-sm">
                        <li>{modalData.agrement_detail2_list[0]}</li>
                        <li className="pl-2 pt-2">● {modalData.agrement_detail2_list[1]}</li>
                        <li className="pl-4">: {modalData.agrement_detail2_list[2]}</li>
                        <li className="pl-2">● {modalData.agrement_detail2_list[3]}</li>
                        <li className="pl-4">: {modalData.agrement_detail2_list[4]}</li>
                        <li className="pl-2">● {modalData.agrement_detail2_list[5]}</li>
                        <li className="pl-4">: {modalData.agrement_detail2_list[6]}</li>
                        <li className="pl-2">● {modalData.agrement_detail2_list[7]}</li>
                        <li className="pl-4">: {modalData.agrement_detail2_list[8]}</li>
                      </ul>
                    </Accordion.Content>
                    <div className="flex items-center gap-2">
                      <Checkbox id="agree2"  onChange={() => changeCheck2()}/>
                      <Label htmlFor="agree2"> {modalData.checkbox_title_list[1]}</Label>
                    </div>
                  </Accordion.Panel>
                </Accordion>
                <div className="my-4 agree-list text-sm">
                </div>
              </ModalContent>
              <ModalFooter>
                <p className="notice text-sm">
                  {modalData.footer}
                </p>
                <div className="flex justify-between items-center">
                  <BtnGray onClick={onSubModalHandler}>동의하지 않음</BtnGray>
                  <BtnMain onClick={onAgreeHandler}>동의함</BtnMain>
                </div>
              </ModalFooter>
            </ModalInner>
          </ModalDialog>
        </div>
      )}

      {/* TODO : 동의하지않음 버튼 클릭시 나오는 팝업  */}
      {showSubModal && (<div className="fixed inset-0 bg-black/25 backdrop-blur-sm">
           <ModalDialog className="w-max h-max mx-auto my-8 z-20 relative">
             <ModalInner className="w-full h-max bg-white px-4 pt-8 pb-2 rounded-lg">
               <ModalContent>
                 <div className="text-center my-2">
                   <img
                     src="assets/img/login/icon-warning.svg"
                     alt=""
                     className="inline-block"
                   />
                 </div>
                 <p className="break-words font-medium text-center mb-6 text-sm">
                   지능형 영업정보 서비스 사용 서약에
                   <br /> 동의하지 않을 경우,
                   <br /> 해당 서비스를 이용할 수 없습니다.
                 </p>
               </ModalContent>
               <ModalFooter>
                 <p className="notice text-sm">서비스를 종료하시겠습니까?</p>
                 <div className="flex justify-between items-center">
                   <BtnGray onClick={offSubModalHandler}>서비스 종료</BtnGray>
                   <BtnMain onClick={onReHandler}>
                     동의하고 <br />
                     서비스 이용하기
                   </BtnMain>
                 </div>
               </ModalFooter>
             </ModalInner>
           </ModalDialog>
         </div>
      )}
    </div>
  );
}

export const ModalDialog = styled.div`
  max-width: 90%;
  margin: 40px auto;
`;

export const ModalInner = styled.div``;

export const ModalClose = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const ModalHeader = styled.div`
  border-bottom: 1px solid #e4e4e4;
  padding: 10px 1rem;
  font-size: 18px;
  font-weight: 500;
`;

export const ModalContent = styled.div`
  max-height: calc(90vh - 2.5rem - 152.3px);
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  border-bottom: 1px solid #e4e4e4;
  padding: 0 0.5rem;
  .agree-list {
    text-indent: -18px;
    padding-left: 20px;
  }
  > p {
    margin-bottom: 15px;
  }
  > div {
    > button {
      min-width: fit-content;
      text-wrap: nowrap;
      height: 28px;
      border: 1px solid #00ade6 !important;
      border-radius: 9999px !important;
      background: #f0fbff !important;
      color: #00ade6;
      font-size: 13px;
    }
    > div {
      padding: 0px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }
`;

const ModalFooter = styled.div`
  .notice {
    color: #00ade6;
    margin: 20px 0 40px;
    padding: 0 0.5rem;
  }
`;

const BtnGray = styled.div`
  width: 47%;
  height: 50px;
  border-radius: 10px;
  background: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ababab;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-right: 2.5px;
`;
const BtnMain = styled.div`
  width: 47%;
  height: 50px;
  border-radius: 10px;
  background: #00ade7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-left: 2.5px;
`;

export default AgreeModal;
