import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Accordion } from "flowbite-react";

import styled from "styled-components";
import HospitalDetailItem from "./HospitalDetailItem";
import { db } from "../../db";

function HospitalSidebar({hospital_flag, filter}) {
  // TODO: pathname에 따라 className 값줌 추후  개발시에 수정
  const location = useLocation();
  const pathname = location?.pathname;
  const [hospitalData, setHospitalData] = useState(Array());
  const [locationData, setLocationData] = useState(Array());
  const [dong, setDong] = useState({});
  const [sgg, setSgg] = useState({});
  const [select, setSelect] = useState("");

  const checkRange = (ymd) => {
    const now = new Date();
    const target = new Date(ymd.substring(0,4) + "/" + ymd.substring(4,6) + "/" + ymd.substring(6));
    const start = new Date(now);
    start.setMonth(now.getMonth() - (filter[1][0] * 12));
    const end = new Date(now);
    end.setMonth(now.getMonth() - (filter[1][1] * 12));
    if(filter[1][0] === 0 && filter[1][1] === 6) return true;
    else if(filter[1][0] === 0) return target >= end;
    else if(filter[1][1] === 6) return target <= start;
    else return target >= end && target <= start;
  };

  useEffect(() => {
    async function getData() {
      setHospitalData(await db.hospital.orderBy('RPRT_INST_NM')
        .filter(item => item.HOSPITAL_FLAG.substring(0,1) === hospital_flag)
        .filter(item => filter[0]?.some(data => data[0] === "0" || item.DEPARTMENT_LIST?.some(code => code === data)))
        .filter(item => filter[1] === null || checkRange(item.HOPN_YMD))
        .toArray());
      const locationCode = {};
      await db.hospital.orderBy('DONG_CD')
        .filter(item => item.HOSPITAL_FLAG.substring(0,1) === hospital_flag)
        .filter(item => filter[0]?.some(data => data[0] === "0" || item.DEPARTMENT_LIST?.some(code => code === data)))
        .filter(item => filter[1] === null || checkRange(item.HOPN_YMD))
        .each(item => {
          if(locationCode[item.DONG_SGG_CD] === undefined) locationCode[item.DONG_SGG_CD] = {};
          locationCode[item.DONG_SGG_CD].count = (locationCode[item.DONG_SGG_CD].count || 0) + 1;
          if(locationCode[item.DONG_SGG_CD].data === undefined) locationCode[item.DONG_SGG_CD].data = {};
          if(locationCode[item.DONG_SGG_CD].data[item.DONG_CD] === undefined) locationCode[item.DONG_SGG_CD].data[item.DONG_CD] = {};
          locationCode[item.DONG_SGG_CD].data[item.DONG_CD].count = (locationCode[item.DONG_SGG_CD].data[item.DONG_CD].count || 0) + 1;
        });
      setLocationData(locationCode);
    }
    if(filter) getData();
  }, [filter]);

  useEffect(() => {
    async function getData() {
      const sggData = {};
      await db.sgg.orderBy("DONG_SGG_CD").each((item) => {
        sggData[item.DONG_SGG_CD] = item.DONG_SGG_NM;
      });
      setSgg(sggData);
      const dongData = {};
      await db.dong.orderBy("DONG_CD").each((item) => {
        dongData[item.DONG_CD] = item.DONG_NM;
      });
      setDong(dongData);
    };
    getData();
  },[]);

  useEffect(() => {
    if(location.state?.DONG_CD) setSelect(location.state.DONG_CD);
    else if(!select && locationData && Object.entries(locationData).length > 0) setSelect(Object.entries(Object.entries(locationData)[0][1].data)[0][0]);
    setTimeout(() => {
      if(document.querySelector("strong.active")) {
        if(!document.querySelector("strong.active").parentElement.parentElement.querySelector("svg.rotate-180")) document.querySelector("strong.active").click();
        if(document.querySelector("button.active")) document.querySelector("button.active").scrollIntoView();
      }
    }, 1000);
  }, [locationData]);

  useEffect(() => {
    document.querySelector("#right").scrollTo(0,0);
  }, [select]);

  return (
    <div className="flex">
      <Total className={pathname === "/hospital_list3" ? "border" : ""} onClick={() => {setSelect("All")}}>
        <h3>브릭전체 ({hospitalData.length})</h3>
      </Total>
      <Left>
        <ScrollWrap>
          <Accordion className="px-3 py-2" data-accordion="collapse" id="similar-accordion">
          {locationData && Object.entries(locationData).map((data) => {
            if(sgg && data[0]) {
              return (
                <Accordion.Panel key={data[0]}>
                  <Accordion.Title>
                    <strong className={select && select.substring(0,5) === data[0] ? "active block text-black text-xs font-normal leading-tight mb-1" : "block text-black text-xs font-normal leading-tight mb-1"}
                      onClick={(e) => { e.stopPropagation(); setSelect(data[0]); }}>
                      {sgg[data[0]]} ({data[1].count})
                    </strong>
                  </Accordion.Title>
                  <Accordion.Content>
                    <ul className="accordion-content">
                      {dong && data[1].data && Object.entries(data[1].data).map((item) => {
                        return (
                          <li key={item[0]}>
                            <button id={item[0]} onClick={() => setSelect(item[0])} className={select && select === item[0] ? "active": ""}>
                              {dong[item[0]]} <span>({item[1].count})</span>
                            </button>
                          </li>
                        )
                      })}
                    </ul>
                  </Accordion.Content>  
                </Accordion.Panel>
              );
            }
          })}
          </Accordion>
        </ScrollWrap>
      </Left>
      <Right>
        <ScrollWrap id="right">
          {select && (<HospitalDetailItem hospitalData={hospitalData} select={select} dong={dong} sgg={sgg} />)}
        </ScrollWrap>
      </Right>
    </div>
  );
}

const Left = styled.div`
  margin-top: 133px;
  width: 115px;
  height: calc(100vh - 133px);
  border-right: 1px solid #e0e0e0;

  box-shadow: 4px 1px 2px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  #similar-accordion {
    > button {
      padding: 0;
      background: none;
      &:focus {
        border: none;
        --tw-ring-opacity: 0;
        --tw-ring-color: rgb(255, 255, 255 / var(--tw-ring-opacity));
      }
      > h2:has(.active) {
        background-color: #e3e9fc;
        width: 100%;
      }
    }
    > div {
      padding: 0;
    }
  }
  
  strong.active {
    padding-top: 5px;
    margin-bottom: 5px;
  }

  .accordion-content {
    border-left: 1px solid #e2e2e2;
    li {
      color: #8b8b8b;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 0.2rem;
      padding-left: 15px;
      position: relative;
      button {
        text-align: left;
      }
      button.active {
        background-color: #e3e9fc;
        width: 100%;
      }
      span {
        color: #000;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }
      &:before {
        content: "";
        width: 10px;
        height: 1px;
        background: #e2e2e2;
        position: absolute;
        top: 9px;
        left: 0;
      }
    }
  }
`;

const Total = styled.div`
  position: fixed;
  top: 103px;
  width: 115px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #007a33;
  color: #fff;
  h3 {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
  }
  &.border {
    background: #fff;
    color: #007a33;
    border: 1px solid #007a33;
  }
`;

const ScrollWrap = styled.div`
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 103px);
`;

const Right = styled.div`
  margin-top: 103px;
  height: calc(100vh - 103px);
  width: calc(100% - 115px);
  overflow-y: auto;
`;

export default HospitalSidebar;
