import React from "react";
import styled from "styled-components";

const MapArea = ({ color, size }) => {
  return (
    <MapAreaWrap className={color} $size={size} />
  );
};
const MapAreaWrap = styled.div`
  position: fixed;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  z-index: 0;
  width: ${({$size}) => $size ? `${$size}px` : "0"};
  height: ${({$size}) => $size ? `${$size}px` : "0"};
  &.green {
    background-color: #007a33;
    color: #fff;
    &.actCluster {
      border: 5px solid #92d050;
      z-index: 1;
    }
  }
  &.green-border {
    border: 1px solid #007a33;
    background-color: #fff;
    color: #007a33;
    &.actCluster {
      border: 5px solid #007a33;
      z-index: 1;
    }
  }
  &.blue {
    background-color: #00ade6;
    color: #fff;
    &.actCluster {
      border: 5px solid #81deff;
      z-index: 1;
    }
  }
  &.darkBlue {
    background-color: #002b4b;
    color: #fff;
    &.actCluster {
      border: 5px solid #00ADE6;
      z-index: 1;
    }
  }
`;
export default MapArea;
