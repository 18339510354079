import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

export const ymd_format = (ymd, echo = false) => {
  if(ymd && ymd !== '20991231') {
    const year = ymd.substring(0,4);
    const month = ymd.substring(4,6);
    const day = ymd.substring(6);
    if(echo) return year + "년 " + Number(month) + "월 " + Number(day) + "일";
    else return year + "." + month + "." + day;
  }
  return "";
}
export const ymd_ago = (ymd) => {
  if(ymd && ymd !== '20991231') {
    const today = new Date();
    let ago;
    if(ymd.substring(0,4) != today.getFullYear()) {
      if(today.getFullYear() > ymd.substring(0,4)) {
        if(today.getFullYear() - ymd.substring(0,4) == 1 && today.getMonth() + 1 < ymd.substring(4,6))
          ago = (today.getMonth() + 13 - ymd.substring(4,6)) + "개월 전";
        else ago = (today.getFullYear() - ymd.substring(0,4)) + "년 전";
      } else {
        if(today.getFullYear() - ymd.substring(0,4) == 1 && today.getMonth() + 1 > ymd.substring(4,6))
          ago = (ymd.substring(4,6) + 12 - (today.getMonth() + 1)) + "개월 후";
        else ago = (ymd.substring(0,4) - today.getFullYear()) + "년 후";
      }
    } else if(ymd.substring(4,6) != today.getMonth() + 1) {
      if(today.getMonth() + 1 > ymd.substring(4,6)) {
        if(today.getMonth() + 1 - ymd.substring(4,6) == 1 && today.getDate() < ymd.substring(6)) {
          const lastDay = today.getMonth() + 2 > 12 ? new Date((today.getFullYear() + 1) + "/" + (today.getMonth() + 2 - 12) + "/" + today.getDate()) : new Date(today.getFullYear() + "/" + (today.getMonth() + 2) + "/" + today.getDate());
          lastDay.setDate(0);
          ago = (today.getDate() + lastDay.getDate() - ymd.substring(6)) + "일 전";
        } else ago = (today.getMonth() + 1 - ymd.substring(4,6)) + "개월 전";
      } else {
        if(ymd.substring(4,6) - (today.getMonth() + 1) == 1 && today.getDate() > ymd.substring(6)) {
          const lastDay = Number(ymd.substring(4,6)) + 1 > 12 ? new Date((Number(ymd.substring(0,4)) + 1) + "/" + (Number(ymd.substring(4,6)) + 1 - 12) + "/" + ymd.substring(6)) : new Date(ymd.substring(0,4) + "/" + (Number(ymd.substring(4,6)) + 1) + "/" + ymd.substring(6));
          lastDay.setDate(0);
          if(lastDay.getDate() - today.getDate() > 0){
            ago = (ymd.substring(6) + lastDay.getDate() - today.getDate()) + "일 후";
          }else{
            var aa = Number(ymd.substring(6));
            ago = aa + "일 후";
          }
        } else ago = (ymd.substring(4,6) - (today.getMonth() + 1)) + "개월 후";
      }
    } else if(ymd.substring(6) != today.getDate()) {
      if(today.getDate() > ymd.substring(6)) ago = (today.getDate() - ymd.substring(6)) + "일 전";
      else ago = (ymd.substring(6) - today.getDate()) + "일 후";
    } else ago = "오늘";
    return ago;
  }
  return "";
}

const PageTop = ({ title }) => {
  const location = useLocation();

  let to = "/hospital_map";
  let center = location?.state?.center ? location.state.center : "";
  switch(location?.pathname) {
    case "/hospital_list1": case "/hospital_list2": case "/hospital_list3": to = "/hospital_map"; break;
    default: to = location?.state?.from?.pathname ? location.state.from.pathname : "/hospital_map"; break;
  }

  return (
    <ListTopWrap className="">
      <BtnBack>
        <Link to={to} state={{center: center}}>
          <img src="assets/img/hospital_list/icon-left-arrow.svg" alt="" />
        </Link>
      </BtnBack>
      <h1>{title}</h1>
    </ListTopWrap>
  );
};

const ListTopWrap = styled.div`
  width: 100%;
  height: 55px;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  border-bottom: 1px solid #e0e0e0;
  z-index: 20;
  background-color: #FFF;
  h1 {
    color: #000;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
`;

export const BtnBack = styled.button`
  position: absolute;
  top: 50%;
  left: 0.0rem;
  margin-top: -12px;
`;
export default PageTop;
