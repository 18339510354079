import React from "react";
import SampleGraph2 from "./SampleGraph2";
import styled from "styled-components";

function CustomBarGraphPerson({ caption, compare, areaInfo }) {
  return (
    <CustomBarGraphPersonWrap>
      <div className="flex items-center gap-1">
        <strong className="text-base font-medium">{caption}</strong>
      </div>
      <div className="flex items-center gap-1">
        <span className="text-black text-xs">{compare}</span>
      </div>
      <SampleGraph2 areaInfo={areaInfo}/>
    </CustomBarGraphPersonWrap>
  );
}

const CustomBarGraphPersonWrap = styled.div`
  border-radius: 10px;
  background: #f4fdff;
  padding: 1rem 1rem 0;
  position: relative;
`;

export default CustomBarGraphPerson;