import React, { useState } from "react";
import styled from "styled-components";
import { ymd_ago } from "../../common/PageTop";
import { getUser } from "../../login/User";
import { getToken } from "../../login/Token";
import gcss_api from "../../api/api";
import axios from "axios";
import MemoEditor from "./MemoEditor";
import MemoReply from "./MemoReply";

const MemoItem = ({item, getSales}) => {
  const user = getUser();
  const token = getToken();
  const [showEditor, setShowEditor] = useState(false);
  const [showReply, setShowReply] = useState(false);

  const option = {
    headers: {'Authorization': token, 'Content-Type': 'application/json'},
    url: gcss_api("api_memo_hospital"),
  };

  const onModify = () => setShowEditor(true);
  const closeEditor = () => setShowEditor(false);

  const onDelete = () => {
    if(window?.confirm("삭제하시겠습니까?")) {
      option.method = "DELETE";
      option.data = JSON.stringify({"COMB_KEY": item.COMB_KEY, MEMO_SEQ: item.MEMO_SEQ});
      axios(option).then(() => {
        getSales();
      }).catch((err) => {
        if(err?.response?.status === 401) window.location.href = "/logout";
        console.log(err);
        alert("영업메모 삭제에 실패하였습니다.");
      });
    }
  };

  const onReply = () => setShowReply(true);
  const closeReply = () => setShowReply(false);

  return (
    <MemoItemWrap $depth={item.DEPTH}>
      <div className="flex items-center gap-1 mb-1 text-xs">
        <img src="assets/img/hospital_map/icon-clock-gray.svg" alt="" />
        <p className="text-neutral-500">
          {ymd_ago(item.SHIP_DT.replaceAll("-","").substring(0,8))} ({item.SHIP_DT.replaceAll("-",".").replaceAll("T"," ")})
        </p>
        <p className="flex items-center gap-2 ml-1">
          {item.USER_NM === user && <button className="text-xs font-semibold" onClick={() => onModify()}>수정</button>}
          {item.USER_NM === user && <SpaceBar />}
          {item.USER_NM === user && <button className="text-xs font-semibold" onClick={() => onDelete()}>삭제</button>}
          {item.USER_NM === user && item.DEPTH === '0' && <SpaceBar />}
          {item.DEPTH === '0' && <button className="text-xs font-semibold" onClick={() => onReply()}>답글쓰기</button>}
        </p>
      </div>
      <div className="flex items-center gap-1 text-xs pl-3 mb-1">
        <p>GC{item.AFFL_NM}</p>
        <SpaceBar />
        <p className="flex items-center gap-2">
          {item.USER_NM} {item.RLS_LVL_CD === "0" && <img src="/assets/img/icon-lock.svg" alt="" />}
        </p>
      </div>
      {showEditor ? <MemoEditor item={item} getSales={getSales} closeEditor={closeEditor}/> : <pre className="text-xs pl-3 text-wrap">{item.MEMO_CN}</pre>}
      {showReply && <MemoReply item={item} getSales={getSales} closeReply={closeReply} />}
    </MemoItemWrap>
  );
};

const MemoItemWrap = styled.div`
  padding: 0.725rem 0 0.725rem ${({$depth}) => $depth ? `${$depth}rem` : "0"};
  border-bottom: 1px solid #e4e4e4;
`;

const SpaceBar = styled.span`
  width: 1px;
  height: 11px;
  background: #959595;
`;

export default MemoItem;
