import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, LabelList, ResponsiveContainer } from "recharts";
import { db } from "../../db";

const renderCustomizedLabel = (props) => {
  const { x, y, width, value } = props;

  return (
    <g>
      <text
        x={x + width / 2}
        y={y - 5}
        fill="#000"
        textAnchor="middle"
        fontSize={12}
        fontWeight={600}
      >
        {value + "명"}
      </text>
    </g>
  );
};

export default function DoctorGraph({item}) {
  const [ctpv, setCtpv] = useState({});
  const [sgg, setSgg] = useState({});
  const [dong, setDong] = useState({});

  useEffect(() => {
    async function getData() {
      const ctpvData = {};
      await db.city.orderBy("DONG_CTPV_CD").each((item) => {
        ctpvData[item.DONG_CTPV_CD] = item.DONG_CTPV_NM;
      });
      setCtpv(ctpvData);
      const sggData = {};
      await db.sgg.orderBy("DONG_SGG_CD").each((item) => {
        sggData[item.DONG_SGG_CD] = item.DONG_SGG_NM;
      });
      setSgg(sggData);
      const dongData = {};
      await db.dong.orderBy("DONG_CD").each((item) => {
        dongData[item.DONG_CD] = item.DONG_NM;
      });
      setDong(dongData);
    };
    getData();
  },[]);

  const data = [
    {
      area: dong[item.DONG_CD],
      doctors: item.PTH_DR_CNT,
    },
    { 
      area: sgg[item.DONG_SGG_CD], 
      doctors: item.SGG_PTH_DR_CNT,
    },
    {
      area: ctpv[item.DONG_CTPV_CD],
      doctors: item.CTPV_PTH_DR_CNT,
    },
  ];
  return (
    <div style={{ width: "100%", height: 150 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{ top: 15, right: 10, left: 10, bottom: 5, }}
        >
          <Bar dataKey="doctors" fill="#D9D9D9" barSize={34} radius={[8, 8, 0, 0]}>
            <LabelList dataKey="doctors" content={renderCustomizedLabel} />
          </Bar>
          <XAxis
            dataKey="area"
            fontSize={12}
            stroke="#929292"
            fontWeight={600}
            tickLine={false}
            tick={{ fill: "#000000" }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
