import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Checkbox, Label } from "flowbite-react";
import { getToken } from "../../login/Token";
import { ModalInner } from "../../login/components/AgreeModal";
// import ScheduleContent from "./ScheduleContent";
import gcss_api from "../../api/api";
import axios from "axios";
import MemoItem from "./MemoItem";
import { db } from "../../db";
import { useLocation, useNavigate } from "react-router-dom";

import ReactDatePicker from "react-datepicker"; // 추가!
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from "date-fns/locale";
import { ymd_ago } from "../../common/PageTop";

const MemoModal = ({ memoHideHandler, hospital, memoPopStatus }) => {
  const [sales, setSales] = useState();
  const [memo, setMemo] = useState("");
  const [showFlag, setShowFlag] = useState(true);
  const token = getToken();
  const [ctpv, setCtpv] = useState({});
  const [sgg, setSgg] = useState({});
  const [dong, setDong] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const [scheduleList, setScheduleList] = useState();

  const [memoShowStatus, setMemoShowStatus] = useState(false);

  const statusTabMemoHandler = () => setMemoShowStatus(false);
  const statusTabScheduleHandler = () => setMemoShowStatus(true);

  const datepickerRef = useRef(null);
  const [selectedDateStart, setSelectedDateStart] = useState(new Date()); // 현재 날짜를 디폴트 값으로!
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date()); // 현재 날짜를 디폴트 값으로!

  const minTime = new Date();
  minTime.setHours(9, 0); // 예약 일시 09시부터~
  const minTime2 = new Date();
  minTime2.setHours(10, 0); // 예약 일시 09시부터~

  const [formattedTimeStart, setFormattedTimeStart] = useState(minTime);
  const [formattedTimeEnd, setFormattedTimeEnd] = useState(minTime2);

  const maxTime = new Date();
  maxTime.setHours(23, 59); // ~ 24시까지

  const [scheduleTitle, setScheduleTitle] = useState('');
  const [scheduleContent, setScheduleContent] = useState('');

  const handleTimeStartChange = (selectedTime) => {
    const hours = selectedTime.getHours();
    const afterHours = selectedTime.getHours()+1;
    // if(selectedTime.getHours() > 22){
    //   afterHours
    // }
    const minutes = selectedTime.getMinutes();
    const fTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;// 출력: "12:00"

    const afterTime =  `${afterHours}:${minutes < 10 ? '0' : ''}${minutes}`;// 출력: "12:00"
    

    console.log('selecttime : '+selectedTime.getHours());

    const timeStringToDate = (timeString) => {
      const today = new Date();
      const [hours, minutes] = timeString.split(':').map(Number);
      const dateWithTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hours, minutes);
      return dateWithTime;
    };
    const st = timeStringToDate(fTime); // timepicker 박스에 표현해줄 변수
    const afterSt = timeStringToDate(afterTime);
    setFormattedTimeStart(st)
    setFormattedTimeEnd(afterSt)
  };

  const handleTimeEndChange = (selectedTime) => {
    const hours = selectedTime.getHours();
    const minutes = selectedTime.getMinutes();
    const fTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;// 출력: "12:00"

    const timeStringToDate = (timeString) => {
      const today = new Date();
      const [hours, minutes] = timeString.split(':').map(Number);
      const dateWithTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hours, minutes);
      return dateWithTime;
    };
    const st = timeStringToDate(fTime); // timepicker 박스에 표현해줄 변수
    setFormattedTimeEnd(st)
    console.log("st", st); //Wed Feb 21 2024 12:30:00 GMT+0900 

    console.log("selectedTime:", selectedTime); // "12:00"
  };



  const getSales = () => {
    const option = {
      headers: { 'Authorization': token, 'Content-Type': 'application/json' },
      url: gcss_api("api_memo_hospital"),
    };

    const options = {
      ...option,
      method: "GET",
      url: option.url + "?comb_key=" + hospital.HOSPITAL_INFO.COMB_KEY,
    };
    axios(options).then((res) => {
      setSales(res.data);
    }).catch((err) => {
      if (err?.response?.status === 401) window.location.href = "/logout";
      console.log(err);
    });
  };

  const getScheduleList = () => {
    const option = {
      headers: { 'Authorization': token, 'Content-Type': 'application/json' },
      url: gcss_api("api_shedule_hospital_list"),
    };

    const options = {
      ...option,
      method: "GET",
      url: option.url + "?comb_key=" + hospital.HOSPITAL_INFO.COMB_KEY,
    };
    axios(options).then((res) => {
      setScheduleList(res.data);
    }).catch((err) => {
      if (err?.response?.status === 401) window.location.href = "/logout";
      console.log(err);
    });
  };

  const onWrite = () => {
    const option = {
      headers: { 'Authorization': token, 'Content-Type': 'application/json' },
      url: gcss_api("api_memo_hospital"),
    };
    if (memo === "") {
      alert("내용을 입력하고 등록해주세요.");
    } else {
      option.method = "POST";
      option.data = JSON.stringify({ "MEMO": memo, "COMB_KEY": hospital.HOSPITAL_INFO.COMB_KEY, "SHOW_FLAG": showFlag });
      axios(option).then(() => {
        getSales();
        setMemo("");
      }).catch((err) => {
        if (err?.response?.status === 401) window.location.href = "/logout";
        console.log(err);
        alert("영업메모 등록에 실패하였습니다.");
      });
    }
  };

  const onDeleteSchedule = (item) => {
    const option = {
      headers: { 'Authorization': token, 'Content-Type': 'application/json' },
      url: gcss_api("api_shedule_hospital_delete"),
    };
    if (window?.confirm("삭제하시겠습니까?")) {
      option.method = "DELETE";
      option.data = JSON.stringify({ "COMB_KEY": item.COMB_KEY, REG_SEQ: item.REG_SEQ });
      axios(option).then(() => {
        getScheduleList();
      }).catch((err) => {
        if (err?.response?.status === 401) window.location.href = "/logout";
        console.log(err);
        alert("영업일정 삭제에 실패하였습니다.");
      });
    }
  };

  const addSchedule = () => {
    const option = {
      headers: { 'Authorization': token, 'Content-Type': 'application/json' },
      url: gcss_api("api_shedule_hospital_add"),
    };

    option.method = "POST";
    option.data = JSON.stringify({
      "COMB_KEY": hospital.HOSPITAL_INFO.COMB_KEY,
      "RPRT_INST_NM": hospital?.HOSPITAL_INFO?.RPRT_INST_NM,
      "SCHDL_TTL_CN": scheduleTitle === "" ? "방문예정" : scheduleTitle,
      "SCHDL_BGNG_DT": convertYmd(selectedDateStart) + ' ' + convertHms(formattedTimeStart),
      "SCHDL_END_DT": convertYmd(selectedDateEnd) + ' ' + convertHms(formattedTimeEnd),
      "SCHDL_DTL_CN": scheduleContent,
      "PARENT_REG_SEQ": scheduleList.length
    });
    axios(option).then(() => {
      getScheduleList();
      clearSchedule();
    }).catch((err) => {
      if (err?.response?.status === 401) window.location.href = "/logout";
      console.log(err);
      console.log(option);
      alert("영업일정 등록에 실패하였습니다.");
    });

  };

  const btnClose = () => {
    if (location?.state?.memo) {
      memoHideHandler();
      navigate("/hospital_map", {
        state: {
          alarm: true,
        }
      });
    } else memoHideHandler();
  };

  const clearSchedule = () => {
    setScheduleTitle('');
    setScheduleContent('');
    setSelectedDateStart(new Date());
    setSelectedDateEnd(new Date());
    setFormattedTimeStart(minTime);
    setFormattedTimeEnd(minTime2);
  }

  const convertYmd = (date) => {

    const year = date.getFullYear(); // 2023
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 06
    const day = date.getDate().toString().padStart(2, '0'); // 18

    const dateString = year + '-' + month + '-' + day; // 2023-06-18

    return dateString;
  }

  const convertHms = (date) => {

    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    const dateString = (hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute) + ':' + (second < 10 ? '0' + second : second); // 2023-06-18

    return dateString;
  }

  const onDetail = () => {
    let to = "";
    switch (hospital.HOSPITAL_INFO.HOSPITAL_FLAG) {
      case "N": to = "/hospital_soon"; break;
      case "A": to = "/deal"; break;
      case "B": case "B-1": to = "/non_deal"; break;
    }
    if (to === "") return false;
    navigate(to, {
      state: {
        COMB_KEY: hospital.HOSPITAL_INFO.COMB_KEY,
        RFRNC_TBL_NM: hospital.HOSPITAL_INFO.RFRNC_TBL_NM,
        DONG_CD: hospital.HOSPITAL_INFO.DONG_CD,
        from: location,
        y: hospital.HOSPITAL_INFO.LNL_YCO,
        x: hospital.HOSPITAL_INFO.LNL_XCO,
      }
    });
  }

  function convertFlagToImage() {
    let icon = ""
    switch (hospital.HOSPITAL_INFO.HOSPITAL_FLAG) {
      case "N": icon = "assets/img/hospital_map/iconalram-icon1-check.svg"; break;
      case "A": icon = "assets/img/hospital_map/iconalram-icon3-check.svg";
        break;
      case "B": case "B-1": icon = "assets/img/hospital_map/iconalram-icon2-check.svg";
        break;
    }
    return icon;
  }

  function convertStringToKoDay(date) {
    //ex) convertStringToKoDay('2023-02-07')
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const dayOfWeek = week[new Date(date).getDay()];
    return dayOfWeek;
  };

  useEffect(() => {
    setMemoShowStatus(memoPopStatus);
    getSales();
    getScheduleList();

    async function getData() {
      const ctpvData = {};
      await db.city.orderBy("DONG_CTPV_CD").each((item) => {
        ctpvData[item.DONG_CTPV_CD] = item.DONG_CTPV_NM;
      });
      setCtpv(ctpvData);
      const sggData = {};
      await db.sgg.orderBy("DONG_SGG_CD").each((item) => {
        sggData[item.DONG_SGG_CD] = item.DONG_SGG_NM;
      });
      setSgg(sggData);
      const dongData = {};
      await db.dong.orderBy("DONG_CD").each((item) => {
        dongData[item.DONG_CD] = item.DONG_NM;
      });
      setDong(dongData);
    };
    getData();
  }, []);

  return (
    <>
      <MemoModalWrap className="modal-memo">
        <div className="fixed inset-0 bg-black/25 backdrop-blur-sm">
          <div className=" h-max mx-4 my-8 z-20 relative">
            <ModalInner className="w-full h-max bg-white  rounded-lg">
              <MemoModalTop className="flex justify-between items-center px-4 py-2">
                {/* <h3 className="text-black text-lg font-semibold ">
                  {hospital?.HOSPITAL_INFO?.RPRT_INST_NM}
                </h3>
                <button onClick={btnClose}>
                  <img src="assets/img/hospital_map/icon-close-gray.svg" style={{ width: "32px" }} />
                </button> */}

                <TitleTab>
                  <button onClick={statusTabMemoHandler}>
                    <h3 className="text-lg font-semibold" style={{ color: memoShowStatus === false ? "#072C49" : "#9A98A2" }}>
                      영업 메모
                    </h3>
                  </button>

                  <button onClick={statusTabScheduleHandler}>
                    <h3 className="text-lg font-semibold" style={{ marginLeft: "30px", color: memoShowStatus === true ? "#072C49" : "#9A98A2" }}>
                      영업 일정
                    </h3>
                  </button>

                </TitleTab>


                <button onClick={btnClose}>
                  <img src="assets/img/hospital_map/icon-close-gray.svg" style={{ width: "32px" }} alt="close" />
                </button>

              </MemoModalTop>
              <LineTab>
                {memoShowStatus ? <TabIndicator2 /> : <TabIndicator1 />}
                {memoShowStatus ? <TabIndicator1 /> : <TabIndicator2 />}
                <TabIndicator3></TabIndicator3>
              </LineTab>
              <AreaLocation>
                <span className="text-[13px] font-medium text-nowrap">
                  {ctpv[hospital?.HOSPITAL_INFO?.DONG_CTPV_CD]} {sgg[hospital?.HOSPITAL_INFO?.DONG_SGG_CD]}
                </span>
                <img src="assets/img/hospital_map/icon-location-arrow.svg" alt="" />
                <span className="text-[13px] font-medium text-nowrap">{dong[hospital?.HOSPITAL_INFO?.DONG_CD]}</span>
                <img src="assets/img/hospital_map/icon-location-arrow.svg" alt="" />
                <img src={convertFlagToImage(hospital)} alt="" style={{ width: "15px", height: "20px" }} />

                <button onClick={() => onDetail()}>
                  <strong className="text-[13px]  text-semibold">
                    {hospital?.HOSPITAL_INFO?.RPRT_INST_NM}
                  </strong>
                </button>
              </AreaLocation>

              {memoShowStatus === false &&
                <MemoModalBottom>
                  <textarea
                    rows="5"
                    className="w-full rounded grow shrink basis-0 text-sm font-normal"
                    placeholder="유용한 정보 입력하고 공유 가능&#10;'비공개'체크시 나만 볼 수 있는 개인메모 가능&#10;■주의■ 거래처 관련자에 대한 내밀 또는 개인 정보 등을 입력시 명예훼손 문제가 발생될 수 있음"
                    onFocus={(e) => e.target.placeholder = " "}
                    onBlur={(e) => e.target.placeholder = "유용한 정보 입력하고 공유 가능\n'비공개'체크시 나만 볼 수 있는 개인메모 가능\n■주의■ 거래처 관련자에 대한 내밀 또는 개인 정보 등을 입력시 명예훼손 문제가 발생될 수 있음"}
                    value={memo}
                    onChange={(e) => setMemo(e.target.value)}
                  />
                  <div className="flex items-center gap-3">
                    <Checkbox onChange={() => setShowFlag(prev => !prev)} />
                    <Label htmlFor="agree1"> 비공개 (개인메모)</Label>
                  </div>
                  <div className="my-2 py-1 flex justify-end gap-2">
                    <button className="py-2 w-16 bg-gray-200 rounded text-neutral-400 text-normal font-semibold" onClick={() => setMemo("")}>취소</button>
                    <button className="py-2 w-16 bg-sky-500 rounded text-white text-normal font-semibold" onClick={onWrite}>등록</button>
                  </div>

                  <MemoContent>
                    {sales?.map((item) => (
                      <MemoItem key={item.MEMO_SEQ} item={item} getSales={getSales} />
                    ))}
                  </MemoContent>
                </MemoModalBottom>
              }

              {memoShowStatus &&
                <ScheduleContentWrap className="px-4">

                  {true && <ScheduleDateTimePicker className="mt-4">
                    <img src="assets/img/hospital_map/icon-clock-gray.svg" alt="" />

                    <div>
                      <div>
                        <ReactDatePicker
                          locale={ko}
                          ref={datepickerRef}
                          className="datepicker"
                          shouldCloseOnSelect
                          dateFormat="yyyy-MM-dd"
                          placeholderText="선택하세요"
                          id="datepicker1"
                          selected={selectedDateStart} // 선택된 날짜를 ReactDatePicker에 전달
                          onChange={(date) => setSelectedDateStart(date)}
                          minDate={new Date()} // 오늘 이전의 날짜 선택 불가능하게 설정
                        />
                      </div>
                      <div>
                        <ReactDatePicker
                          locale={ko}
                          ref={datepickerRef}
                          className="datepicker"
                          shouldCloseOnSelect
                          placeholderText="선택하세요"
                          id="datepicker2"
                          selected={formattedTimeStart}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="시간"
                          dateFormat="HH:mm"
                          minTime={minTime} // 09:00부터 선택 가능하도록 설정
                          maxTime={maxTime} // 24:00까지 선택 가능하도록 설정
                          onChange={(selectedTime) => handleTimeStartChange(selectedTime)}
                        />
                      </div>
                    </div>


                    <img src="/assets/img/hospital_map/icon-datepicker-arrow.svg" alt="" style={{ marginRight: "30px", marginLeft: "30px" }} />


                    <img src="assets/img/hospital_map/icon-clock-gray.svg" alt="" />

                    <div>
                      <div>
                        <ReactDatePicker
                          locale={ko}
                          ref={datepickerRef}
                          className="datepicker"
                          shouldCloseOnSelect
                          dateFormat="yyyy-MM-dd"
                          placeholderText="선택하세요"
                          id="datepicker1"
                          selected={selectedDateStart} // 선택된 날짜를 ReactDatePicker에 전달
                          onChange={(date) => setSelectedDateStart(date)}
                          minDate={new Date()} // 오늘 이전의 날짜 선택 불가능하게 설정
                        />

                        {/* <ReactDatePicker
                          locale={ko}
                          ref={datepickerRef}
                          className="datepicker"
                          shouldCloseOnSelect
                          dateFormat="yyyy-MM-dd"
                          placeholderText="선택하세요"
                          id="datepicker1"
                          selected={selectedDateEnd} // 선택된 날짜를 ReactDatePicker에 전달
                          onChange={(date) => setSelectedDateEnd(date)}
                          minDate={new Date()} // 오늘 이전의 날짜 선택 불가능하게 설정
                        /> */}
                      </div>
                      <div >
                        <ReactDatePicker
                          locale={ko}
                          ref={datepickerRef}
                          className="datepicker"
                          shouldCloseOnSelect
                          placeholderText="선택하세요"
                          id="datepicker2"
                          selected={formattedTimeEnd}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="시간"
                          dateFormat="HH:mm"
                          minTime={minTime} // 09:00부터 선택 가능하도록 설정
                          maxTime={maxTime} // 24:00까지 선택 가능하도록 설정
                          onChange={(selectedTime) => handleTimeEndChange(selectedTime)}
                        />
                      </div>
                    </div>

                  </ScheduleDateTimePicker>
                  }
                  <ScheduleFrame>
                    <input
                      type="text"
                      className="w-full mt-2 text-sm  rounded"
                      placeholder='제목을 입력하세요 (미입력시 "방문예정"으로 저장)'
                      value={scheduleTitle}
                      onChange={(value) => setScheduleTitle(value.target.value)}
                    />
                  </ScheduleFrame>

                  <textarea
                    name=""
                    id=""
                    row="7"
                    placeholder="필요시 추가 내용을 입력할 수 있습니다."
                    className="w-full mt-2 text-sm  rounded"
                    value={scheduleContent}
                    onChange={(value) => setScheduleContent(value.target.value)}
                  ></textarea>
                  <div className="mt-2 mb-5 flex justify-end gap-2">
                    <button className="py-2 w-16 bg-gray-200 rounded text-neutral-400 text-normal font-semibold" onClick={() => clearSchedule()}>
                      취소
                    </button>
                    <button className="py-2 w-16 bg-sky-500 rounded text-white text-normal font-semibold" onClick={() => addSchedule()}>
                      등록
                    </button>
                  </div>

                  <ScheduleList>
                    {scheduleList?.map((item) => (
                      <div className="flex" key={item.REG_SEQ}>
                        <div className="w-1/4  border-black text-center py-2 px-2">
                          <p className="block text-black text-xs">{item.SCHDL_BGNG_DT.substr(0, 4)}년</p>
                          <strong className="text-black font-semibold text-normal">
                            {item.SCHDL_BGNG_DT.substr(5, 2)}월 {item.SCHDL_BGNG_DT.substr(8, 2)}일
                          </strong>
                          <p className="block text-black text-xs">{convertStringToKoDay(item.SCHDL_BGNG_DT.substr(0, 10))}</p>
                          <span className="px-1.5 py-0.5 bg-green-700 rounded-2xl text-white text-xs">
                            {ymd_ago(item.SCHDL_BGNG_DT.replaceAll("-", "").substr(0, 8))}
                          </span>
                        </div>
                        <ScheduleInfo className="w-3/4 flex flex-col my-2 px-2 gap-1 mb-1">
                          <div className="flex justify-around items-center">
                            <strong className="text-black font-semibold text-normal">
                              {item.SCHDL_TTL_CN}
                            </strong>
                            <p className="flex items-center gap-2 ml-auto">
                              {/* <button className="text-xs font-semibold">수정</button>
                              <SpaceBar /> */}
                              <button className="text-xs font-semibold" onClick={() => onDeleteSchedule(item)}>삭제</button>
                            </p>
                          </div>
                          <p className=" text-xs text-neutral-500 flex items-center gap-1">
                            <img src="assets/img/hospital_map/icon-clock-gray.svg" alt="" />
                            {item.SCHDL_BGNG_DT.substr(11, 5)} - {item.SCHDL_END_DT.substr(11, 5)}
                          </p>
                          <p className="text-xs">
                            {item.SCHDL_DTL_CN}
                          </p>
                        </ScheduleInfo>
                      </div>
                    ))}


                  </ScheduleList>
                </ScheduleContentWrap>
              }


            </ModalInner>
          </div>
        </div>
      </MemoModalWrap>
    </>
  );
};

const LineTab = styled.div`
  // border-bottom: 1px solid #e4e4e4;
  display : flex;
  align-items:flex-end;
`;

const TabIndicator1 = styled.div`

  width : 98px;
  height : 4px;
  background-color: #072C49;
`;
const TabIndicator2 = styled.div`
  width : 98px;
  height : 2px;
  background-color: #e4e4e4;
`;
const TabIndicator3 = styled.div`
  flex : 1 ;
  height : 2px;
  background-color: #e4e4e4;
`;

const MemoModalWrap = styled.div`
  &.modal-memo {
    position: fixed;
    z-index: 11;
  }
`;

const MemoModalTop = styled.div`
`;

const MemoModalBottom = styled.div`
  padding: 10px 1rem 30px;
  textarea {
    margin: 1rem 0 0;
    border: 1px solid #6da0d2;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  }
  textarea::placeholder { 
    color: #cccccc; 
  }
`;

const TitleTab = styled.div`
  display: flex;
`;

const AreaLocation = styled.div`

  margin: 1rem 1rem 0;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  span {
    font-weight: 500;
  }
  strong {
    font-weight: 700;
  }
`;

const MemoContent = styled.div`
  max-height: calc(100vh - 420px);
  overflow-y: scroll;
`;

const ScheduleContent = styled.div`
  max-height: 500px;
  overflow-y: scroll;
`;

// schedule
const ScheduleContentWrap = styled.div`
  padding: 20px 1rem 30px;
  textarea {
    min-height: 80px;
    border: 1px solid #6da0d2;
  }
  .datepicker{
    text-align: center;
    width: 120px;
    border-width: 1px;
    border-radius: 4px;
    margin-bottom: 2px;
  }
}
`;

const ScheduleFrame = styled.div`
  input {
    min-height: 40px;
    border: 1px solid #6da0d2;
  }
}
`;

const ScheduleDateTimePicker = styled.div`
  width: 100%;
  display : flex;
  align-items:center;
  justify-content: center;

  img {
    
  }
`;

export const ScheduleList = styled.div`

  max-height: calc(100vh - 500px);
  overflow-y: scroll;
  > div {
    border-top: 1px solid #e4e4e4;
  }
`;

const ScheduleInfo = styled.div`
  border-left: 1px solid #e4e4e4;
`;

const SpaceBar = styled.span`
  width: 1px;
  height: 11px;
  background: #e4e4e4;
`;

export default MemoModal;
