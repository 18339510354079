import React from "react";
import styled from "styled-components";

function ScheduleItem() {
  return (
    <ScheduleItemWrap className="px-4">
      {/* 스케줄1 */}
      <div className="flex">
        <div className="w-1/4 flex flex-col justify-center items-center text-center py-4 px-2">
          <p className="block text-black text-xs">2024년</p>
          <strong className="text-black font-semibold text-normal">
            5월 23일
          </strong>
          <p className="block text-black text-xs">목</p>
          <span className=" w-14 px-1.5 py-0.5 bg-green-700 rounded-2xl text-white text-xs">
            20일 후
          </span>
        </div>
        <ScheduleInfo className="w-3/4 px-2 my-2">
          <span className="inline-block align-middle py-0.5 px-1 mb-1 bg-zinc-100 text-neutral-500 text-xs">
            장그린내과
          </span>
          <strong className="block mb-1 text-black font-semibold text-normal">
            김지씨 원장님 미팅
          </strong>
          <p className="mb-1 text-xs text-neutral-500 flex items-center gap-1">
            <img src="assets/img/hospital_map/icon-clock-gray.svg" alt="" />
            오후 5:00 – 오후 6: 00
          </p>
          <p className="text-xs">
            브로셔 가지고 가기로 함 브로셔 가지고 가기로 함 브로셔 가지고 가기로
            함
          </p>
        </ScheduleInfo>
      </div>
      {/* 스케줄2 */}
      <div className="flex">
        <div className="w-1/4 flex flex-col justify-center items-center text-center py-4 px-2">
          <p className="block text-black text-xs">2024년</p>
          <strong className="text-black font-semibold text-normal">
            5월 23일
          </strong>
          <p className="block text-black text-xs">목</p>
          <span className=" w-14 px-1.5 py-0.5 bg-gray-400 rounded-2xl text-white text-xs">
            20일 후
          </span>
        </div>
        <ScheduleInfo className="w-3/4 px-2 my-2">
          <span className="inline-block align-middle py-0.5 px-1 mb-1 bg-zinc-100 text-neutral-500 text-xs">
            장그린내과
          </span>
          <strong className="block mb-1 text-black font-semibold text-normal">
            김지씨 원장님 미팅
          </strong>
          <p className="mb-1 text-xs text-neutral-500 flex items-center gap-1">
            <img src="assets/img/hospital_map/icon-clock-gray.svg" alt="" />
            오후 5:00 – 오후 6: 00
          </p>
          <p className="text-xs">
            브로셔 가지고 가기로 함 브로셔 가지고 가기로 함 브로셔 가지고 가기로
            함
          </p>
        </ScheduleInfo>
      </div>
    </ScheduleItemWrap>
  );
}

const ScheduleItemWrap = styled.div`
  > div {
    border-bottom: 1px solid #e4e4e4;
  }
`;

const ScheduleInfo = styled.div`
  border-left: 1px solid #e4e4e4;
`;

const SpaceBar = styled.span`
  width: 1px;
  height: 11px;
  background: #e4e4e4;
`;

export default ScheduleItem;
