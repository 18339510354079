import React from "react";
import styled from "styled-components";
import { ymd_ago, ymd_format } from "../../common/PageTop";
import { Link, useLocation } from "react-router-dom";
import { useNavermaps } from "react-naver-maps";

function AreaInfo({inVisible, item}) {
  if(!inVisible) return null;
  return (
    <AreaInfoWrap>
      <AreaInfoBar className="bg-zinc-500 rounded-full" />
      <ContentBox className="">
      {item?.map((data, idx) => (
        <AreaContent key={idx} item={data.item}/>
      ))}
      </ContentBox>
    </AreaInfoWrap>
  );
}

export const AreaContent = ({item}) => {
  const location = useLocation();
  const navermaps = useNavermaps();
  let color = "", name = "", size = "", ago = "", ymd = "", addr = "", detailUrl = "", center = "";
  if(item.NEW_SHOP_INFO) {
    color = "blue";
    name = item.NEW_SHOP_INFO.BDST_NM;
    size = "총 " + item.NEW_SHOP_INFO.BDST_TFAR.toLocaleString() + "㎡";
    ago = ymd_ago(item.NEW_SHOP_INFO.USE_APRV_YMD);
    ymd = ymd_format(item.NEW_SHOP_INFO.USE_APRV_YMD, true) + " 사용승인";
    addr = item.NEW_SHOP_INFO.ADDR_CN;
    center = new navermaps.LatLng(item.NEW_SHOP_INFO.LNL_YCO, item.NEW_SHOP_INFO.LNL_XCO);
    detailUrl = "/shop_detail";
  } else if(item.NEW_APARTMENT_INFO) {
    color = "red";
    name = item.NEW_APARTMENT_INFO.APT_NM;
    size = item.NEW_APARTMENT_INFO.HH_CNT.toLocaleString() + "세대";
    ago = ymd_ago(item.NEW_APARTMENT_INFO.MOVIN_YM);
    ymd = ymd_format(item.NEW_APARTMENT_INFO.MOVIN_YM, true).replace("0일", "") + "입주예정";
    addr = item.NEW_APARTMENT_INFO.ADDR_CN;
    center = new navermaps.LatLng(item.NEW_APARTMENT_INFO.LNL_YCO, item.NEW_APARTMENT_INFO.LNL_XCO);
    detailUrl = "/apt_detail";
  }
  return (
    <AreaTextWrap>
      <Link to={detailUrl} state={{item: item, from: location, center: center}}>
        <h2>{name} <span>({size})</span></h2>
      </Link>
      <div>
        <span className={color}>{ago}</span> <p>{ymd}</p>
      </div>
      <div>
        <img src="assets/img/hospital_map/icon-map.svg" alt="지도아이콘" />
        <p>{addr}</p>
      </div>
    </AreaTextWrap>
  );
}

const AreaInfoWrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px -4px 7px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 1rem 0;
`;

const ContentBox = styled.div`
  max-height: 250px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const AreaInfoBar = styled.div`
  width: 134px;
  height: 5px;
  margin: 0 auto 5px;
`;

const AreaTextWrap = styled.div`
  padding: 0 1rem;
  h2 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    margin: 1rem 0 0.5rem;
    span {
      font-size: 13px;
    }
  }
  div {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
    span {
      display: flex;
      padding: 3px 6px;
      font-size: 13px;
      border-radius: 20px;
      &.red {
        color: #d20000;
        border: 1px solid #d78c8c;
        background: #fed5d5;
      }
      &.blue {
        color: #002b49;
        border: 1px solid #002b49;
        background: #eef4f8;
      }
    }
    p {
      color: #000;
      font-size: 13px;
    }
  }
`;
export default AreaInfo;
