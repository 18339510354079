import axios from "axios";
import { getToken } from "../login/Token";
import gcss_api from "../api/api";

const filter = {
    "department": {},
    "hospital-flag": [
        { code: 'A', name: '거래처', isActive: true },
        { code: 'B', name: '비거래처', isActive: true },
        { code: 'N', name: '개원예정', isActive: true },
    ],
};

export const setFilterData = (filterName, filterData) => {
    const filterLabel = getFilter();
    let filterCodes = Array();
    filterData?.map((item) => {
        filterLabel[filterName]?.map((data) => {
            if (data.code === item) filterCodes.push(item);
        });
    });
    sessionStorage.setItem('filter-' + filterName, JSON.stringify(filterCodes));
    if (filterLabel) {
        filterLabel[filterName].map((item) => {
            if (filterData.some(data => item.code === data)) item.isActive = true;
            else item.isActive = false;
        });
        sessionStorage.setItem('filter-label', JSON.stringify(filterLabel));
    } else {
        setTimeout(() => {
            setFilterData(filterName, filterCodes);
        }, 1000);
    }
}

export const setFilterDataNew = (filterName, filterData) => {
    const filter_label = JSON.parse(sessionStorage.getItem('filter-label'), true);
    if (!filter_label) {
        const token = getToken();
        const option = {
            method: 'GET',
            headers: { 'Authorization': token },
            url: gcss_api("api_code_departments"),
        }
        axios(option).then((res) => {
            filter.department = res.data.map((item) => {
                return { code: item.TRT_SBJCT_CD, name: item.TRT_SBJCT_NM, isActive: true };
            });
            sessionStorage.setItem('filter-label', JSON.stringify(filter));
            let filterCodes = Array();
            filterData?.map((item) => {
                filter[filterName]?.map((data) => {
                    if (data.code === item) filterCodes.push(item);
                });
            });
            sessionStorage.setItem('filter-' + filterName, JSON.stringify(filterCodes));
            if (filter) {
                filter[filterName].map((item) => {
                    if (filterData.some(data => item.code === data)) item.isActive = true;
                    else item.isActive = false;
                });
                sessionStorage.setItem('filter-label', JSON.stringify(filter));
            } else {
                setTimeout(() => {
                    setFilterData(filterName, filterCodes);
                }, 1000);
            }
        }).catch((err) => {
            if (err?.response?.status === 401) window.location.href = "/logout";
            console.log(err); //에러가 안나와서 어떻게 나올지 감이 안잡힘
        });
    } else {
        let filterCodes = Array();
        filterData?.map((item) => {
            filter_label[filterName]?.map((data) => {
                if (data.code === item) filterCodes.push(item);
            });
        });
        sessionStorage.setItem('filter-' + filterName, JSON.stringify(filterCodes));
        if (filter_label) {
            filter_label[filterName].map((item) => {
                if (filterData.some(data => item.code === data)) item.isActive = true;
                else item.isActive = false;
            });
            sessionStorage.setItem('filter-label', JSON.stringify(filter_label));
        } else {
            setTimeout(() => {
                setFilterData(filterName, filterCodes);
            }, 1000);
        }
    }
}

export const getFilterData = (filterName) => {
    return JSON.parse(sessionStorage.getItem('filter-' + filterName), true);
}

export const getFilter = () => {
    const filter_label = JSON.parse(sessionStorage.getItem('filter-label'), true);
    if (!filter_label) {
        const token = getToken();
        const option = {
            method: 'GET',
            headers: { 'Authorization': token },
            url: gcss_api("api_code_departments"),
        }
        axios(option).then((res) => {
            filter.department = res.data.map((item) => {
                return { code: item.TRT_SBJCT_CD, name: item.TRT_SBJCT_NM, isActive: true };
            });
            sessionStorage.setItem('filter-label', JSON.stringify(filter));
            return filter;
        }).catch((err) => {
            if (err?.response?.status === 401) window.location.href = "/logout";
            console.log(err); //에러가 안나와서 어떻게 나올지 감이 안잡힘
        });
    } else return filter_label;
}

export const setFilter = (filterName, filterItem) => {
    const filterLabel = getFilter();
    filterLabel[filterName].map((item) => {
        if (item.code === filterItem) item.isActive = !item.isActive;
    })
    sessionStorage.setItem('filter-label', JSON.stringify(filterLabel));
}

export const setFilterAll = (filterName, data) => {
    const filterLabel = getFilter();
    if (filterLabel) {
        filterLabel[filterName].map((item) => {
            item.isActive = data;
        });
        sessionStorage.setItem('filter-label', JSON.stringify(filterLabel));
    }
}

export const setFilterDataAll = (filterName) => {
    const filterLabel = getFilter();
    if (filterLabel) {
        let filterCodes = Array();
        filterLabel[filterName].map((item) => {
            item.isActive = true;
            filterCodes.push(item.code);
        });
        sessionStorage.setItem('filter-label', JSON.stringify(filterLabel));
        sessionStorage.setItem('filter-' + filterName, JSON.stringify(filterCodes));
    } else {
        setTimeout(() => {
            setFilterDataAll(filterName);
        }, 1000);
    }
}