import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import InfoCard from "../../common/InfoCard";
import CustomTable from "../../common/CustomTable";
import KeyWordBadge from "../components/KeyWordBadge";
import DayBadgeSquare from "../components/DayBadgeSquare";
import CustomBarGraph from "./CustomBarGraph";
import CustomBarGraphPerson from "./CustomBarGraphPerson";

import InfoCardTab from "../../common/InfoCardTab";

import { getFilterData } from "../../hospital_map/Filter";
import { ymd_ago, ymd_format } from "../../common/PageTop";
import { Modal } from "flowbite-react";

function HospitalInfoCard({infoData}) {
  const location = useLocation();
  const pathname = location?.pathname;
  const department_code = getFilterData("label").department;
  const operationTime = (key, item) => {
    const content = item ? item : "";
    if(content === "") return null;
    switch(key) {
      case "SUNDAY": return "일 " + content;
      case "MONDAY": return "월 " + content;
      case "TUESDAY": return "화 " + content;
      case "WEDNESDAY": return "수 " + content;
      case "THURSDAY": return "목 " + content;
      case "FRIDAY": return "금 " + content;
      case "SATURDAY": return "토 " + content;
    }
  }
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  const ex_list = [];

  const [openModal, setOpenModal] = useState(false);

  const onModalOpinionHandler = () => {
    setOpenModal(true);
  };
  const offModalOpinionHandler = () => {
    setOpenModal(false);
  };

  return (
    <div className="px-4">
      {pathname !== "/hospital_soon" && <InfoCard title={"병원정보"}>
        <CustomTable data={infoData?.HOSPITAL_DETAIL_INFO1 ? infoData.HOSPITAL_DETAIL_INFO1 : infoData.HOSPITAL_DETAIL_INFO2} />
        <div className="my-2 flex flex-wrap gap-1">
          {infoData?.DEPARTMENT_LIST?.map((item) => (
            <TreatLable className={item.isActive ? "active" : ""} key={item}>
              {department_code?.filter((filter) => filter.code === item)[0]?.name}
            </TreatLable>
          ))}
        </div>
        <HospitalKeyWord>
          {infoData?.DETAIL_INFO1?.KEYWORD_LIST?.length > 0 && (
          <div className="flex items-center gap-2">
            <KeyWordBadge title={"키워드"} />
            <div>
              <p className="text-xs">
                {infoData.DETAIL_INFO1.KEYWORD_LIST.map((item, index) => {
                  if(index !== 0) return " | " + item;
                  return item;
                })}
              </p>
            </div>
          </div>
          )}

          {infoData?.DETAIL_INFO1?.WORKING_TIME?.length > 0 && (
          <div className="flex items-center gap-2">
            <KeyWordBadge title={"진료시간"} />
            <div>
              <p className="text-xs">{infoData.DETAIL_INFO1.WORKING_TIME.map((item) => {
                console.log(item);
                return "처리필요";
              })}</p>
            </div>
          </div>
          )}
          
          {infoData?.DETAIL_INFO1?.CONTRACT_INFO?.length > 0 && (
          <div className="flex items-baseline gap-2 relative w-full">
            <KeyWordBadge title={"계약"} />
            <div>
              {infoData.DETAIL_INFO1.CONTRACT_INFO.map((item) => (
                <p className="text-xs mb-1" key={item.AFFL_NM}>
                  {item.AFFL_NM} {ymd_format(item.CTRT_YMD)} <DayBadgeSquare title={ymd_ago(item.CTRT_YMD)} />
                </p>
              ))}
            </div>
            <button className="absolute top-0 right-0" onClick={() => onModalOpinionHandler()}>
              <img src="assets/img/hospital_soon/icon-question.svg" alt="" />
            </button>
          </div>
          )}
        </HospitalKeyWord>
      </InfoCard>}
      <Modal show={openModal} onClose={() => setOpenModal(false)} style={{display: "flex", alignItems: "center"}}>
        <ModalTop className="flex justify-between items-center px-4 py-2">
          <h3 className="text-black text-lg font-semibold ">GC 가족사 계약 정보</h3>
          <button onClick={offModalOpinionHandler}>
            <img src="assets/img/hospital_map/icon-close-gray.svg" style={{ width: "32px" }} />
          </button>
        </ModalTop>
        <Modal.Body className="px-6">
          <TootipText className="list-disc">
            <li>해당 병원과 거래하고 있는 가족사의 회사명과 거래일자 정보를 제공합니다.<br/>(일부 시스템에 거래일자 정보가 없을 경우, 표시되지 않습니다.)</li>
            <li>Cell : 거래 시작일<br/>웰빙 : 거래 시작일<br/>유비케어 : 시스템 병원 등록일<br/>녹십자제약 : 시스템 병원 등록일</li>
          </TootipText>
        </Modal.Body>
      </Modal>

      {/* 비거래 페이지에서만  InfoCard (예상매출컨텐츠)  */}
      {/* pathname === "/non_deal" ? (
        <InfoCard title={"예상 매출 규모 및 추천 아이템"}>
          <div className="flext items-center gap-1">
            <strong className="text-black text-base font-semibold">
              예상 매출 등급 :
              <span className="text-red-600 text-base font-semibold">
                A 등급
              </span>
            </strong>
            <span className="text-neutral-500 text-xs font-medium ml-1">
              (거래 매출 기준 산출)  
            </span>
          </div>
          <NonDealAccoridon />
        </InfoCard>
      ) : null */}

      {/* 비거래 페이지에서만  InfoCard (추가 가능 매출컨텐츠)  */}
      {/* pathname === "/deal" ? (
        <InfoCard title={"추가 가능 매출 및 추천 아이템"}>
          <p className="text-black text-base font-medium mb-2">
            [추가 가능 매출 분석]
          </p>
          <div className="flex gap-1">
            <Sales>
              <div className="mb-4">
                <strong>최대</strong>
                <p className="flex justify-center items-center">
                  <strong>30%</strong> <span>추가상승여력</span>
                </p>
              </div>
              <strong className="block text-center text-sky-500 text-xs">
                최소
              </strong>
              -
            </Sales>
            <SalesGraph>
              <span
                className="percent-label"
                style={{ left: "24px", bottom: "45px" }}
              >
                70%
              </span>
              <span
                className="percent-label"
                style={{ left: "89px", bottom: "47px" }}
              >
                60%
              </span>
              <span
                className="percent-label"
                style={{ right: "21px", bottom: "97px" }}
              >
                최대
              </span>
              <AverageBarline style={{ top: "23px" }} />
              <AverageBarline style={{ top: "58px" }} />
              <div style={{ width: "100%", height: 110 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart width={120} height={70} data={sampleGraphData}>
                    <Bar dataKey="profits" fill="#D9D9D9" />
                    <XAxis dataKey="name" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </SalesGraph>
          </div>
          <NonDealAccoridon />
        </InfoCard>
      ) : null */}

      {pathname !== "/hospital_soon" && (
        <InfoCardTab>
          <div tab1content="true" className="px-4">
            <div className="mb-2">
              <p className="text-black text-base font-medium mb-2">
                [진료과목별 전문의 정보]
              </p>
              <table className="gray-th-table">
                <thead>
                  <tr>
                    <th>진료과목명</th>
                    <th>전문의 수</th>
                  </tr>
                </thead>
                <tbody>
                  {infoData?.DETAIL_INFO2?.DOCTOR_LIST?.length > 0 ? 
                    infoData.DETAIL_INFO2.DOCTOR_LIST.map((item) => {
                      if(item.TRT_SBJCT_SPLT_CNT === 0) return null;
                      else return (
                    <tr key={item.TRT_SBJCT_CD}>
                      <td>{department_code?.filter((filter) => filter.code === item.TRT_SBJCT_CD)[0]?.name}</td>
                      <td>{item.TRT_SBJCT_SPLT_CNT}</td>
                    </tr>
                  )}) : (
                    <tr>
                      <td colSpan={2}>정보 없음</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {infoData?.DETAIL_INFO2?.HIRA_COMMON_EQUIPMENT_LIST?.length > 0 && (
            <div className="mb-2">
              <p className="text-black text-base font-medium mb-2">
                [의료장비]
              </p>
              <table className="gray-th-table">
                <thead>
                  <tr>
                    <th>의료장비</th>
                    <th>장비 수</th>
                  </tr>
                </thead>
                <tbody>
                  {infoData.DETAIL_INFO2.HIRA_COMMON_EQUIPMENT_LIST.map((item) => {
                    const keys = Array();
                    Object.keys(item).map((key) => keys.push(key));
                    return (
                    <tr key={item[keys[0]]}>
                      <td>{item[keys[0]]}</td>
                      <td>{item[keys[1]]}</td>
                    </tr>
                  )})}
                </tbody>
              </table>
            </div>
            )}
            {infoData?.DETAIL_INFO2?.HIRA_SPECIAL_EQUIPMENT_LIST?.length > 0 && (
            <div className="mb-2">
              <p className="text-black text-base font-medium mb-2">
                [특수진료장비]
              </p>
              <table className="gray-th-table">
                <thead>
                  <tr>
                    <th>특수진료장비</th>
                    <th>장비 수</th>
                  </tr>
                </thead>
                <tbody>
                  {infoData.DETAIL_INFO2.HIRA_SPECIAL_EQUIPMENT_LIST.map((item) => {
                    const keys = Array();
                    Object.keys(item).map((key) => keys.push(key));
                    return (
                    <tr key={item[keys[0]]}>
                      <td>{item[keys[0]]}</td>
                      <td>{item[keys[1]]}</td>
                    </tr>
                  )})}
                </tbody>
              </table>
            </div>
            )}
            {infoData?.DETAIL_INFO2?.CHECKUP_EXECUTION_LIST?.length > 0 && (
            <div>
              <p className="text-black text-base font-medium mb-2">
                [검진실행]
              </p>
              <table className="gray-th-table">
                <tbody>
                  {infoData.DETAIL_INFO2.CHECKUP_EXECUTION_LIST.map((item, index) => {
                    const ex_item = [];
                    if(index % 2 === 0) ex_list.push(ex_item);
                    ex_list[ex_list.length - 1].push(item);
                  })}
                  {ex_list?.map((items, index) => (
                    <tr key={"tr" + index}>
                      {items?.map((item) => (<td key={item}>{item}</td>))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            )}
            {/* 진료시간 */}
            {(infoData?.DETAIL_INFO2?.OPERATION_TIME?.SUNDAY
             || infoData?.DETAIL_INFO2?.OPERATION_TIME?.MONDAY
             || infoData?.DETAIL_INFO2?.OPERATION_TIME?.TUESDAY
             || infoData?.DETAIL_INFO2?.OPERATION_TIME?.WEDNESDAY
             || infoData?.DETAIL_INFO2?.OPERATION_TIME?.THURSDAY
             || infoData?.DETAIL_INFO2?.OPERATION_TIME?.FRIDAY
             || infoData?.DETAIL_INFO2?.OPERATION_TIME?.SATURDAY) && infoData?.DETAIL_INFO2?.OPERATION_TIME && (
            <div className="mt-4 flex items-start gap-1">
              <img src="assets/img/hospital_soon/icon-clock.svg" alt="" />
              <ul className="flex flex-col gap-y-1">
                {Object.keys(infoData.DETAIL_INFO2.OPERATION_TIME).map((item) => (
                  <li className="text-xs" key={item}>{operationTime(item, infoData.DETAIL_INFO2.OPERATION_TIME[item])}</li>
                ))}
              </ul>
            </div>)}
          </div>
          <div tab2content="true" className="px-4">
            <span className="text-right block text-xs text-gray-400 relative" style={{top: "-12px"}}>{infoData?.AREA_POTENTIAL_TOTAL?.DONG_NM} vs 해당 지역에 속한 동평균</span>
            <CustomBarGraph caption={"[직장 인구수 및 1인당 평균소득 지수]"} areaInfo={infoData?.AREA_POTENTIAL_TOTAL} flag="NPSN_JNR"/>
            <CustomBarGraph caption={"[주거 인구수 및 가계 평균소득 지수]"} areaInfo={infoData?.AREA_POTENTIAL_TOTAL} flag="PPLTN"/>
            <CustomBarGraphPerson caption={"연령대 구간별 인구 비교"}
              compare={"(" + infoData?.AREA_POTENTIAL_TOTAL?.DONG_NM + " vs " + infoData?.AREA_POTENTIAL_TOTAL?.DONG_CTPV_NM + " 동평균)"}
              areaInfo={infoData?.AREA_POTENTIAL_TOTAL} />
          </div>
        </InfoCardTab>
      )}

      {pathname === "/hospital_soon" && (
      <InfoCard title={"지역정보"} className="px-4">
        <span className="text-right block text-xs text-gray-400 relative" style={{top: "-12px"}}>{infoData?.AREA_POTENTIAL_TOTAL?.DONG_NM} vs 해당 지역에 속한 동평균</span>
        <CustomBarGraph caption={"[직장 인구수 및 1인당 평균소득 지수]"} areaInfo={infoData?.AREA_POTENTIAL_TOTAL} flag="NPSN_JNR"/>
        <CustomBarGraph caption={"[주거 인구수 및 가계 평균소득 지수]"} areaInfo={infoData?.AREA_POTENTIAL_TOTAL} flag="PPLTN"/>
        <CustomBarGraphPerson caption={"연령대 구간별 인구 비교"}
          compare={"(" + infoData?.AREA_POTENTIAL_TOTAL?.DONG_NM + " vs " + infoData?.AREA_POTENTIAL_TOTAL?.DONG_CTPV_NM + " 동평균)"}
          areaInfo={infoData?.AREA_POTENTIAL_TOTAL} />
      </InfoCard>
      )}
    </div>
  );
}

export const TreatLable = styled.span`
  background: #ffffff;
  border: 1px solid #bebebe;
  color: #747474;
  font-size: 14px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  &.active {
    background: #e8e8e8;
    border: 1px solid #9f9f9f;
    color: #0a0a0a;
  }
`;

export const HospitalKeyWord = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  row-gap: 5px;
  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }
`;

const Sales = styled.div`
  width: calc(100% - 220px);
  background: #f4fdff;
  text-align: center;
  padding: 12px 5px;
  strong {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
  }
  span {
    font-size: 11px;
  }
`;

const SalesGraph = styled.div`
  width: 210px;
  padding-top: 10px;
  position: relative;
  g {
    font-size: 11px;
  }
  .percent-label {
    position: absolute;
    border: 1px solid #000;
    color: #000;
    font-size: 11px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    border-radius: 10px;
    background: #fff;
    z-index: 10;
  }
`;

const AverageBarline = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  border-top: 1px dashed #f00;
  z-index: 10;
`;

const ModalTop = styled.div`
  border-bottom: 1px solid #e4e4e4;
`;

const TootipText = styled.ul`
  li {
    color: #000;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px;
    padding-left: 10px;
  }
`;
export default HospitalInfoCard;
