import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalInner } from "../../login/components/AgreeModal";
import { getToken } from "../../login/Token";
import gcss_api from "../../api/api";
import axios from "axios";
import { ymd_ago } from "../../common/PageTop";

const OpinionReply = ({ item, getOpinion, closeReply }) => {
  const [memo, setMemo] = useState(item.PSTO_CN);
  const token = getToken();
  let option = {
    method: 'GET',
    headers: {'Authorization': token, 'Content-Type': 'application/json'},
    url: gcss_api("api_memo_opinion"),
  };

  const onWrite = () => {
    if(memo === "") {
      alert("내용을 입력하고 등록해주세요.");
    } else {
      option.method = "POST";
      option.data = JSON.stringify({"MEMO": memo, "PARENT_PSTO_SEQ": item.PSTO_SEQ});
      axios(option).then(() => {
        getOpinion();
        closeReply();
      }).catch((err) => {
        if(err?.response?.status === 401) window.location.href = "/logout";
        console.log(err);
        alert("의견 등록에 실패하였습니다.");
      });
    }
  };

  return (
    <OpinionModalBottom>
      <InputWrap>
        <textarea
          rows="3"
          className="grow shrink basis-0 text-sm font-normal font-['Nunito']"
          value={memo}
          onChange={(e) => setMemo(e.target.value)}
        />
        <div className="flex justify-between items-center mb-2">
          <BtnImgFile>
            {false && <img src="assets/img/hospital_map/icon-photofile.svg" alt="" />}
            {false && <input type="file" name="" id="" />}
          </BtnImgFile>
          <div className="py-1 flex gap-2">
            <button className="py-2 w-16 bg-gray-200 rounded text-neutral-400 text-normal font-semibold" onClick={() => closeReply()}>취소</button>
            <button className="py-2 w-16 bg-sky-500 rounded text-white text-normal font-semibold" onClick={() => onWrite()}>등록</button>
          </div>
        </div>
      </InputWrap>
    </OpinionModalBottom>
  );
};

const OpinionModalBottom = styled.div`
  padding: 10px 1rem 30px;
`;

const InputWrap = styled.div`
  textarea {
    width: 100%;
    height: 100px;
    border-radius: 5px;
    border: 1px solid #6da0d2;
    background: linear-gradient(0deg, #fff 0%, #fff 100%), #f0f3f6;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    padding: 0 5px;
  }
`;

const BtnImgFile = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  img {
  }
  input[type="file"] {
    visibility: hidden;
  }
`;

export default OpinionReply;
