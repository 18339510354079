import React from "react";
import styled from "styled-components";

const Process = ({ text }) => {
  return <ProcessWrap>{text}</ProcessWrap>;
};

const ProcessWrap = styled.span`
  width: 35px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0172ce;
  background: #fff;
  color: #0172ce;
  font-size: 12px;
  font-weight: 500;

  background: #fff;
  color: #0172ce;
`;
export default Process;
