import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalInner } from "../../login/components/AgreeModal";
import { getToken } from "../../login/Token";
import gcss_api from "../../api/api";
import axios from "axios";
import OpinionItem from "./OpinionItem";

const OpinionModal = ({ openModal, offModalOpinionHandler }) => {
  const [opinion, setOpinion] = useState();
  const [memo, setMemo] = useState("");
  const token = getToken();
  let option = {
    method: 'GET',
    headers: {'Authorization': token, 'Content-Type': 'application/json'},
    url: gcss_api("api_memo_opinion"),
  };

  const getOpinion = () => {
    option.method = "GET";
    axios(option).then((res) => {
      setOpinion(res.data);
    }).catch((err) => {
      if(err?.response?.status === 401) window.location.href = "/logout";
      console.log(err); //에러가 안나와서 어떻게 나올지 감이 안잡힘
    });
  };

  const onWrite = () => {
    if(memo === "") {
      alert("내용을 입력하고 등록해주세요.");
    } else {
      option.method = "POST";
      option.data = JSON.stringify({"MEMO": memo});
      axios(option).then(() => {
        getOpinion();
        setMemo("");
      }).catch((err) => {
        if(err?.response?.status === 401) window.location.href = "/logout";
        console.log(err);
        alert("의견 등록에 실패하였습니다.");
      });
    }
  };

  useEffect(() => {
    getOpinion();
  }, []);

  return (
    <>
      {openModal && (
        <OpinionModalWrap className="modal-opinion">
          <div className="fixed inset-0 bg-black/25 backdrop-blur-sm">
            <div className=" h-max mx-4 my-8 z-20 relative">
              <ModalInner className="w-full h-max bg-white  rounded-lg">
                <OpinionModalTop className="flex justify-between items-center px-4 py-2">
                  <h3 className="text-black text-lg font-semibold ">
                    의견 게시판
                  </h3>
                  <button onClick={offModalOpinionHandler}>
                    <img src="assets/img/hospital_map/icon-close-gray.svg" style={{ width: "32px" }} />
                  </button>
                </OpinionModalTop>
                <OpinionModalBottom>
                  <InputWrap>
                    <textarea
                      rows="3"
                      className="grow shrink basis-0 text-sm font-normal font-['Nunito']"
                      placeholder="사용시 발생한 오류 및 더나은 서비스를 위한의견, 제안 남겨주세요."
                      value={memo}
                      onChange={(e) => setMemo(e.target.value)}
                    />
                    <div className="flex justify-between items-center mb-2">
                      <BtnImgFile>
                        {false && <img src="assets/img/hospital_map/icon-photofile.svg" alt="" />}
                        {false && <input type="file" name="" id="" />}
                      </BtnImgFile>
                      <div className="py-1 flex gap-2">
                        <button className="py-2 w-16 bg-gray-200 rounded text-neutral-400 text-normal font-semibold" onClick={() => setMemo("")}>취소</button>
                        <button className="py-2 w-16 bg-sky-500 rounded text-white text-normal font-semibold" onClick={() => onWrite()}>등록</button>
                      </div>
                    </div>
                  </InputWrap>
                  <OpinionContentBox>
                    {opinion && opinion.map((item, index) => (
                    <OpinionItem key={item.USER_NM + index} item={item} getOpinion={getOpinion}/>
                    ))}
                  </OpinionContentBox>
                </OpinionModalBottom>
              </ModalInner>
            </div>
          </div>
        </OpinionModalWrap>
      )}
    </>
  );
};

const OpinionModalWrap = styled.div`
  &.modal-opinion {
    position: fixed;
    z-index: 200;
  }
`;

const OpinionModalTop = styled.div`
  border-bottom: 1px solid #e4e4e4;
`;

const OpinionModalBottom = styled.div`
  padding: 10px 1rem 30px;
`;

const InputWrap = styled.div`
  textarea {
    width: 100%;
    height: 100px;
    border-radius: 5px;
    border: 1px solid #6da0d2;
    background: linear-gradient(0deg, #fff 0%, #fff 100%), #f0f3f6;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    padding: 0 5px;
  }
`;

const BtnImgFile = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  img {
  }
  input[type="file"] {
    visibility: hidden;
  }
`;

const OpinionContentBox = styled.div`
  height: calc(100vh - 300px);
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

export default OpinionModal;
