export default function gcss_api(name) {
    const api_url = "https://api.gcss-h.com";
    // const api_url = "http://127.0.0.1:8000";
    switch(name) {  // 순서는 api.gcss-h.com/docs 기준
        case "api_login": return api_url + "/api/v1/login"; // 로그인
        case "api_agree_content": return api_url + "/api/v1/agree/content2"; // 동의서
        case "api_agree_confirm": return api_url + "/api/v1/agree/confirm"; // 동의서 동의
        case "api_agree_check": return api_url + "/api/v1/agree/check"; // 동의서 동의여부 확인
        case "api_hospital_total": return api_url + "/api/v1/hospital/total";   // 병원리스트 (개인별 전체)
        case "api_filter_department": return api_url + "/api/v1/filter/department"; // 진료과 필터 조회 GET / 진료과 필터 저장 POST
        case "api_filter_hospital-flag": return api_url + "/api/v1/filter/hospital-flag";   // 병원플래그 필터 조회 GET / 병원플래그 필터 저장 POST
        case "api_hospital_detail_new": return api_url + "/api/v1/hospital/detail/new"; // 개원예정병원 상세 조회
        case "api_hospital_detail_account": return api_url + "/api/v1/hospital/detail/account"; // 거래병원 상세 조회
        case "api_hospital_detail_no-account": return api_url + "/api/v1/hospital/detail/no-account";   // 비거래병원 상세 조회
        case "api_account_count": return api_url + "/api/v1/account/count"; // 거래처 갯수 조회
        case "api_memo_count": return api_url + "/api/v1/memo/count";   // 메모 갯수 조회
        case "api_hospital_family-info": return api_url + "/api/v1/hospital/family-info";   // 가족사정보
        case "api_potential_area": return api_url + "/api/v1/potential/area";   // 개원가능지역 조회
        case "api_potential_real-estate": return api_url + "/api/v1/potential/real-estate";  // 공인중개사 상세
        case "api_memo_hospital": return api_url + "/api/v1/memo/hospital";   // 병원메모 조회 GET / 병원메모 등록 POST / 병원메모 수정 PUT / 병원메모 삭제 DELETE
        case "api_memo_opinion": return api_url + "/api/v1/memo/opinion";   // 개인의견 조회 GET / 개인의견 등록 POST / 개인의견 수정 PUT / 개인의견 삭제 DELETE
        case "api_alarm_device-token": return api_url + "/api/v1/alarm/device-token";   // 푸시토큰 조회 GET / 푸시토큰 등록 POST
        case "api_alarm_history": return api_url + "/api/v1/alarm/history";   // 알람 조회 GET / 알람 읽음 PUT
        case "api_center-point": return api_url + "/api/v1/center-point";   // 중심좌표 조회
        case "api_code_area-level1": return api_url + "/api/v1/code/area-level1";   // 시도코드
        case "api_code_area-level2": return api_url + "/api/v1/code/area-level2";   // 시군구코드
        case "api_code_area-level3": return api_url + "/api/v1/code/area-level3";   // 동코드
        case "api_code_departments": return api_url + "/api/v1/code/departments";   // 진료과코드
        case "api_memo_hospital_user": return api_url + "/api/v1/memo/hospital/user";   // 마이페이지 병원메모 조회
        case "api_memo_hospital_center": return api_url + "/api/v1/memo/hospital/center";   // 마이페이지 센터메모 조회
        case "api_shedule_hospital_add": return api_url + "/api/v1/schedule/hospital";   // 병원일정 입력
        case "api_shedule_hospital_list": return api_url + "/api/v1/schedule/hospital";   // 병원일정 조회
        case "api_shedule_hospital_delete": return api_url + "/api/v1/schedule/hospital";   // 병원일정 삭제
        case "api_shedule_user_list": return api_url + "/api/v1/schedule/hospital/user";   // 마이페이지 일정 조회
        case "api_hospitall_summary": return api_url + "/api/v1/hospital/summary";   // 병원요약정보
    }
};