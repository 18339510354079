import React from "react";

export const IconArrowON = ({btnRun}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => btnRun(3)}
    >
      <path
        fillRule="evenodd"
        cliprul="evenodd"
        d="M20 15L12 7L4 15L6 17L12 11L18 17L20 15Z"
        fill="#ACACAC"
      />
      <path
        fillRule="evenodd"
        cliprul="evenodd"
        d="M20 15L12 7L4 15L6 17L12 11L18 17L20 15Z"
        fill="black"
      />
    </svg>
  );
};
