import React from "react";
import styled from "styled-components";
import { Tabs } from "flowbite-react";

function InfoCardTab({ children }) {
  const tab1content = children.find((child) => child.props.tab1content);
  const tab2content = children.find((child) => child.props.tab2content);

  return (
    <InfoCardTabWrap>
      <Tabs id="info">
        <Tabs.Item active title={"진료정보"}>
          {tab1content}
        </Tabs.Item>
        <Tabs.Item title={"지역정보"}>{tab2content}</Tabs.Item>
      </Tabs>
    </InfoCardTabWrap>
  );
}

const InfoCardTabWrap = styled.div`
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  margin-bottom: 1rem;
  div[aria-label="Tabs"] {
    background: #f6f6f6;
    display: flex;
    align-items: center;

    border-radius: 10px 10px 0px 0px;
    button {
      width: 50%;
      color: #9c9c9c;
      font-size: 16px;
      padding: 0;
      height: 100%;
      padding: 0.5rem;
      border-bottom: 2px solid #d0d0d0;
      background: none;
      &:focus {
        --tw-ring-opacity: 0;
      }
    }
    button[aria-selected="true"] {
      color: #002b49;
      border-bottom: 2px solid #002b49;
    }
  }
  .info-card-tab-title {
  }
`;

export default InfoCardTab;
