import React, { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import NeedDoctor from "./NeedDoctor";
import InfoCard from "../../common/InfoCard";
import DoctorGraph from "../components/DoctorGraph";
import { getFilterData } from "../../hospital_map/Filter";

const colorData = {
  과밀: "red",
  부족: "blue",
  적정: "green",
}

function AvailableContent() {
  const location = useLocation();
  const pathname = location?.pathname;
  const item = location?.state?.item;
  const department_code = getFilterData("label").department;
  const [select, setSelect] = useState(0);

  return (
    <div className="px-4 py-4">
      {pathname === "/apt_detail" ? <NeedDoctor item={item}/> : null}

      <InfoCard title={"주변 병원 정보"}>
        <HospitalListUl>
          {item.AREA_HOSPITAL_INFO_LIST.map((item, index) => {
            return (
              <HospitalListLi
                key={item.TRT_SBJCT_CD}
                onClick={() => setSelect(index)}
                className={index === select ? "gray" : ""}
              >
                <p>{department_code?.filter((filter) => filter.code === item.TRT_SBJCT_CD)[0]?.name}</p>
                <CountLabel
                  text={item.MDLCR_OCRD_GRD_NM}
                  color={colorData[item.MDLCR_OCRD_GRD_NM]}
                />
              </HospitalListLi>
            )
          })}
        </HospitalListUl>

        <div className="mt-4">
          <p className="text-black text-base font-medium mb-1">
            [의사 과부족 정보]
          </p>
          <p className="text-black text-xs">인구 1000명당 의사수</p>
          <DoctorGraph item={item.AREA_HOSPITAL_INFO_LIST[select]}/>
          <div>
            <div className="flex items-center gap-2 mb-3">
              <p className="text-black text-base font-medium mb-1">
                [주변 병원 정보]
              </p>
              <p className="text-black text-xs">(반경 500이내 / {department_code?.filter((filter) => filter.code === item.AREA_HOSPITAL_INFO_LIST[select].TRT_SBJCT_CD)[0]?.name})</p>
            </div>
            <ul>
              {item.AREA_SQUARE_HOSPITAL_INFO.map((item) => {
                return (
                  <li className="flex items-center gap-1 mb-1" key={item.id}>
                    {item.deal ? (
                      <img
                        src="assets/img/hospital_map/icon-map-green.svg"
                        alt=""
                        style={{ width: "18px" }}
                      />
                    ) : (
                      <img
                        src="assets/img/hospital_map/icon-map-green-border.svg"
                        alt=""
                        style={{ width: "18px" }}
                      />
                    )}

                    <p className="text-black text-xs font-normal">
                      {item.name} / 총의사수 {item.doctors}명 / {item.distance}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </InfoCard>
    </div>
  );
}

const CountLabel = ({ text, color }) => {
  return <CountLabelWrap className={color}>{text}</CountLabelWrap>;
};

const CountLabelWrap = styled.span`
  height: 20px;
  padding: 2px 7px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  border-radius: 20px;
  &.red {
    background: #e5002b;
  }
  &.blue {
    background: #0072ce;
  }
  &.green {
    background: green;
  }
`;
const HospitalListUl = styled.ul`
  display: flex;
  flex-wrap: no-wrap;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 5px;
`;

const HospitalListLi = styled.li`
  flex: 0 0 auto;
  width: 70px;
  min-width: max-content;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  background: #fff;
  &.gray {
    background: #f3f3f3;
  }

  p {
    color: #000;
    text-align: center;
    font-size: 14px;
    margin-bottom: 5px;
  }
`;
export default AvailableContent;
